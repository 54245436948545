import AddIcon from "@mui/icons-material/Add";
import { MoreVert, Tune } from "@mui/icons-material";
import { MouseEvent, useCallback, useState } from "react";
import { useNTableSettingsDialog } from "./NTableSettings/NTableSettingsDialog";
import {
  alpha,
  Divider,
  DividerProps,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

export type NTableMenuItem = (MenuItemProps & { type: "menuItem" }) | (DividerProps & { type: "divider" });

interface EnhancedTableToolbarProps {
  numSelected: number;
  title?: string;
  onAddClick?: () => void;
  onSelectionAvailableActions?: NTableMenuItem[];
  supportsColumnOrder?: {
    tableKey: string;
    onNewOrder: () => void;
  };
  toolbarIsDense?: boolean;
}

export function NTableToolbar(props: EnhancedTableToolbarProps) {
  const theme = useTheme();

  const { numSelected } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const settingsDialog = useNTableSettingsDialog({
    onNewSettings: props.supportsColumnOrder?.onNewOrder || (() => {}),
    dataKey: props.supportsColumnOrder?.tableKey || "",
  });

  const openSettingsDialog = useCallback(() => {
    settingsDialog.openDialog();
  }, [settingsDialog]);

  return (
    <>
      {settingsDialog.DialogRender}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
        variant={props.toolbarIsDense ? "dense" : "regular"}
      >
        {numSelected > 0 ? (
          <>
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>

            <Tooltip title="More actions">
              <>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {props.onSelectionAvailableActions?.map((a, i) => {
                    if (a.type === "menuItem") {
                      return <MenuItem key={i} {...a} />;
                    }

                    if (a.type === "divider") {
                      return <Divider key={i} {...a} />;
                    }

                    return null;
                  })}
                </Menu>
              </>
            </Tooltip>
          </>
        ) : (
          <>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              {props.title}
            </Typography>

            {props.onAddClick && (
              <Tooltip title="Add">
                <IconButton onClick={props.onAddClick}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}

            {props.supportsColumnOrder && (
              <Tooltip title="Edit column orders">
                <IconButton onClick={openSettingsDialog}>
                  <Tune />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
    </>
  );
}
