import { useEffect, useRef } from "react";
import { FormContainer } from "react-hook-form-mui";

import { Delete, Save } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress, Paper, Toolbar, Typography } from "@mui/material";

import { NPrompDialog } from "../nPrompt/NPrompDialog";
import { NFreezer } from "../nFreezer/NFreezer";
import { useForm, UseFormWatch } from "react-hook-form";

export function NFormContainer(props: {
  title: string;
  defaultValues?: { [key: string]: any };
  children: any;
  onFormReady: (formData: any) => void;
  deleteProps?: {
    redirectToPath: string;
    onClick?: () => void;
  };
  isLoading?: boolean;
  isFrozen?: boolean;
  error?: string;
  watchers?: (watcher: UseFormWatch<any>) => void;
}) {
  const dialogActions = useRef({ show: undefined });
  const formContext = useForm({
    defaultValues: props.defaultValues,
  });

  useEffect(() => {
    props.watchers?.(formContext.watch);
  });

  useEffect(() => {
    formContext.reset(props.defaultValues, { keepDefaultValues: true, keepDirtyValues: true });
  }, [formContext, props.defaultValues]);

  return (
    <>
      <Box
        sx={{
          width: {
            xs: "100%",
            lg: "816px",
          },
        }}
      >
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
              {props.title}
            </Typography>
          </Toolbar>
          {props.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}
          <Box
            sx={{
              pl: 2,
              pr: 2,
              pb: 2,
            }}
          >
            {props.isLoading !== true && (
              <NFreezer isLoading={props.isFrozen}>
                <FormContainer formContext={formContext} onSuccess={props.onFormReady}>
                  <Grid container spacing={2}>
                    {props.children}
                  </Grid>
                  <br />
                  {props.error && (
                    <div style={{ margin: "0px 0px 10px 0px" }}>
                      <Typography color="orangered" variant="caption">
                        {props.error}
                      </Typography>
                    </div>
                  )}

                  <div style={{ display: "flex", gap: "1em" }}>
                    <Button type="submit" variant="contained" startIcon={<Save />}>
                      Save
                    </Button>
                    {props.deleteProps && (
                      <Button onClick={dialogActions.current.show} variant="outlined" startIcon={<Delete />}>
                        Delete
                      </Button>
                    )}
                  </div>
                </FormContainer>
              </NFreezer>
            )}
          </Box>
        </Paper>
      </Box>
      <NPrompDialog yieldsActions={dialogActions.current} onYes={props.deleteProps?.onClick} />
    </>
  );
}
