import { getInput } from "../../shared/utils";
import { getTemplate } from "../../graphql/queries";
import { GetTemplateInput, GetTemplateQuery } from "../../shared/API";
import { Overview } from "./children/templateOverview";
import { Portfolios } from "./children/portfolios";
import { useGetData } from "../../shared/useGetData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Box, LinearProgress, Paper, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { useUserPreferences } from "../_appLayout/AppContent";

export function TemplateDash() {
  const { id = "" } = useParams();
  const { customClientId } = useUserPreferences();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const dataState = useGetData<GetTemplateQuery>({
    customClientId,
    graphqlQuery: getTemplate,
    variables: getInput<GetTemplateInput>({ id }),
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
            Template: {dataState.data?.getTemplate?.name}
          </Typography>
        </Toolbar>
        {dataState.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={selectedTabIndex} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" id="0" />
            <Tab label="Portfolios" id="1" />
          </Tabs>
        </Box>
        <TabPanel value={selectedTabIndex} index={0}>
          <Overview data={dataState.data?.getTemplate} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={1}>
          <Portfolios templateId={id} />
        </TabPanel>
      </Paper>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
