import { createExchange } from "../../graphql/mutations";
import { CreateExchangeInput, GetExchangeKindsInput, GetExchangeKindsQuery } from "../../shared/API";
import { getExchangeKinds } from "../../graphql/queries";
import { getInput } from "../../shared/utils";
import { Grid } from "@mui/material";
import { NFormContainer } from "../../components/nFormContainer/NFormContainer";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { useGetData } from "../../shared/useGetData";
import { useNavigate } from "react-router-dom";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";

export function ExchangeNew() {
  const navigate = useNavigate();
  const { customClientId } = useUserPreferences();
  const post = usePostData({
    customClientId,
    onSuccess: () => {
      navigate("/exchange");
      toast.success("Exchange successfully created!");
    },
    onError: () => {
      toast.error("Failed to create exchange!");
    },
  });

  const createExchangeApi = useCallback(
    (formData: any) => {
      post.postData(createExchange, getInput<CreateExchangeInput>(formData));
    },
    [post]
  );

  const exchangeKindsState = useGetData<GetExchangeKindsQuery>({
    customClientId,
    graphqlQuery: getExchangeKinds,
    variables: getInput<GetExchangeKindsInput>({ limit: 1000 }),
  });

  return (
    <NFormContainer
      onFormReady={createExchangeApi}
      title="Create new exchange"
      isFrozen={post.state.isLoading}
      error={post.state.error}
      isLoading={exchangeKindsState.isLoading}
    >
      <Grid item xs={12} md={6}>
        <TextFieldElement name="name" label="Name" required variant="filled" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="kind"
          label="Type"
          required
          variant="filled"
          fullWidth
          options={
            exchangeKindsState.data?.getExchangeKinds?.items?.map((e) => {
              return { id: e?.id, title: e?.name };
            }) || []
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldElement name="publicKey" label="Public key" required variant="filled" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextFieldElement name="secretKey" label="Secret key" required variant="filled" fullWidth />
      </Grid>
    </NFormContainer>
  );
}
