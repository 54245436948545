import { NLevel } from "./Template.types";

export const reorderSequentialSet = (selectedSubSetKeys: string[], currentSet: NLevel[], dir: 1 | -1 | 0) => {
  if (selectedSubSetKeys.length === 0) return currentSet;

  const selectedSubSet = currentSet.filter((f) => selectedSubSetKeys.includes(f.__customId));
  const result: any = {};
  const modifiedItems = new Set();

  let outOfTheBounds = false;
  let minPossibleLevel = 0;
  let maxPossibleLevel = currentSet.length - 1;

  const sortedSet = sortItems(currentSet, "level", dir);
  if(dir === 1){
    minPossibleLevel = sortedSet[0].level;
    maxPossibleLevel = sortedSet[currentSet.length - 1].level;
  }else{
    maxPossibleLevel = sortedSet[0].level;
    minPossibleLevel = sortedSet[currentSet.length - 1].level;
  }

  if(maxPossibleLevel - minPossibleLevel + 1 !== currentSet.length) {
    console.warn("Levels are not sequential.");
    return currentSet;
  }


  const sortedSelectedSubSet = sortItems(selectedSubSet, "level", dir);
  for (const item of sortedSelectedSubSet) {
    let newLevel = item.level - dir;

    if (newLevel <= minPossibleLevel && dir === 1) {
      if (newLevel < minPossibleLevel) outOfTheBounds = true;

      newLevel = minPossibleLevel;
      minPossibleLevel += dir;
    }

    if (newLevel >= maxPossibleLevel && dir === -1) {
      if (newLevel > maxPossibleLevel) outOfTheBounds = true;

      newLevel = maxPossibleLevel;
      maxPossibleLevel += dir;
    }

    modifiedItems.add(item.__customId);
    result[newLevel] = { ...item, newLevel };
  }


  for (const item of sortedSet) {
    if (modifiedItems.has(item.__customId)) continue;

    let newLevel = item.level;
    while (result[newLevel] !== undefined) newLevel += dir;

    result[newLevel] = { ...item, newLevel };
  }

  if (!outOfTheBounds) dir = 0;

  const finalResult: any = Object.values(result)
    .map((item: any) => {
      return {
        __customId: item.__customId,
        level: item.newLevel - dir,
      };
    })
    .sort((l1, l2) => {
      if (l1.level < l2.level) return -1;
      if (l1.level > l2.level) return 1;
      return 0;
    });

  return finalResult;
};

const sortItems = (items: any[], field: string, dir: 1 | -1 | 0) => {
  const result = [...items];
  return result.sort((i1, i2) => {
    if (i1[field] < i2[field]) return -dir;
    if (i1[field] > i2[field]) return dir;
    return 0;
  });
};
