import { AppLayout } from "./views/_appLayout/AppLayout";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";

export function App() {
  return (
    <Authenticator hideSignUp={false} signUpAttributes={["email"]}>
      <Authenticator.Provider>
        <BrowserRouter>
          <AppLayout />
        </BrowserRouter>
      </Authenticator.Provider>
    </Authenticator>
  );
}
