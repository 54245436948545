import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import GoToNextLimit from "../../../components/GoToNextToken";
import { blue, red } from "@mui/material/colors";
import { Box } from "@mui/material";
import { getColumns, getInput, prepareTableRows } from "../../../shared/utils";
import { getOrders } from "../../../graphql/queries";
import { GetOrdersInput, GetOrdersQuery, Order } from "../../../shared/API";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useGetData } from "../../../shared/useGetData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../_appLayout/AppContent";

const fetchLimit = 1000;

type IProps = { exchangeId?: string; portfolioId?: string };

const getConfigKey = (props: IProps) => {
  if (props.exchangeId) {
    return "exchanges_orders";
  } else if (props.portfolioId) {
    return "portfolios_orders";
  } else {
    return "orders";
  }
};

const getKeyName = (props: IProps) => {
  if (props.exchangeId) {
    return "Exchange orders";
  } else if (props.portfolioId) {
    return "Portfolio orders";
  } else {
    return "Orders";
  }
};

export function Orders(props: IProps) {
  const { customClientId } = useUserPreferences();
  const TABLE_KEY = useRef(getConfigKey(props));
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));

  const dataState = useGetData<GetOrdersQuery>({
    customClientId,
    graphqlQuery: getOrders,
    variables: getInput<GetOrdersInput>({
      portfolioId: props.portfolioId,
      exchangeId: props.exchangeId,
      limit: fetchLimit,
    }),
  });

  return (
    <>
      <NTable
        toolbarIsDense
        title={getKeyName(props)}
        rememberStateKey={getConfigKey(props)}
        columns={getColumns(OrdersAsChildrenColumns, colsConfig)}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        defaultOrder="desc"
        defaultOrderBy="trdPair"
        isLoading={dataState.isLoading}
        rows={prepareTableRows(dataState.data?.getOrders?.items ?? [])}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getOrders?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}

export const OrdersAsChildrenColumns: IHeadCell<Order>[] = [
  {
    id: "time",
    label: "Time",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "id",
    label: "Order ID",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "trdPair",
    label: "Trade pair",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "dir",
    label: "Direction",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      switch (value) {
        case "SELL":
          return (
            <Box sx={{ color: blue[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleUpIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "BUY":
          return (
            <Box sx={{ color: red[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleDownIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        default:
          return <Box>{value}</Box>;
      }
    },
  },
  {
    id: "qty",
    label: "Quantity",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "price",
    label: "Price",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "level",
    label: "Level",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "role",
    label: "Role",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "volume",
    label: "Volume",
    numeric: true,
    disablePadding: false,
  },
  { id: "status", label: "Status", numeric: false, disablePadding: false },
  { id: "kind", label: "Kind", numeric: false, disablePadding: false },
  { id: "type", label: "Type", numeric: false, disablePadding: false },
  { id: "fulfilled", label: "Fulfilled", numeric: true, disablePadding: false },
  { id: "qtyChange", label: "Quantity change", numeric: true, disablePadding: false },
  { id: "linked", label: "Linked", numeric: false, disablePadding: false },
  { id: "trdAmount", label: "Trade amount", numeric: true, disablePadding: false },
];
