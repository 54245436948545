import { API, graphqlOperation } from "aws-amplify";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { getConfigEntries, getPermissions } from "../../graphql/queries";
import { GetConfigEntriesInput, GetConfigEntriesQuery, GetPermissionsInput, GetPermissionsQuery } from "../../shared/API";
import { getInput } from "../../shared/utils";
import { GraphQLResult } from "../../shared/useGetData";
import { SharedData } from "../../shared/sharedData/sharedData";
import { useEffect, useState } from "react";

const dataToHave: { query: string; variables: {}; onData?: (data: any) => void }[] = [
  {
    query: getConfigEntries,
    variables: getInput<GetConfigEntriesInput>({ limit: 100000 }),
    onData: (data: GetConfigEntriesQuery) => {
      data.getConfigEntries?.items?.forEach((i) => {
        try {
          if (i && i.id && i.value) {
            SharedData.set(i.id, JSON.parse(i.value));
          }
        } catch (error) {
          console.error(error);
        }
      });
    },
  },
  {
    query: getPermissions,
    variables: getInput<GetPermissionsInput>({ limit: 100000 }),
    onData: (data: GetPermissionsQuery) => {
      SharedData.set("_AVAILABLE_CLIENTS", data.getPermissions?.items);
    },
  },
];

export function AppInitialLoad(props: { onLoaded: (isLoaded: boolean) => void }) {
  const { onLoaded } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let fetchedAlready = 0;
    let mustFetch = dataToHave.length;

    const fetch = async () => {
      for (let index = 0; index < dataToHave.length; index++) {
        const element = dataToHave[index];

        const data: GraphQLResult<any> = await API.graphql(graphqlOperation(element.query, element.variables));
        if (data.data && element.onData) {
          element.onData(data.data);
        }
        fetchedAlready += 1;
        setProgress((fetchedAlready / mustFetch) * 100);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (progress === 100) {
      onLoaded(true);
    }
  }, [progress, onLoaded]);

  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw", alignItems: "center", justifyContent: "center" }}>
      <Paper sx={{ p: 2, maxWidth: "350px", width: "100%" }}>
        <Typography variant="overline" gutterBottom>
          Gulintech
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Paper>
    </Box>
  );
}
