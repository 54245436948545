import AllInboxIcon from "@mui/icons-material/AllInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { FileOpen, FilePresent } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { User } from "./user/user";

export function SideMenu(props: { drawerWidth: number; isMobile: boolean; onMenuToggle: () => void }) {
  return (
    <Box component="nav" sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      <Drawer
        container={window.document.body}
        variant="temporary"
        open={props.isMobile}
        onClose={props.onMenuToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
      >
        <MenuLinks />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth,
          },
        }}
        open
      >
        <MenuLinks />
      </Drawer>
    </Box>
  );
}

function MenuLinks() {
  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Toolbar>K27 AG</Toolbar>
      <Divider />
      <List>
        <CustomLink to="exchange" text="Exchanges" icon={<FilePresent />} />
        <CustomLink to="portfolio" text="Portfolio" icon={<AllInboxIcon />} />
        <CustomLink to="log" text="Logs" icon={<TableRowsIcon />} />
        <CustomLink to="template" text="Templates" icon={<FileOpen />} />
      </List>
      <Divider />
      <List>
        <CustomLink to="user_settings" text="User settings" icon={<SettingsIcon />} />
      </List>
      <Divider />
      <User />

      <div
        style={{
          position: "absolute",
          bottom: 0,
          padding: "8px 16px",
          fontWeight: 300,
        }}
      >
        Version: {process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
}

function CustomLink(props: { to: string; text: string; icon: any }) {
  return (
    <NavLink to={props.to}>
      {({ isActive }) => (
        <ListItemButton selected={isActive}>
          <ListItemIcon>
            <>{props.icon}</>
          </ListItemIcon>
          <ListItemText primary={props.text} />
        </ListItemButton>
      )}
    </NavLink>
  );
}
