import { CheckboxElement, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { createPortfolio } from "../../graphql/mutations";
import { CreatePortfolioInput, GetExchangesInput, GetExchangesQuery, GetTemplatesInput, GetTemplatesQuery } from "../../shared/API";
import { getExchanges, getTemplates } from "../../graphql/queries";
import { getInput } from "../../shared/utils";
import { Grid, TextField } from "@mui/material";
import { NFormContainer } from "../../components/nFormContainer/NFormContainer";
import { toast } from "react-toastify";
import { useCallback, useRef, useState } from "react";
import { useGetData } from "../../shared/useGetData";
import { useNavigate } from "react-router-dom";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";

type IState = {
  buy: null | number | undefined | string;
  sell: null | number | undefined | string;
  helpObject: { templateId?: string | null | undefined; initialVolum?: string | number | null | undefined };
};

export function PortfolioNew() {
  const navigate = useNavigate();
  const { customClientId } = useUserPreferences();
  const post = usePostData({
    customClientId,

    onSuccess: () => {
      navigate("/portfolio");
      toast.success("Portfolio successfully created!");
    },
    onError: () => {
      toast.error("Failed to create portfolio!");
    },
  });

  const exchangesState = useGetData<GetExchangesQuery>({
    customClientId,
    graphqlQuery: getExchanges,
    variables: getInput<GetExchangesInput>({ limit: 1000 }),
  });

  const templatesState = useGetData<GetTemplatesQuery>({
    customClientId,
    graphqlQuery: getTemplates,
    variables: getInput<GetTemplatesInput>({ limit: 1000 }),
  });

  const createPortfolioAction = useCallback(
    (formData: any) => {
      post.postData(createPortfolio, getInput<CreatePortfolioInput>(formData));
    },
    [post]
  );

  const [maxVolumes, setMaxVolumes] = useState<IState>({
    buy: "",
    sell: "",
    helpObject: {},
  });

  const setMaxVolumesAction = useCallback(
    (newInitialVolum: string | number | null | undefined, newTempalteId: string | null | undefined) => {
      if (newInitialVolum !== maxVolumes.helpObject.initialVolum || newTempalteId !== maxVolumes.helpObject.templateId) {
        const newStatToBe: IState = {
          buy: "",
          sell: "",
          helpObject: {
            templateId: newTempalteId,
            initialVolum: newInitialVolum,
          },
        };
        try {
          const selectedTemplate = templatesState.data?.getTemplates?.items?.find((t) => t?.id === newTempalteId);

          try {
            if (selectedTemplate?.maxBuyVolume != null) {
              newStatToBe.buy = Number(newInitialVolum) * selectedTemplate.maxBuyVolume;
            }
            if (selectedTemplate?.maxSellVolume != null) {
              newStatToBe.sell = Number(newInitialVolum) * selectedTemplate.maxSellVolume;
            }
          } catch (error) {
            // nothign for now
          }

          setMaxVolumes(newStatToBe);
        } catch (error) {
          setMaxVolumes({
            buy: "",
            sell: "",
            helpObject: {
              templateId: newTempalteId,
              initialVolum: newInitialVolum,
            },
          });
        }
      }
    },
    [maxVolumes.helpObject.initialVolum, maxVolumes.helpObject.templateId, templatesState.data?.getTemplates?.items]
  );

  const registerWatchers = useCallback(
    (watcher: any) => {
      const [initialVolum, templateId] = watcher(["initialVolume", "templateId"]);
      setMaxVolumesAction(initialVolum, templateId);
    },
    [setMaxVolumesAction]
  );

  return (
    <NFormContainer
      watchers={(watcher) => {
        registerWatchers(watcher);
      }}
      isLoading={exchangesState.isLoading || templatesState.isLoading}
      isFrozen={post.state.isLoading}
      onFormReady={createPortfolioAction}
      title="Create new portfolio"
      defaultValues={
        useRef({
          initialVolume: 100,
          status: "wait",
          buy: false,
          sell: false,
          onlyClose: false,
        }).current
      }
    >
      <Grid item xs={12} md={6}>
        <TextFieldElement name="name" label="Name" required variant="outlined" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="status"
          label="Status"
          required
          variant="outlined"
          fullWidth
          options={[
            { id: "active", title: "Active" },
            { id: "wait", title: "Wait" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="exchangeId"
          label="Exchange"
          required
          variant="outlined"
          fullWidth
          options={exchangesState.data?.getExchanges?.items?.map((e) => ({ id: e?.id, title: e?.name }))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="templateId"
          label="Template"
          required
          variant="outlined"
          fullWidth
          options={templatesState.data?.getTemplates?.items?.map((e) => ({ id: e?.id, title: e?.name }))}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldElement
          name="trdPair"
          label="Trade pair"
          required
          variant="outlined"
          fullWidth
          // helperText="Set trading pair as it appears at exchange. Case sensitive!"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldElement
          name="initialVolume"
          label="Initial volume (USD)"
          // validation={{ min: (val) => (val > 0) | "Intial volume must be > 0!" }}
          type="number"
          required
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField label="Max buy volume" disabled variant="outlined" fullWidth value={maxVolumes.buy} />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField label="Max sell volume" disabled variant="outlined" fullWidth value={maxVolumes.sell} />
      </Grid>

      <Grid item xs={12}>
        <CheckboxElement name="buy" label="Buy" />
        <CheckboxElement name="sell" label="Sell" />
        <CheckboxElement name="onlyClose" label="Only close" />
      </Grid>
    </NFormContainer>
  );
}
