import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import NearbyErrorIcon from "@mui/icons-material/NearbyError";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";
import { grey, lightGreen, orange } from "@mui/material/colors";

import { IHeadCell } from "../../components/nTable/NTable.types";
import { Portfolio } from "../../shared/API";
import { sortColumnsByLabelExceptFirst } from "../../shared/utils";

export const PortfolioColumns: IHeadCell<Portfolio>[] = sortColumnsByLabelExceptFirst([
  {
    id: "name",
    label: "Portfolio name",
    numeric: false,
    disablePadding: false,
    linkTo: "/portfolio/:id/dash",
  },
  {
    id: "status",
    label: "Status",
    disablePadding: false,
    numeric: false,
    customRender: (value) => {
      switch (value) {
        case "wait":
          return (
            <Box
              sx={{
                color: grey[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <HourglassTopIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "active":
          return (
            <Box
              sx={{
                color: lightGreen[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <DirectionsRunIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "inconsistent":
          return (
            <Box
              sx={{
                color: orange[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <WarningAmberIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        default:
          return <Box>{value}</Box>;
      }
    },
  },
  {
    id: "buy",
    label: "Buy",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.buy ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.buy ? null : "line-through",
            opacity: row.buy ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <ArrowCircleDownIcon sx={{ fontSize: "1.2em" }} /> &nbsp; Buy
        </Box>
      );
    },
  },
  {
    id: "sell",
    label: "Sell",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.sell ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.sell ? null : "line-through",
            opacity: row.sell ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <ArrowCircleUpIcon sx={{ fontSize: "1.2em" }} /> &nbsp; Sell
        </Box>
      );
    },
  },
  {
    id: "onlyClose",
    label: "Only close",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.onlyClose ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.onlyClose ? null : "line-through",
            opacity: row.onlyClose ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <NearbyErrorIcon sx={{ fontSize: "1.2em" }} /> &nbsp; Only close
        </Box>
      );
    },
  },
  {
    id: "lastTradeMoment",
    label: "Last trade",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "amount",
    label: "Amount",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "level",
    label: "Level",
    numeric: true,
    disablePadding: false,
  },
  { id: "ask", label: "Ask", numeric: true, disablePadding: false },
  { id: "bid", label: "Bid", numeric: true, disablePadding: false },
  { id: "cooldown", label: "Cooldown", numeric: true, disablePadding: false },
  { id: "exchange", label: "Exchange", numeric: false, disablePadding: false, customRender: (value) => value.name },
  { id: "fixedAmount", label: "Fixed amount", numeric: true, disablePadding: false },
  { id: "fixedVolume", label: "Fixed volume", numeric: true, disablePadding: false },
  { id: "initialPrice", label: "Initial price", numeric: true, disablePadding: false },
  { id: "initialVolume", label: "Initial volume", numeric: true, disablePadding: false },
  { id: "initialVolumeEUR", label: "Initial volume EUR", numeric: true, disablePadding: false },
  { id: "limit", label: "Limit", numeric: true, disablePadding: false },
  { id: "martingale", label: "Martingale", numeric: true, disablePadding: false },
  { id: "nextLevelPrice", label: "Next level price", numeric: true, disablePadding: false },
  { id: "nextLevelVolume", label: "Next level volume", numeric: true, disablePadding: false },
  { id: "ordersType", label: "Orders type", numeric: false, disablePadding: false },
  { id: "positionControl", label: "Position control", numeric: false, disablePadding: false },
  { id: "priceShift", label: "Price shift", numeric: true, disablePadding: false },
  { id: "takeProfit", label: "Take profit", numeric: true, disablePadding: false },
  { id: "takeProfitPrice", label: "Take profit price", numeric: true, disablePadding: false },
  { id: "template", label: "Template", numeric: false, disablePadding: false, customRender: (value) => value.name },
  { id: "trdPair", label: "Trade pair", numeric: false, disablePadding: false },
  { id: "volume", label: "Volume", numeric: true, disablePadding: false },
]);
