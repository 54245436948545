import React from "react";
import ReactDOM from 'react-dom';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Button
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
  headerText: string;
  submitBtnText: string;
  onSubmit: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: "800px",
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}));


export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}
  
function BootstrapDialogTitle(props: DialogTitleProps) {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[400],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

export function NModal(props: ModalProps) {
	const modal = (
		<BootstrapDialog
			onClose={props.onClose}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			maxWidth="lg"
		>
		<BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
			{props.headerText}
		</BootstrapDialogTitle>
		<DialogContent dividers>
			{props.children}
		</DialogContent>
		<DialogActions>
			<Button autoFocus onClick={props.onSubmit}>
				{props.submitBtnText}
			</Button>
		</DialogActions>
	</BootstrapDialog>
	)

	return props.open ? ReactDOM.createPortal(modal, document.body) : null;
}

