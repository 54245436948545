import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SharedData } from "../../../../../shared/sharedData/sharedData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../../AppContent";

export function ClientPicker(props: { username: string | undefined; userEmail: string | null | undefined }) {
  const { setCustomClientId } = useUserPreferences();

  const knownClients = useRef<{ clientId: string; permission: string }[]>([
    // { clientId: "test" },
    ...(SharedData.get("_AVAILABLE_CLIENTS") || []),
  ]);

  const [selectedClientId, setSelectedClientId] = useState(props.username);

  const onClientSelect = (event: SelectChangeEvent) => {
    const newClientTobe = event.target.value;
    setSelectedClientId(newClientTobe);

    setCustomClientId?.(newClientTobe === props.username ? undefined : newClientTobe);
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-label">Client</InputLabel>
      <Select
        disabled={knownClients.current.length === 1}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedClientId}
        label="Client"
        onChange={onClientSelect}
      >
        {knownClients.current.map((client) => (
          <MenuItem key={client.clientId} value={client.clientId}>
            {client.clientId === props.username ? props.userEmail || client.clientId : client.clientId}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
