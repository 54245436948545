import { Add, ContentPasteGo, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Save } from "@mui/icons-material";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { createTemplate } from "../../graphql/mutations";
import { CreateTemplateInput } from "../../shared/API";
import { getInput } from "../../shared/utils";
import { LevelRow } from "./LevelRow";
import { NContainer } from "../../components/nContainer/NContainer";
import { NLevel } from "./Template.types";
import { reorderSequentialSet } from "./Template.utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { usePasteLevelsDialog } from "./PasteLevelsDialog";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";
import { v4 } from "uuid";
import {
  alpha,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

export function TemplateCreate() {
  const theme = useTheme();
  const { customClientId } = useUserPreferences();
  const navigate = useNavigate();

  const post = usePostData({
    customClientId,
    onSuccess: () => {
      navigate("/template");
      toast.success("Template successfully created!");
    },
    onError: () => {
      toast.error("Failed to create template!");
    },
  });

  const [templateName, setTemplateName] = useState<string>("");
  const [levels, setLevels] = useState<NLevel[]>([]);
  const [selectedLevels, setSelectecedLevels] = useState<string[]>([]);

  const toggleLevelSelection = useCallback((levelId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectecedLevels((prevState) => [...prevState, levelId]);
    } else {
      setSelectecedLevels((prevState) => prevState.filter((f) => f !== levelId));
    }
  }, []);

  const onAddLevel = useCallback(() => {
    setLevels((prevLevels) => {
      return [
        ...prevLevels,
        {
          __customId: v4(),
          __typename: "TemplateLevel",
          level:
            prevLevels.reduce((pv, cv) => {
              return Math.max(pv, cv.level || 0);
            }, -1) + 1,
        },
      ];
    });
  }, []);

  const levelsById = useRef<any>({});

  const levelUp = useCallback(() => {
    setLevels((prevLevelsOrder) => {
      return reorderSequentialSet(selectedLevels, prevLevelsOrder, 1);
    });
  }, [selectedLevels]);

  const levelDown = useCallback(() => {
    setLevels((prevLevelsOrder) => {
      return reorderSequentialSet(selectedLevels, prevLevelsOrder, -1);
    });
  }, [selectedLevels]);

  const levelRemove = useCallback((levelId: string) => {
    setSelectecedLevels((prevState) => prevState.filter((f) => f !== levelId));
    setLevels((prevState) => prevState.filter((f) => f.__customId !== levelId));
  }, []);

  const createTemlate = useCallback(() => {
    post.postData(
      createTemplate,
      getInput<CreateTemplateInput>({
        name: templateName,
        // @ts-ignore
        levels: levels.map((l) => {
          return {
            level: l.level,
            martingale: levelsById.current[l.__customId].martingale,
            priceShift: levelsById.current[l.__customId].priceShift,
            takeProfit: levelsById.current[l.__customId].takeProfit,
          };
        }),
      })
    );
  }, [levels, post, templateName]);

  const dialog = usePasteLevelsDialog({
    onFullfilled: (lvls: NLevel[]) => {
      setLevels(lvls);
    },
  });

  return (
    <>
      {dialog.TemplateDialog}
      <NContainer title="Create new template" isFrozen={post.state.isLoading}>
        <Grid item xs={12}>
          <TextField
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setTemplateName(e.target.value)}
            value={templateName}
            name="name"
            label="Template name"
            required
            variant="outlined"
            fullWidth
          />
        </Grid>

        <br />
        <Grid item xs={12}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selectedLevels.length > 0 && {
                bgcolor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              }),
            }}
          >
            {selectedLevels.length > 0 ? (
              <>
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                  {selectedLevels.length} selected
                </Typography>

                <Tooltip title="+1 Level">
                  <IconButton onClick={levelDown}>
                    <KeyboardDoubleArrowDown />
                  </IconButton>
                </Tooltip>
                <Tooltip title="-1 Level">
                  <IconButton onClick={levelUp}>
                    <KeyboardDoubleArrowUp />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Typography sx={{ flex: "1 1 100%" }} variant="body1" id="tableTitle" component="div">
                  Template's levels
                </Typography>
                <Tooltip title="Import">
                  <IconButton onClick={() => dialog.openDialog()}>
                    <ContentPasteGo />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add">
                  <IconButton onClick={onAddLevel}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Toolbar>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Martingale</TableCell>
                <TableCell>Price shift</TableCell>
                <TableCell>Take profit</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levels
                .sort((a, b) => (a.level || 0) - (b.level || 0))
                .map((lvl) => (
                  <LevelRow
                    key={lvl.__customId}
                    level={lvl}
                    refValue={levelsById.current}
                    onSelection={toggleLevelSelection}
                    onRemove={levelRemove}
                  />
                ))}
            </TableBody>
          </Table>
        </Grid>

        {post.state.error && (
          <div style={{ margin: "10px 0px 0px 0px" }}>
            <Typography color="orangered" variant="caption">
              {post.state.error}
            </Typography>
          </div>
        )}
        <br />
        <Button variant="contained" startIcon={<Save />} onClick={createTemlate}>
          Save
        </Button>
      </NContainer>
    </>
  );
}
