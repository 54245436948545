import { getInitialColumns } from "../components/nTable/NTableSettings/TableSettingsDetails";
import { IHeadCell } from "../components/nTable/NTable.types";

export function prepareTableRows<T>(array: T[]): (T & { _rowName: string })[] {
  return array.map((item) => {
    // @ts-ignore
    return { ...item, _rowName: item.id || Math.random().toString() };
  });
}

export function getInput<T>(input: T) {
  return { input };
}

export function LocalizedTs(props: { value: string | number | null | undefined }) {
  const { value } = props;

  if (!value) {
    return null;
  }

  if (!isNaN(Number(value))) {
    const d = new Date(+value);
    return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
  }

  return <span>{value}</span>;
}

export const getColumns = (cols: IHeadCell<any>[], colDefs: any | undefined | null): IHeadCell<any>[] => {
  let x = getInitialColumns(cols, colDefs);

  let newColsOrder = cols
    .map((col) => ({ ...col, order: x[col.id].order, isShown: x[col.id].isShown }))
    .filter((x) => x.isShown)
    .sort((a, b) => a.order - b.order);

  return newColsOrder.map((x) => {
    return x;
  });
};

export function sortColumnsByLabelExceptFirst(cols: IHeadCell<any>[]): IHeadCell<any>[] {
  return [cols[0], ...cols.slice(1).sort((a, b) => a.label.localeCompare(b.label))] as IHeadCell<any>[];
}

export function reorderSequentialSet<T>(
  selectedSubSetKeys: string[],
  currentSet: T[],
  dir: 1 | -1 | 0,
  dataKey: keyof T,
  orderByValue: keyof T
) {
  if (selectedSubSetKeys.length === 0) return currentSet;

  const selectedSubSet = currentSet.filter((f) => selectedSubSetKeys.includes(f[dataKey] + ""));
  const result: any = {};
  const modifiedItems = new Set();

  let outOfTheBounds = false;
  let minPossibleLevel = 0;
  let maxPossibleLevel = currentSet.length - 1;

  const sortedSet = sortItems(currentSet, orderByValue.toString(), dir);
  if (dir === 1) {
    minPossibleLevel = sortedSet[0][orderByValue.toString()];
    maxPossibleLevel = sortedSet[currentSet.length - 1][orderByValue.toString()];
  } else {
    maxPossibleLevel = sortedSet[0][orderByValue.toString()];
    minPossibleLevel = sortedSet[currentSet.length - 1][orderByValue.toString()];
  }

  if (maxPossibleLevel - minPossibleLevel + 1 !== currentSet.length) {
    console.warn("Levels are not sequential.");
    return currentSet;
  }

  const sortedSelectedSubSet = sortItems(selectedSubSet, orderByValue.toString(), dir);
  for (const item of sortedSelectedSubSet) {
    let newLevel = item[orderByValue.toString()] - dir;

    if (newLevel <= minPossibleLevel && dir === 1) {
      if (newLevel < minPossibleLevel) outOfTheBounds = true;

      newLevel = minPossibleLevel;
      minPossibleLevel += dir;
    }

    if (newLevel >= maxPossibleLevel && dir === -1) {
      if (newLevel > maxPossibleLevel) outOfTheBounds = true;

      newLevel = maxPossibleLevel;
      maxPossibleLevel += dir;
    }

    modifiedItems.add(item[dataKey]);
    result[newLevel] = { ...item, newLevel };
  }

  for (const item of sortedSet) {
    if (modifiedItems.has(item[dataKey])) continue;

    let newLevel = item[orderByValue.toString()];
    while (result[newLevel] !== undefined) newLevel += dir;

    result[newLevel] = { ...item, newLevel };
  }

  if (!outOfTheBounds) dir = 0;

  const finalResult: any = Object.values(result)
    .map((item: any) => {
      return {
        ...item,
        [orderByValue]: item.newLevel - dir,
      };
    })
    .sort((l1, l2) => {
      if (l1[orderByValue.toString()] < l2[orderByValue.toString()]) return -1;
      if (l1[orderByValue.toString()] > l2[orderByValue.toString()]) return 1;
      return 0;
    });

  return finalResult;
}

const sortItems = (items: any[], field: string, dir: 1 | -1 | 0) => {
  const result = [...items];
  return result.sort((i1, i2) => {
    if (i1[field] < i2[field]) return -dir;
    if (i1[field] > i2[field]) return dir;
    return 0;
  });
};
