import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useState } from "react";

export function useMultiplePostData<Type>(props: { customClientId: string | undefined; onSuccess?: () => void; onError?: () => void }) {
  const [state, setState] = useState<{
    isLoading: boolean;
    data: Type | undefined;
    error: string | undefined;
  }>({
    isLoading: false,
    data: undefined,
    error: undefined,
  });

  const postData = useCallback(
    (queries: { graphqlQuery: string; variables?: any }[]) => {
      setState({
        isLoading: true,
        data: undefined,
        error: undefined,
      });
      const fetch = async () => {
        try {
          await Promise.allSettled(
            queries.map((q) => {
              if (props.customClientId) {
                if (q.variables?.input) {
                  q.variables.input.clientId = props.customClientId;
                }
              }

              return API.graphql(graphqlOperation(q.graphqlQuery, q.variables));
            })
          );

          if (props.onSuccess) {
            props.onSuccess();
          } else {
            setState({ isLoading: false, data: undefined, error: undefined });
          }
        } catch (result: any) {
          setState({
            isLoading: false,
            data: undefined,
            error: "Mutation failed! Reason(s): \n" + result.errors?.map((e: any) => e.message).join("\n"),
          });
          props.onError?.();
        }
      };
      fetch();
    },
    [props.onError, props.onSuccess, props.customClientId]
  );

  return { postData, state };
}

export interface GraphQLResult<T = object> {
  data?: T;
  errors?: { message?: string }[];
  extensions?: {
    [key: string]: any;
  };
}
