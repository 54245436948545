import { Box, LinearProgress, Paper, Toolbar, Typography } from "@mui/material";

import { NFreezer } from "../nFreezer/NFreezer";

export function NContainer(props: { title?: string; children: any; isLoading?: boolean; isFrozen?: boolean }) {
  return (
    <>
      <Box
        sx={{
          width: {
            xs: "100%",
            lg: "816px",
          },
        }}
      >
        <Paper sx={{ width: "100%", mb: 2 }}>
          {props.title && (
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
                {props.title}
              </Typography>
            </Toolbar>
          )}
          {props.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}
          <Box
            sx={{
              pl: 2,
              pr: 2,
              pb: 2,
            }}
          >
            {props.isLoading !== true && <NFreezer isLoading={props.isFrozen}>{props.children}</NFreezer>}
          </Box>
        </Paper>
      </Box>
    </>
  );
}
