import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import LaunchIcon from "@mui/icons-material/Launch";
import MLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { API, graphqlOperation } from "aws-amplify";
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { createConfigEntry } from "../../graphql/mutations";
import { CreateConfigEntryInput } from "../../shared/API";
import { generatePath, Link } from "react-router-dom";
import { getComparator } from "./NTable.utils";
import { getInput } from "../../shared/utils";
import { IHeadCell, Order } from "./NTable.types";
import { LinearProgress, Typography } from "@mui/material";
import { NTableHead } from "./NTableHead";
import { NTableMenuItem, NTableToolbar } from "./NTableToolbar";
import { SharedData } from "../../shared/sharedData/sharedData";
import { toast } from "react-toastify";

const getPrevState = (rememberKey: string | undefined, columns: IHeadCell<any>[]): any => {
  if (!rememberKey) {
    return undefined;
  }

  let x = SharedData.get(`${rememberKey}@prevState`);
  if (!x) {
    return undefined;
  }

  let prevStatewas = {
    orderType: undefined,
    orderBy: undefined,
    page: undefined,
    rowsPerPage: undefined,
  };

  if (["asc", "desc"].includes(x.orderType)) {
    prevStatewas.orderType = x.orderType;
  }

  if (columns.find((c) => c.id === x.orderBy)) {
    prevStatewas.orderBy = x.orderBy;
  }

  if (!isNaN(x.page)) {
    prevStatewas.page = x.page;
  }

  if (!isNaN(x.rowsPerPage)) {
    prevStatewas.rowsPerPage = x.rowsPerPage;
  }

  return prevStatewas;
};

export function NTable<T>(props: {
  title?: string;
  defaultOrderBy?: keyof T;
  defaultOrder?: Order;
  rows: (T & { _rowName: string })[];
  columns: IHeadCell<T>[];
  onAddClick?: () => void;
  isLoading?: boolean;
  error?: string;
  checkAble?: boolean;
  elementsTop?: ReactNode;
  hidePagination?: boolean;
  hideBoxShadow?: boolean;
  condensed?: boolean;
  onSelectionAvailableActions?: NTableMenuItem[];
  onCheck?: (newCheckSelection: string[]) => void;
  rememberStateKey?: string;
  supportsColumnOrder?: {
    tableKey: string;
    onNewOrder: () => void;
  };
  toolbarIsDense?: boolean;
  stickyHeadersMaxHeight?: string;
}) {
  const prevState = useMemo(() => getPrevState(props.rememberStateKey, props.columns), [props.rememberStateKey, props.columns]);
  const [orderType, setOrderType] = useState<Order | undefined>(prevState?.orderType ?? props.defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(prevState?.orderBy ?? props.defaultOrderBy);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(prevState?.page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(props.hidePagination ? Number.MAX_SAFE_INTEGER : prevState?.rowsPerPage ?? 10);

  useEffect(() => {
    if (props.rememberStateKey && !props.isLoading) {
      let currentState = {
        rowsPerPage,
        page,
        orderBy,
        orderType,
      };

      SharedData.set(`${props.rememberStateKey}@prevState`, currentState);
      setTimeout(() => {
        const fetch = async () => {
          try {
            await API.graphql(
              graphqlOperation(
                createConfigEntry,
                getInput<CreateConfigEntryInput>({
                  id: `${props.rememberStateKey}@prevState`,
                  value: JSON.stringify(currentState),
                })
              )
            );
          } catch (result: any) {
            toast.error("Failed to sync table state, contact Administrator!");
          }
        };
        fetch();
      });
    }
  }, [rowsPerPage, page, orderBy, orderType, props.rememberStateKey, props.isLoading]);

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && orderType === "asc";
    setOrderType(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n._rowName);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const { onCheck } = props;
  useEffect(() => {
    onCheck?.(selected);
  }, [selected, onCheck]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          boxShadow: props.hideBoxShadow ? "unset" : undefined,
        }}
      >
        {(props.title || props.onAddClick || props.supportsColumnOrder) && (
          <NTableToolbar
            toolbarIsDense={props.toolbarIsDense}
            numSelected={selected.length}
            title={props.title}
            onAddClick={props.onAddClick}
            onSelectionAvailableActions={props.onSelectionAvailableActions}
            supportsColumnOrder={props.supportsColumnOrder}
          />
        )}

        {props.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}

        {!props.isLoading && props.elementsTop && <div style={{ padding: "0px 12px" }}>{props.elementsTop}</div>}

        <TableContainer sx={{ maxHeight: props.stickyHeadersMaxHeight != null ? props.stickyHeadersMaxHeight : undefined }}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader={props.stickyHeadersMaxHeight != null}
            size={props.condensed || SharedData.get("table_is_condensed") ? "small" : "medium"}
          >
            {props.error && (
              <caption>
                <Typography color="orangered" variant="inherit">
                  Error: {props.error}
                </Typography>
              </caption>
            )}
            <NTableHead
              tableProps={{
                numSelected: selected.length,
                order: orderType,
                orderBy: orderBy?.toString(),
                onRequestSort: handleRequestSort,
                onSelectAllClick: handleSelectAllClick,
                rowCount: props.rows.length,
              }}
              headCells={props.isLoading ? [] : props.columns}
              checkAble={props.checkAble}
            />
            <TableBody>
              {props.rows
                .sort(getComparator(orderType, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._rowName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={props.checkAble ? (event) => handleClick(event, row._rowName) : undefined}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {props.checkAble && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}

                      {(props.isLoading ? [] : props.columns).map((c, i) => {
                        const value = getValue(c, row);

                        if (i === 0) {
                          return (
                            <TableCell key={i} component="th" id={labelId} scope="row" padding={c.padding}>
                              {c.linkTo ? (
                                <MLink
                                  component={Link}
                                  to={generatePath(c.linkTo ?? "", {
                                    id: (row as any).id.toString(),
                                  })}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "1ch",
                                      alignItems: "center",
                                      color: "primary.dark",
                                    }}
                                  >
                                    <LaunchIcon sx={{ fontSize: "1.2em" }} />
                                    {value}
                                  </Box>
                                </MLink>
                              ) : (
                                <>{value}</>
                              )}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={i} align={c.rightAligned || c.numeric ? "right" : undefined}>
                              {value}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (props.condensed || SharedData.get("table_is_condensed") ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={props.columns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!props.hidePagination && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component="div"
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

function getValue<T>(c: IHeadCell<T>, row: any) {
  if (c.customRender) {
    return c.customRender(row[c.id], row);
  }

  const isNumber = c.numeric && !isNaN(row[c.id] as any);
  if (isNumber) {
    return Number(row[c.id]).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return row[c.id]?.toString();
}
