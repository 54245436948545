import { IHeadCell } from "../../components/nTable/NTable.types";
import { Log } from "../../shared/API";
import { LocalizedTs, sortColumnsByLabelExceptFirst } from "../../shared/utils";

export const LogColumns: IHeadCell<Log>[] = sortColumnsByLabelExceptFirst([
  {
    id: "timestamp",
    label: "Timestamp",
    numeric: false,
    disablePadding: false,
    customRender: (value: Log) => <LocalizedTs value={value.timestamp} />,
  },
  {
    id: "src",
    label: "Source",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "srcId",
    label: "Source ID",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "info",
    label: "Info",
    numeric: false,
    disablePadding: false,
  },
]);
