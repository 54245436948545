import { Save } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, DialogContentText, Typography, TextField } from "@mui/material";
import { useState } from "react";
import { v4 } from "uuid";
import { NTable } from "../../components/nTable/NTable";
import { IHeadCell } from "../../components/nTable/NTable.types";

import { NLevel } from "./Template.types";

export function usePasteLevelsDialog(props: { onFullfilled: (levels: NLevel[]) => void }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<{
    text: string | undefined;
    levels: NLevel[];
    error: string | null;
  }>({
    text: undefined,
    levels: [],
    error: null,
  });

  const openDialog = () => {
    setOpen(true);
    setState({
      text: undefined,
      levels: [],
      error: null,
    });
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const PasteLevelsDialog = (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth>
        <DialogTitle>Paste levels</DialogTitle>

        <DialogContent>
          <DialogContentText>To create/update level, fullfill the values below</DialogContentText>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography pb={1}>Example of data structure in Excel</Typography>
              <NTable
                hidePagination
                defaultOrder="asc"
                defaultOrderBy="level"
                columns={
                  [
                    { id: "level", disablePadding: false, label: "Level", numeric: false },
                    { id: "martingale", disablePadding: false, label: "Martingale", numeric: false },
                    { id: "priceShift", disablePadding: false, label: "Price shift", numeric: false },
                    { id: "takeProfit", disablePadding: false, label: "Take profit", numeric: false },
                  ] as IHeadCell<NLevel>[]
                }
                rows={[
                  { level: 1, martingale: 1, priceShift: 2, takeProfit: 3, __typename: "TemplateLevel", __customId: v4(), _rowName: "1" },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Paste content here"
                multiline
                maxRows={10}
                minRows={4}
                value={state.text}
                onChange={(evt) => {
                  let error = "";
                  let levels: NLevel[] = [];

                  try {
                    let lines = evt.target.value.split(/\r\n|\n/);
                    let firstLineSplit = lines[0].split(/\t/);

                    let levelIndex: number | undefined = undefined;
                    let martingaleIndex: number | undefined = undefined;
                    let priceShiftIndex: number | undefined = undefined;
                    let takeProfitIndex: number | undefined = undefined;

                    firstLineSplit.forEach((el, index) => {
                      switch (el.toLowerCase()) {
                        case "level":
                          levelIndex = index;
                          break;

                        case "martingale":
                          martingaleIndex = index;
                          break;

                        case "price shift":
                          priceShiftIndex = index;
                          break;

                        case "take profit":
                          takeProfitIndex = index;
                          break;
                      }
                    });

                    lines.slice(1, lines.length).forEach((l) => {
                      let line = l.split(/\t/);

                      if (line.length > 2) {
                        levels.push({
                          __customId: v4(),
                          __typename: "TemplateLevel",
                          level: levelIndex != null ? Number(line[levelIndex]) : undefined,
                          martingale: martingaleIndex != null ? Number(line[martingaleIndex]) : undefined,
                          priceShift: priceShiftIndex != null ? Number(line[priceShiftIndex]) : undefined,
                          takeProfit: takeProfitIndex != null ? Number(line[takeProfitIndex]) : undefined,
                        });
                      }
                    });

                    if (levels.length === 0) {
                      throw new Error("Something was parsed but returned 0 levels!");
                    }
                  } catch (Exception) {
                    console.error(Exception);
                    error = "Failed to parse pasted data!";
                  }

                  console.log(levels);

                  setState({
                    text: evt.target.value,
                    levels: levels,
                    error: error,
                  });
                }}
                fullWidth
                variant="filled"
              />
            </Grid>
          </Grid>
          {state.error && (
            <Typography color={"orangered"} py={2}>
              {state.error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "0px 24px 24px 0px" }}>
          <Button onClick={closeDialog}>Cancel</Button>{" "}
          <Button
            disabled={!!state.error || (state.text || "").trim().length === 0}
            onClick={() => {
              closeDialog();
              props.onFullfilled(state.levels);
            }}
            variant="contained"
            startIcon={<Save />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return { openDialog, TemplateDialog: PasteLevelsDialog };
}
