import { ReactNode } from "react";

import { CircularProgress } from "@mui/material";

export function NFreezer(props: { isLoading?: boolean; children: ReactNode }) {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          filter: props.isLoading ? "blur(2px)" : undefined,
        }}
      >
        {props.children}
      </div>
      {props.isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            animation: "fadeIn 0.4s ease-in-out",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              position: "sticky",
              top: "10%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10%",
              flexFlow: "column",
            }}
          >
            <CircularProgress />
            <div>Saving...</div>
          </div>
        </div>
      )}
    </div>
  );
}
