import { Box, LinearProgress, Paper, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { getInput } from "../../shared/utils";
import { getPortfolio } from "../../graphql/queries";
import { GetPortfolioInput, GetPortfolioQuery } from "../../shared/API";
import { Logs } from "./children/logs";
import { OrdersChain } from "./children/ordersChain";
import { Overview } from "./children/portfolioOverview";
import { Trades } from "./children/trades";
import { useGetData } from "../../shared/useGetData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useUserPreferences } from "../_appLayout/AppContent";

export function PortfolioDash() {
  const { id = "" } = useParams();
  const { customClientId } = useUserPreferences();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const dataState = useGetData<GetPortfolioQuery>({
    customClientId,
    graphqlQuery: getPortfolio,
    variables: getInput<GetPortfolioInput>({ id }),
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
            Portfolio: {dataState.data?.getPortfolio?.name}
          </Typography>
        </Toolbar>
        {dataState.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={selectedTabIndex} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" id="0" />
            <Tab label="Trades" id="1" />
            <Tab label="Orders" id="2" />
            <Tab label="Logs" id="3" />
          </Tabs>
        </Box>
        <TabPanel value={selectedTabIndex} index={0}>
          <Overview data={dataState.data?.getPortfolio} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={1}>
          <Trades portfolioId={id} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={2}>
          <OrdersChain ordersChain={dataState.data?.getPortfolio?.ordersChain} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={3}>
          <Logs portfolioId={id} />
        </TabPanel>
      </Paper>
    </Box>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
