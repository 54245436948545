import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { NContainer } from "../../components/nContainer/NContainer";
import { useUserPreferences } from "../_appLayout/AppContent";

export function UserSettings() {
  const uPref = useUserPreferences();

  return (
    <>
      <NContainer title="User settings">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormLabel>Theme</FormLabel>
            <RadioGroup row defaultValue={uPref.theme?.palette.mode} onChange={uPref.toggleTheme}>
              <FormControlLabel value="dark" control={<Radio />} label="Dark" />
              <FormControlLabel value="light" control={<Radio />} label="Light" />
            </RadioGroup>
          </Grid>
          {/* <Grid item xs={12} md={6}>
                <FormLabel>Table row height</FormLabel>
                <RadioGroup row defaultValue={uPref.tableCondensed} onChange={uPref.toggleTableCondense}>
                  <FormControlLabel value={true} control={<Radio />} label="Condensed" />
                  <FormControlLabel value={false} control={<Radio />} label="Normal" />
                </RadioGroup>
              </Grid> */}
        </Grid>
      </NContainer>
    </>
  );
}
