import { ArrowDownward, ArrowUpward, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Visibility, VisibilityOff } from "@mui/icons-material";
import { createConfigEntry } from "../../../graphql/mutations";
import { CreateConfigEntryInput } from "../../../shared/API";
import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { getInput, reorderSequentialSet } from "../../../shared/utils";
import { NFreezer } from "../../nFreezer/NFreezer";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { toast } from "react-toastify";
import { usePostData } from "../../../shared/usePostData";
import { useUserPreferences } from "../../../views/_appLayout/AppContent";
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

type ISimpleHeadCell = {
  id: string | number | symbol;
  label: string;
};

export type ISelectedTableProps = {
  key: string;
  label: string;
  columns: ISimpleHeadCell[];
};

type ICell = ISimpleHeadCell & {
  order: number;
  isShown: boolean;
};

export const getInitialColumns = (
  COLUMNS: any[],
  colDefs: { id?: string; label?: string; isShown?: boolean; order?: number }[] | undefined | null
) => {
  let returnData: { [key: string | number | symbol]: ICell } = {
    [COLUMNS[0].id]: { ...COLUMNS[0], order: 0, isShown: true },
  };

  let existingsColSettings: ICell[] = [];

  //#region parse known tableSettingsformat
  let knownSettings: ICell[] = [];
  if (colDefs) {
    try {
      if (Array.isArray(colDefs) && colDefs.every((c) => c.id && c.order != null && !isNaN(c.order) && c.isShown != null)) {
        // @ts-ignore
        knownSettings = colDefs;
      }
    } catch (error) {
      // can not parse settings, it could be outdated or something, in that case there are no known settings
    }
  }

  //#endregion

  //#region fill existing col settings
  knownSettings.forEach((colProps) => {
    // first column is always hardcoded to be shown and order 0
    // that's why we omit it
    if (colProps.id !== COLUMNS[0].id) {
      let existingCol = COLUMNS.find((c) => c.id === colProps.id);
      if (existingCol) {
        existingsColSettings.push({
          ...existingCol,
          isShown: colProps.isShown,
          order: colProps.order,
        });
      }
    }
  });
  existingsColSettings.sort((a, b) => a.order - b.order).forEach((col, i) => (col.order = i + 1));
  //#endregion

  let maxSetOrder = existingsColSettings[existingsColSettings.length - 1]?.order || 0;

  COLUMNS.slice(1).forEach((col) => {
    if (!existingsColSettings.find((c) => c.id === col.id)) {
      maxSetOrder += 1;
      existingsColSettings.push({
        ...col,
        isShown: true,
        order: maxSetOrder,
      });
    }
  });

  existingsColSettings.forEach((col) => {
    returnData[col.id] = col;
  });

  return returnData;
};

function TableSettingsDetailsImpl(
  props: { selectedTableProps: ISelectedTableProps; savedProps: any },
  ref: ForwardedRef<{ saveColumnDefs: (callback?: () => void) => void }>
) {
  const theme = useTheme();
  const { customClientId } = useUserPreferences();
  const [columns, setColumns] = useState(getInitialColumns(props.selectedTableProps.columns, props.savedProps));

  const moveDirection = useCallback((col: ICell, direction: 1 | -1) => {
    setColumns((prevState) => {
      const swapClickedWith = Object.values(prevState).find((f) => f.order === col.order + direction);

      if (swapClickedWith) {
        swapClickedWith.order = col.order;
        col.order = col.order + direction;

        prevState[col.id] = col;
        prevState[swapClickedWith.id] = swapClickedWith;
      }

      return { ...prevState };
    });
  }, []);

  const onCheck = useCallback((col: ICell) => {
    setColumns((prevState) => {
      prevState[col.id].isShown = !prevState[col.id].isShown;
      return { ...prevState };
    });
  }, []);

  const x = usePostData({
    customClientId,

    onSuccess: () => {
      toast.success("Settings succesfully synced!");
    },
    onError: () => {
      toast.error("Failed to sync table settings!");
    },
  });

  const saveColumnDefs = useCallback(
    (callBack?: () => void) => {
      const newSettings = Object.values(columns);
      SharedData.set(props.selectedTableProps.key, newSettings);

      callBack?.();

      x.postData(
        createConfigEntry,
        getInput<CreateConfigEntryInput>({
          id: props.selectedTableProps.key,
          value: JSON.stringify(newSettings),
        })
      );
    },
    [props.selectedTableProps.key, columns, x]
  );

  useImperativeHandle(ref, () => ({
    saveColumnDefs,
  }));

  const [selectedCols, setSelectecedCols] = useState<string[]>([]);
  const selectedColsById = selectedCols.reduce((pv: any, cv: any) => {
    pv[cv] = true;
    return pv;
  }, {});

  const toggleLevelSelection = useCallback((id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectecedCols((prevState) => [...prevState, id]);
    } else {
      setSelectecedCols((prevState) => prevState.filter((f) => f !== id));
    }
  }, []);

  const moveUp = useCallback(() => {
    setColumns((prevColumns) => {
      const newState = reorderSequentialSet<ICell>(selectedCols, Object.values(prevColumns), 1, "id", "order").reduce(
        (pv: any, cv: any) => {
          pv[cv.id] = cv;
          return pv;
        },
        {}
      );
      return newState;
    });
  }, [selectedCols]);

  const moveDown = useCallback(() => {
    setColumns((prevColumns) => {
      const newState = reorderSequentialSet<ICell>(selectedCols, Object.values(prevColumns), -1, "id", "order").reduce(
        (pv: any, cv: any) => {
          pv[cv.id] = cv;
          return pv;
        },
        {}
      );
      return newState;
    });
  }, [selectedCols]);

  return (
    <NFreezer isLoading={x.state.isLoading}>
      <Toolbar
        sx={{
          pl: { sm: selectedCols.length > 0 ? 2 : 0 },
          pr: { xs: 1, sm: 1 },
          bgcolor: selectedCols.length > 0 ? theme.palette.primary.main : theme.palette.background.paper,
          position: "sticky",
          top: "0px",
          zIndex: 11,
        }}
      >
        {selectedCols.length > 0 ? (
          <>
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
              {selectedCols.length} selected
            </Typography>

            <Tooltip title="+1 Level">
              <IconButton onClick={moveDown}>
                <KeyboardDoubleArrowDown />
              </IconButton>
            </Tooltip>
            <Tooltip title="-1 Level">
              <IconButton onClick={moveUp}>
                <KeyboardDoubleArrowUp />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Typography sx={{ flex: "1 1 100%" }} variant="body1" id="tableTitle" component="div">
              All available columns
            </Typography>
          </>
        )}
      </Toolbar>
      <TableContainer sx={{ maxHeight: "calc(100vh - 310px)" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: theme.palette.background.paper }}></TableCell>
              <TableCell sx={{ bgcolor: theme.palette.background.paper }} padding="checkbox"></TableCell>
              <TableCell sx={{ bgcolor: theme.palette.background.paper }}>Column name</TableCell>
              <TableCell sx={{ bgcolor: theme.palette.background.paper }} padding="checkbox"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(columns)
              .sort((a, b) => a.order - b.order)
              .map((c, i, a) => {
                return (
                  <TableRow key={i} selected={selectedColsById[c.id.toString()] ?? false}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={i === 0}
                        checked={selectedColsById[c.id.toString()] ?? false}
                        onChange={() => {
                          toggleLevelSelection(c.id.toString(), !selectedColsById[c.id.toString()]);
                        }}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        {...(i === 0 ? { checked: true, disabled: true } : { checked: c.isShown })}
                        icon={<VisibilityOff />}
                        checkedIcon={<Visibility />}
                        onChange={() => {
                          onCheck(c);
                        }}
                      />
                    </TableCell>
                    <TableCell>{c.label}</TableCell>
                    <TableCell padding="checkbox">
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={() => {
                            moveDirection(c, 1);
                          }}
                          disabled={i === 0 || i === a.length - 1}
                        >
                          <ArrowDownward />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            moveDirection(c, -1);
                          }}
                          disabled={i === 0 || i === 1}
                        >
                          <ArrowUpward />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </NFreezer>
  );
}

export const TableSettingsDetails = forwardRef(TableSettingsDetailsImpl);
