import { Delete } from "@mui/icons-material";
import { Checkbox, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { NLevel } from "./Template.types";

export function LevelRow({
  level,
  refValue,
  onSelection,
  onRemove,
}: {
  level: NLevel;
  refValue: any;
  onSelection: (levelId: string, isSelected: boolean) => void;
  onRemove: (levelId: string) => void;
}) {
  const [state, setState] = useState({
    level: level.level,
    martingale: level.martingale ?? null,
    priceShift: level.priceShift ?? null,
    takeProfit: level.takeProfit ?? null,
    _isSelected: false,
  });

  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setState((prevState) => {
        const newState = {
          ...prevState,
          [e.target.name]: e.target.value,
        };
        refValue[level.__customId] = newState;
        return newState;
      });
    },
    [refValue, level.__customId]
  );

  const onCheck = useCallback(
    (_: any, value: boolean) => {
      setState((prevState) => {
        onSelection(level.__customId, value);
        return {
          ...prevState,
          _isSelected: value,
        };
      });
    },
    [level.__customId, onSelection]
  );

  useEffect(() => {
    refValue[level.__customId] = state;
  }, [refValue, level.__customId, state]);

  return (
    <TableRow
      selected={state._isSelected}
      key={level.__customId}
      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={state._isSelected} onChange={onCheck} />
      </TableCell>
      <TableCell component="th" scope="row">
        {level.level}
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          onChange={onTextChange}
          value={state.martingale}
          name="martingale"
          size="small"
          hiddenLabel
          type="number"
          required
          variant="standard"
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          onChange={onTextChange}
          value={state.priceShift}
          name="priceShift"
          size="small"
          hiddenLabel
          type="number"
          required
          variant="standard"
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <TextField
          onChange={onTextChange}
          value={state.takeProfit}
          name="takeProfit"
          size="small"
          hiddenLabel
          type="number"
          required
          variant="standard"
          fullWidth
        />
      </TableCell>
      <TableCell sx={{ p: 1 }}>
        <IconButton
          onClick={() => {
            onRemove(level.__customId);
          }}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
