import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { KnownColumns } from "../../../views/_appLayout/AppContent";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { TableSettingsDetails } from "./TableSettingsDetails";
import { useRef, useState } from "react";

export function useNTableSettingsDialog(props: { onNewSettings: () => void; dataKey: string }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const ColsById = KnownColumns.reduce((pv, cv) => {
    pv[cv.key] = cv;

    return pv;
  }, {} as any);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const DetailsRef = useRef<{ saveColumnDefs: (callback?: () => void) => void }>(null);

  const DialogRender = (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth>
        <DialogTitle sx={{ bgcolor: theme.palette.background.paper }}>Modify table columns</DialogTitle>

        <DialogContent sx={{ bgcolor: theme.palette.background.paper }}>
          {/* <DialogContentText>To create/update level, fullfill the values below</DialogContentText> */}

          <TableSettingsDetails selectedTableProps={ColsById[props.dataKey]} savedProps={SharedData.get(props.dataKey)} ref={DetailsRef} />

          <br />
        </DialogContent>

        <DialogActions sx={{ padding: "0px 24px 24px 0px", bgcolor: theme.palette.background.paper }}>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            // disabled={!!state.error || (state.text || "").trim().length === 0}
            onClick={() => {
              DetailsRef.current?.saveColumnDefs(() => {
                closeDialog();
                props.onNewSettings();
              });
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return { openDialog, DialogRender };
}
