import GoToNextLimit from "../../components/GoToNextToken";
import { getColumns, getInput, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../shared/utils";
import { getTemplates } from "../../graphql/queries";
import { GetTemplatesInput, GetTemplatesQuery, Template } from "../../shared/API";
import { IHeadCell } from "../../components/nTable/NTable.types";
import { NTable } from "../../components/nTable/NTable";
import { SharedData } from "../../shared/sharedData/sharedData";
import { useGetData } from "../../shared/useGetData";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUserPreferences } from "../_appLayout/AppContent";

const fetchLimit = 1000;

export const templateListColumns: IHeadCell<Template>[] = sortColumnsByLabelExceptFirst([
  {
    id: "name",
    label: "Name",
    disablePadding: false,
    numeric: false,
    linkTo: "/template/:id/dash",
  },
  {
    id: "maxBuyVolume",
    label: "Max buy volume",
    disablePadding: false,
    numeric: true,
  },
  {
    id: "maxSellVolume",
    label: "Max sell volume",
    disablePadding: false,
    numeric: true,
  },
]);

const TABLE_KEY = "templates";

export function Templates() {
  const { customClientId } = useUserPreferences();
  const navigate = useNavigate();

  const dataState = useGetData<GetTemplatesQuery>({
    customClientId,
    graphqlQuery: getTemplates,
    variables: getInput<GetTemplatesInput>({ limit: fetchLimit }),
  });

  const onAddClick = () => navigate("/template/new");

  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY));

  return (
    <>
      <NTable
        stickyHeadersMaxHeight="calc(100vh - 220px)"
        onAddClick={onAddClick}
        rememberStateKey={TABLE_KEY}
        title="Templates"
        isLoading={dataState.isLoading}
        error={dataState.error}
        columns={getColumns(templateListColumns, colsConfig)}
        supportsColumnOrder={{ tableKey: TABLE_KEY, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY)) }}
        defaultOrder="desc"
        defaultOrderBy="name"
        rows={prepareTableRows(dataState.data?.getTemplates?.items ?? [])}
      />
      <GoToNextLimit nextToken={dataState.data?.getTemplates?.nextToken} limit={fetchLimit} goToAction={dataState.fetchData} />
    </>
  );
}
