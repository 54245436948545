/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateExchangeInput = {
  clientId?: string | null,
  name: string,
  kind: ExchangeEnum,
  publicKey: string,
  secretKey: string,
};

export enum ExchangeEnum {
  binance_usdfut = "binance_usdfut",
  binance_usdfut_mock = "binance_usdfut_mock",
  binance_spot = "binance_spot",
}


export type Exchange = {
  __typename: "Exchange",
  clientId?: string | null,
  id?: string | null,
  kind?: ExchangeEnum | null,
  name?: string | null,
  publicKey?: string | null,
  lastTransferMoment?: number | null,
  lastRequestEndTime?: number | null,
  totalTransferredUSDT?: number | null,
  totalInitialMargin?: number | null,
  totalMaintMargin?: number | null,
  totalWalletBalance?: number | null,
  totalUnrealizedProfit?: number | null,
  totalMarginBalance?: number | null,
  totalPositionInitialMargin?: number | null,
  totalOpenOrderInitialMargin?: number | null,
  totalCrossWalletBalance?: number | null,
  totalCrossUnPnl?: number | null,
  availableBalance?: number | null,
  maxWithdrawAmount?: number | null,
  totalNotional?: number | null,
};

export type UpdateExchangeInput = {
  clientId?: string | null,
  id: string,
  name?: string | null,
  publicKey?: string | null,
  secretKey?: string | null,
};

export type DeleteExchangeInput = {
  clientId?: string | null,
  id: string,
};

export type CreatePortfolioInput = {
  clientId?: string | null,
  name: string,
  trdPair: string,
  exchangeId: string,
  templateId: string,
  status: string,
  buy: boolean,
  sell: boolean,
  onlyClose: boolean,
  initialVolume: number,
};

export type Portfolio = {
  __typename: "Portfolio",
  clientId?: string | null,
  id?: string | null,
  trdPair?: string | null,
  exchange?: Exchange | null,
  template?: Template | null,
  name?: string | null,
  status?: string | null,
  buy?: boolean | null,
  sell?: boolean | null,
  onlyClose?: boolean | null,
  cooldown?: number | null,
  initialVolume?: number | null,
  tradeVolume?: number | null,
  absInitialVolume?: number | null,
  initialVolumeEUR?: number | null,
  fixedVolume?: number | null,
  fixedAmount?: number | null,
  initialPrice?: number | null,
  positionControl?: string | null,
  amount?: number | null,
  volume?: number | null,
  absVolume?: number | null,
  level?: number | null,
  takeProfit?: number | null,
  priceShift?: number | null,
  martingale?: number | null,
  limit?: number | null,
  lastTradeMoment?: number | null,
  orders?:  Array<Order | null > | null,
  ordersChain?:  Array<Order | null > | null,
  trades?:  Array<Trade | null > | null,
  bid?: number | null,
  ask?: number | null,
  levels?:  Array<Level | null > | null,
  ordersType?: string | null,
  nextLevelPrice?: number | null,
  nextLevelVolume?: number | null,
  takeProfitPrice?: number | null,
};

export type Template = {
  __typename: "Template",
  clientId?: string | null,
  id?: string | null,
  name?: string | null,
  levels?:  Array<TemplateLevel | null > | null,
  maxBuyVolume?: number | null,
  maxSellVolume?: number | null,
};

export type TemplateLevel = {
  __typename: "TemplateLevel",
  id?: string | null,
  level?: number | null,
  priceShift?: number | null,
  takeProfit?: number | null,
  martingale?: number | null,
};

export type Order = {
  __typename: "Order",
  clientId?: string | null,
  portfolioId?: string | null,
  id?: string | null,
  level?: number | null,
  orderId?: string | null,
  time?: string | null,
  status?: string | null,
  dir?: string | null,
  price?: number | null,
  qty?: number | null,
  volume?: number | null,
  role?: string | null,
  kind?: string | null,
  type?: string | null,
  fulfilled?: number | null,
  qtyChange?: number | null,
  linked?: string | null,
  trdPair?: string | null,
  trdAmount?: number | null,
};

export type Trade = {
  __typename: "Trade",
  clientId?: string | null,
  orderId?: string | null,
  id?: string | null,
  price?: number | null,
  qty?: number | null,
  time?: number | null,
  dir?: string | null,
  trdPair?: string | null,
  level?: number | null,
};

export type Level = {
  __typename: "Level",
  clientId?: string | null,
  strategyId?: string | null,
  id?: string | null,
  level?: number | null,
  takeProfit?: number | null,
  priceShift?: number | null,
  martingale?: number | null,
};

export type UpdatePortfolioInput = {
  clientId?: string | null,
  id: string,
  name?: string | null,
  status?: string | null,
  buy?: boolean | null,
  sell?: boolean | null,
  onlyClose?: boolean | null,
  cooldown?: number | null,
  initialVolume?: number | null,
  templateId?: string | null,
  task?: string | null,
};

export type DeletePortfolioInput = {
  clientId?: string | null,
  id: string,
};

export type PlacePortfolioOrderInput = {
  id: string,
  dir: string,
  amount?: number | null,
  volume?: number | null,
};

export type CreateTemplateInput = {
  clientId?: string | null,
  name: string,
  levels: Array< TemplateLevelInput >,
};

export type TemplateLevelInput = {
  level: number,
  priceShift: number,
  takeProfit: number,
  martingale: number,
};

export type UpdateTemplateInput = {
  clientId?: string | null,
  id: string,
  levels: Array< TemplateLevelInput >,
};

export type DeleteTemplateInput = {
  clientId?: string | null,
  id: string,
};

export type CreateConfigEntryInput = {
  id: string,
  value: string,
};

export type ConfigEntry = {
  __typename: "ConfigEntry",
  id?: string | null,
  value?: string | null,
};

export type UpdateConfigEntryInput = {
  id: string,
  value: string,
};

export type DeleteConfigEntryInput = {
  id: string,
};

export type UpdateTradingModeInput = {
  mode: string,
};

export type TradingMode = {
  __typename: "TradingMode",
  mode?: string | null,
};

export enum CRUD {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
}


export type LogInput = {
  clientId: string,
  exchangeId: string,
  portfolioId: string,
  timestamp?: number | null,
  src?: string | null,
  srcId?: string | null,
  info?: string | null,
};

export type ModifiedLogPayload = {
  __typename: "ModifiedLogPayload",
  operation: CRUD,
  clientId: string,
  payload: Log,
};

export type Log = {
  __typename: "Log",
  clientId?: string | null,
  exchangeId?: string | null,
  portfolioId?: string | null,
  timestamp?: number | null,
  src?: string | null,
  srcId?: string | null,
  info?: string | null,
};

export type TradeInput = {
  portfolioId?: string | null,
  exchangeId?: string | null,
  id?: string | null,
  orderId?: string | null,
  price?: number | null,
  qty?: number | null,
  time?: number | null,
  dir?: string | null,
  trdPair?: string | null,
  level?: number | null,
};

export type ModifiedTradePayload = {
  __typename: "ModifiedTradePayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Trade | null,
};

export type PortfolioInput = {
  id?: string | null,
  status?: string | null,
  buy?: boolean | null,
  sell?: boolean | null,
  tradeVolume?: number | null,
  initialVolume?: number | null,
  absInitialVolume?: number | null,
  initialVolumeEUR?: number | null,
  fixedVolume?: number | null,
  fixedAmount?: number | null,
  initialPrice?: number | null,
  amount?: number | null,
  volume?: number | null,
  absVolume?: number | null,
  level?: number | null,
  lastTradeMoment?: number | null,
  bid?: number | null,
  ask?: number | null,
  nextLevelPrice?: number | null,
  nextLevelVolume?: number | null,
  takeProfitPrice?: number | null,
  ordersChain?: Array< OrderInput | null > | null,
};

export type OrderInput = {
  portfolioId?: string | null,
  id?: string | null,
  orderId?: string | null,
  dir?: string | null,
  price?: number | null,
  qty?: number | null,
  volume?: number | null,
  role?: string | null,
  type?: string | null,
  fulfilled?: number | null,
  qtyChange?: number | null,
  linked?: string | null,
  trdPair?: string | null,
  trdAmount?: number | null,
  level?: number | null,
};

export type ModifiedPortfolioPayload = {
  __typename: "ModifiedPortfolioPayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Portfolio | null,
};

export type ExchangeInput = {
  clientId?: string | null,
  id?: string | null,
  lastTransferMoment?: number | null,
  lastRequestEndTime?: number | null,
  totalTransferredUSDT?: number | null,
  totalInitialMargin?: number | null,
  totalMaintMargin?: number | null,
  totalWalletBalance?: number | null,
  totalUnrealizedProfit?: number | null,
  totalMarginBalance?: number | null,
  totalPositionInitialMargin?: number | null,
  totalOpenOrderInitialMargin?: number | null,
  totalCrossWalletBalance?: number | null,
  totalCrossUnPnl?: number | null,
  availableBalance?: number | null,
  maxWithdrawAmount?: number | null,
};

export type ModifiedExchangePayload = {
  __typename: "ModifiedExchangePayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Exchange | null,
};

export type ModifiedOrderPayload = {
  __typename: "ModifiedOrderPayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Order | null,
};

export type PositionInput = {
  exchangeId?: string | null,
  symbol?: string | null,
  positionAmt?: number | null,
  entryPrice?: number | null,
  markPrice?: number | null,
  unRealizedProfit?: number | null,
  liquidationPrice?: number | null,
  leverage?: number | null,
  maxNotionalValue?: number | null,
  marginType?: string | null,
  isolatedMargin?: number | null,
  isAutoAddMargin?: boolean | null,
  positionSide?: string | null,
  notional?: number | null,
  isolatedWallet?: number | null,
  updateTime?: number | null,
};

export type ModifiedPositionPayload = {
  __typename: "ModifiedPositionPayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Position | null,
};

export type Position = {
  __typename: "Position",
  clientId?: string | null,
  exchangeId?: string | null,
  symbol?: string | null,
  positionAmt?: number | null,
  entryPrice?: number | null,
  markPrice?: number | null,
  unRealizedProfit?: number | null,
  liquidationPrice?: number | null,
  leverage?: number | null,
  maxNotionalValue?: number | null,
  marginType?: string | null,
  isolatedMargin?: number | null,
  isAutoAddMargin?: boolean | null,
  positionSide?: string | null,
  notional?: number | null,
  isolatedWallet?: number | null,
  updateTime?: number | null,
};

export type BalanceInput = {
  exchangeId?: string | null,
  asset?: string | null,
  walletBalance?: number | null,
  unrealizedProfit?: number | null,
  marginBalance?: number | null,
  maintMargin?: number | null,
  initialMargin?: number | null,
  positionInitialMargin?: number | null,
  openOrderInitialMargin?: number | null,
  crossWalletBalance?: number | null,
  crossUnPnl?: number | null,
  availableBalance?: number | null,
  maxWithdrawAmount?: number | null,
  marginAvailable?: boolean | null,
  updateTime?: number | null,
};

export type ModifiedBalancePayload = {
  __typename: "ModifiedBalancePayload",
  operation?: CRUD | null,
  clientId: string,
  payload?: Balance | null,
};

export type Balance = {
  __typename: "Balance",
  clientId?: string | null,
  exchangeId?: string | null,
  asset?: string | null,
  walletBalance?: number | null,
  unrealizedProfit?: number | null,
  marginBalance?: number | null,
  maintMargin?: number | null,
  initialMargin?: number | null,
  positionInitialMargin?: number | null,
  openOrderInitialMargin?: number | null,
  crossWalletBalance?: number | null,
  crossUnPnl?: number | null,
  availableBalance?: number | null,
  maxWithdrawAmount?: number | null,
  marginAvailable?: boolean | null,
  updateTime?: number | null,
};

export type GetPermissionsInput = {
  limit: number,
  nextToken?: string | null,
};

export type Permissions = {
  __typename: "Permissions",
  items?:  Array<Permission | null > | null,
  nextToken?: string | null,
};

export type Permission = {
  __typename: "Permission",
  clientId?: string | null,
  permission?: string | null,
};

export type GetExchangesInput = {
  clientId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Exchanges = {
  __typename: "Exchanges",
  items?:  Array<Exchange | null > | null,
  nextToken?: string | null,
};

export type GetExchangeInput = {
  clientId?: string | null,
  id: string,
};

export type GetPortfoliosInput = {
  clientId?: string | null,
  templateId?: string | null,
  exchangeId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Portfolios = {
  __typename: "Portfolios",
  items?:  Array<Portfolio | null > | null,
  nextToken?: string | null,
};

export type GetPortfolioInput = {
  clientId?: string | null,
  id: string,
};

export type GetTemplatesInput = {
  clientId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Templates = {
  __typename: "Templates",
  items?:  Array<Template | null > | null,
  nextToken?: string | null,
};

export type GetTemplateInput = {
  clientId?: string | null,
  id: string,
};

export type GetTradesInput = {
  clientId?: string | null,
  portfolioId?: string | null,
  exchangeId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Trades = {
  __typename: "Trades",
  items?:  Array<Trade | null > | null,
  nextToken?: string | null,
};

export type GetLogsInput = {
  clientId?: string | null,
  exchangeId?: string | null,
  portfolioId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Logs = {
  __typename: "Logs",
  items?:  Array<Log | null > | null,
  nextToken?: string | null,
};

export type GetOrdersInput = {
  clientId?: string | null,
  portfolioId?: string | null,
  exchangeId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Orders = {
  __typename: "Orders",
  items?:  Array<Order | null > | null,
  nextToken?: string | null,
};

export type GetPositionsInput = {
  clientId?: string | null,
  exchangeId: string,
  limit: number,
  nextToken?: string | null,
};

export type Positions = {
  __typename: "Positions",
  items?:  Array<Position | null > | null,
  nextToken?: string | null,
};

export type GetBalancesInput = {
  clientId?: string | null,
  exchangeId: string,
  limit: number,
  nextToken?: string | null,
};

export type Balances = {
  __typename: "Balances",
  items?:  Array<Balance | null > | null,
  nextToken?: string | null,
};

export type GetExchangeKindsInput = {
  limit: number,
  nextToken?: string | null,
};

export type ExchangeKinds = {
  __typename: "ExchangeKinds",
  items?:  Array<ExchangeKind | null > | null,
  nextToken?: string | null,
};

export type ExchangeKind = {
  __typename: "ExchangeKind",
  id: string,
  name?: string | null,
  description?: string | null,
  url?: string | null,
};

export type GetLevelsInput = {
  clientId?: string | null,
  limit: number,
  nextToken?: string | null,
};

export type Levels = {
  __typename: "Levels",
  items?:  Array<Level | null > | null,
  nextToken?: string | null,
};

export type GetConfigEntryInput = {
  id: string,
};

export type GetConfigEntriesInput = {
  limit: number,
  nextToken?: string | null,
};

export type ConfigEntries = {
  __typename: "ConfigEntries",
  items?:  Array<ConfigEntry | null > | null,
  nextToken?: string | null,
};

export type GetClientStatusInput = {
  clientId?: string | null,
};

export type ClientStatus = {
  __typename: "ClientStatus",
  clientId?: string | null,
  status?: string | null,
  mode?: string | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  status?: string | null,
  secretArn?: string | null,
  exchanges?:  Array<Exchange | null > | null,
  portfolios?:  Array<Portfolio | null > | null,
  logs?:  Array<Log | null > | null,
};

export type CreateExchangeMutationVariables = {
  input: CreateExchangeInput,
};

export type CreateExchangeMutation = {
  createExchange?:  {
    __typename: "Exchange",
    clientId?: string | null,
    id?: string | null,
    kind?: ExchangeEnum | null,
    name?: string | null,
    publicKey?: string | null,
    lastTransferMoment?: number | null,
    lastRequestEndTime?: number | null,
    totalTransferredUSDT?: number | null,
    totalInitialMargin?: number | null,
    totalMaintMargin?: number | null,
    totalWalletBalance?: number | null,
    totalUnrealizedProfit?: number | null,
    totalMarginBalance?: number | null,
    totalPositionInitialMargin?: number | null,
    totalOpenOrderInitialMargin?: number | null,
    totalCrossWalletBalance?: number | null,
    totalCrossUnPnl?: number | null,
    availableBalance?: number | null,
    maxWithdrawAmount?: number | null,
    totalNotional?: number | null,
  } | null,
};

export type UpdateExchangeMutationVariables = {
  input: UpdateExchangeInput,
};

export type UpdateExchangeMutation = {
  updateExchange?:  {
    __typename: "Exchange",
    clientId?: string | null,
    id?: string | null,
    kind?: ExchangeEnum | null,
    name?: string | null,
    publicKey?: string | null,
    lastTransferMoment?: number | null,
    lastRequestEndTime?: number | null,
    totalTransferredUSDT?: number | null,
    totalInitialMargin?: number | null,
    totalMaintMargin?: number | null,
    totalWalletBalance?: number | null,
    totalUnrealizedProfit?: number | null,
    totalMarginBalance?: number | null,
    totalPositionInitialMargin?: number | null,
    totalOpenOrderInitialMargin?: number | null,
    totalCrossWalletBalance?: number | null,
    totalCrossUnPnl?: number | null,
    availableBalance?: number | null,
    maxWithdrawAmount?: number | null,
    totalNotional?: number | null,
  } | null,
};

export type DeleteExchangeMutationVariables = {
  input: DeleteExchangeInput,
};

export type DeleteExchangeMutation = {
  deleteExchange?:  {
    __typename: "Exchange",
    clientId?: string | null,
    id?: string | null,
    kind?: ExchangeEnum | null,
    name?: string | null,
    publicKey?: string | null,
    lastTransferMoment?: number | null,
    lastRequestEndTime?: number | null,
    totalTransferredUSDT?: number | null,
    totalInitialMargin?: number | null,
    totalMaintMargin?: number | null,
    totalWalletBalance?: number | null,
    totalUnrealizedProfit?: number | null,
    totalMarginBalance?: number | null,
    totalPositionInitialMargin?: number | null,
    totalOpenOrderInitialMargin?: number | null,
    totalCrossWalletBalance?: number | null,
    totalCrossUnPnl?: number | null,
    availableBalance?: number | null,
    maxWithdrawAmount?: number | null,
    totalNotional?: number | null,
  } | null,
};

export type CreatePortfolioMutationVariables = {
  input: CreatePortfolioInput,
};

export type CreatePortfolioMutation = {
  createPortfolio?:  {
    __typename: "Portfolio",
    clientId?: string | null,
    id?: string | null,
    trdPair?: string | null,
    exchange?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
    template?:  {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null,
    name?: string | null,
    status?: string | null,
    buy?: boolean | null,
    sell?: boolean | null,
    onlyClose?: boolean | null,
    cooldown?: number | null,
    initialVolume?: number | null,
    tradeVolume?: number | null,
    absInitialVolume?: number | null,
    initialVolumeEUR?: number | null,
    fixedVolume?: number | null,
    fixedAmount?: number | null,
    initialPrice?: number | null,
    positionControl?: string | null,
    amount?: number | null,
    volume?: number | null,
    absVolume?: number | null,
    level?: number | null,
    takeProfit?: number | null,
    priceShift?: number | null,
    martingale?: number | null,
    limit?: number | null,
    lastTradeMoment?: number | null,
    orders?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    ordersChain?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    trades?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    bid?: number | null,
    ask?: number | null,
    levels?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    ordersType?: string | null,
    nextLevelPrice?: number | null,
    nextLevelVolume?: number | null,
    takeProfitPrice?: number | null,
  } | null,
};

export type UpdatePortfolioMutationVariables = {
  input: UpdatePortfolioInput,
};

export type UpdatePortfolioMutation = {
  updatePortfolio?:  {
    __typename: "Portfolio",
    clientId?: string | null,
    id?: string | null,
    trdPair?: string | null,
    exchange?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
    template?:  {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null,
    name?: string | null,
    status?: string | null,
    buy?: boolean | null,
    sell?: boolean | null,
    onlyClose?: boolean | null,
    cooldown?: number | null,
    initialVolume?: number | null,
    tradeVolume?: number | null,
    absInitialVolume?: number | null,
    initialVolumeEUR?: number | null,
    fixedVolume?: number | null,
    fixedAmount?: number | null,
    initialPrice?: number | null,
    positionControl?: string | null,
    amount?: number | null,
    volume?: number | null,
    absVolume?: number | null,
    level?: number | null,
    takeProfit?: number | null,
    priceShift?: number | null,
    martingale?: number | null,
    limit?: number | null,
    lastTradeMoment?: number | null,
    orders?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    ordersChain?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    trades?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    bid?: number | null,
    ask?: number | null,
    levels?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    ordersType?: string | null,
    nextLevelPrice?: number | null,
    nextLevelVolume?: number | null,
    takeProfitPrice?: number | null,
  } | null,
};

export type DeletePortfolioMutationVariables = {
  input: DeletePortfolioInput,
};

export type DeletePortfolioMutation = {
  deletePortfolio?:  {
    __typename: "Portfolio",
    clientId?: string | null,
    id?: string | null,
    trdPair?: string | null,
    exchange?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
    template?:  {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null,
    name?: string | null,
    status?: string | null,
    buy?: boolean | null,
    sell?: boolean | null,
    onlyClose?: boolean | null,
    cooldown?: number | null,
    initialVolume?: number | null,
    tradeVolume?: number | null,
    absInitialVolume?: number | null,
    initialVolumeEUR?: number | null,
    fixedVolume?: number | null,
    fixedAmount?: number | null,
    initialPrice?: number | null,
    positionControl?: string | null,
    amount?: number | null,
    volume?: number | null,
    absVolume?: number | null,
    level?: number | null,
    takeProfit?: number | null,
    priceShift?: number | null,
    martingale?: number | null,
    limit?: number | null,
    lastTradeMoment?: number | null,
    orders?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    ordersChain?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    trades?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    bid?: number | null,
    ask?: number | null,
    levels?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    ordersType?: string | null,
    nextLevelPrice?: number | null,
    nextLevelVolume?: number | null,
    takeProfitPrice?: number | null,
  } | null,
};

export type TradePortfolioMutationVariables = {
  input: PlacePortfolioOrderInput,
};

export type TradePortfolioMutation = {
  tradePortfolio?:  {
    __typename: "Portfolio",
    clientId?: string | null,
    id?: string | null,
    trdPair?: string | null,
    exchange?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
    template?:  {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null,
    name?: string | null,
    status?: string | null,
    buy?: boolean | null,
    sell?: boolean | null,
    onlyClose?: boolean | null,
    cooldown?: number | null,
    initialVolume?: number | null,
    tradeVolume?: number | null,
    absInitialVolume?: number | null,
    initialVolumeEUR?: number | null,
    fixedVolume?: number | null,
    fixedAmount?: number | null,
    initialPrice?: number | null,
    positionControl?: string | null,
    amount?: number | null,
    volume?: number | null,
    absVolume?: number | null,
    level?: number | null,
    takeProfit?: number | null,
    priceShift?: number | null,
    martingale?: number | null,
    limit?: number | null,
    lastTradeMoment?: number | null,
    orders?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    ordersChain?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    trades?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    bid?: number | null,
    ask?: number | null,
    levels?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    ordersType?: string | null,
    nextLevelPrice?: number | null,
    nextLevelVolume?: number | null,
    takeProfitPrice?: number | null,
  } | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    clientId?: string | null,
    id?: string | null,
    name?: string | null,
    levels?:  Array< {
      __typename: "TemplateLevel",
      id?: string | null,
      level?: number | null,
      priceShift?: number | null,
      takeProfit?: number | null,
      martingale?: number | null,
    } | null > | null,
    maxBuyVolume?: number | null,
    maxSellVolume?: number | null,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    clientId?: string | null,
    id?: string | null,
    name?: string | null,
    levels?:  Array< {
      __typename: "TemplateLevel",
      id?: string | null,
      level?: number | null,
      priceShift?: number | null,
      takeProfit?: number | null,
      martingale?: number | null,
    } | null > | null,
    maxBuyVolume?: number | null,
    maxSellVolume?: number | null,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "Template",
    clientId?: string | null,
    id?: string | null,
    name?: string | null,
    levels?:  Array< {
      __typename: "TemplateLevel",
      id?: string | null,
      level?: number | null,
      priceShift?: number | null,
      takeProfit?: number | null,
      martingale?: number | null,
    } | null > | null,
    maxBuyVolume?: number | null,
    maxSellVolume?: number | null,
  } | null,
};

export type CreateConfigEntryMutationVariables = {
  input: CreateConfigEntryInput,
};

export type CreateConfigEntryMutation = {
  createConfigEntry?:  {
    __typename: "ConfigEntry",
    id?: string | null,
    value?: string | null,
  } | null,
};

export type UpdateConfigEntryMutationVariables = {
  input: UpdateConfigEntryInput,
};

export type UpdateConfigEntryMutation = {
  updateConfigEntry?:  {
    __typename: "ConfigEntry",
    id?: string | null,
    value?: string | null,
  } | null,
};

export type DeleteConfigEntryMutationVariables = {
  input: DeleteConfigEntryInput,
};

export type DeleteConfigEntryMutation = {
  deleteConfigEntry?:  {
    __typename: "ConfigEntry",
    id?: string | null,
    value?: string | null,
  } | null,
};

export type UpdateTradingModeMutationVariables = {
  input: UpdateTradingModeInput,
};

export type UpdateTradingModeMutation = {
  updateTradingMode?:  {
    __typename: "TradingMode",
    mode?: string | null,
  } | null,
};

export type ModifyLogMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: LogInput,
};

export type ModifyLogMutation = {
  modifyLog?:  {
    __typename: "ModifiedLogPayload",
    operation: CRUD,
    clientId: string,
    payload:  {
      __typename: "Log",
      clientId?: string | null,
      exchangeId?: string | null,
      portfolioId?: string | null,
      timestamp?: number | null,
      src?: string | null,
      srcId?: string | null,
      info?: string | null,
    },
  } | null,
};

export type ModifyTradeMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: TradeInput,
};

export type ModifyTradeMutation = {
  modifyTrade?:  {
    __typename: "ModifiedTradePayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null,
  } | null,
};

export type ModifyPortfolioMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: PortfolioInput,
};

export type ModifyPortfolioMutation = {
  modifyPortfolio?:  {
    __typename: "ModifiedPortfolioPayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Portfolio",
      clientId?: string | null,
      id?: string | null,
      trdPair?: string | null,
      exchange?:  {
        __typename: "Exchange",
        clientId?: string | null,
        id?: string | null,
        kind?: ExchangeEnum | null,
        name?: string | null,
        publicKey?: string | null,
        lastTransferMoment?: number | null,
        lastRequestEndTime?: number | null,
        totalTransferredUSDT?: number | null,
        totalInitialMargin?: number | null,
        totalMaintMargin?: number | null,
        totalWalletBalance?: number | null,
        totalUnrealizedProfit?: number | null,
        totalMarginBalance?: number | null,
        totalPositionInitialMargin?: number | null,
        totalOpenOrderInitialMargin?: number | null,
        totalCrossWalletBalance?: number | null,
        totalCrossUnPnl?: number | null,
        availableBalance?: number | null,
        maxWithdrawAmount?: number | null,
        totalNotional?: number | null,
      } | null,
      template?:  {
        __typename: "Template",
        clientId?: string | null,
        id?: string | null,
        name?: string | null,
        levels?:  Array< {
          __typename: "TemplateLevel",
          id?: string | null,
          level?: number | null,
          priceShift?: number | null,
          takeProfit?: number | null,
          martingale?: number | null,
        } | null > | null,
        maxBuyVolume?: number | null,
        maxSellVolume?: number | null,
      } | null,
      name?: string | null,
      status?: string | null,
      buy?: boolean | null,
      sell?: boolean | null,
      onlyClose?: boolean | null,
      cooldown?: number | null,
      initialVolume?: number | null,
      tradeVolume?: number | null,
      absInitialVolume?: number | null,
      initialVolumeEUR?: number | null,
      fixedVolume?: number | null,
      fixedAmount?: number | null,
      initialPrice?: number | null,
      positionControl?: string | null,
      amount?: number | null,
      volume?: number | null,
      absVolume?: number | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
      limit?: number | null,
      lastTradeMoment?: number | null,
      orders?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      ordersChain?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      trades?:  Array< {
        __typename: "Trade",
        clientId?: string | null,
        orderId?: string | null,
        id?: string | null,
        price?: number | null,
        qty?: number | null,
        time?: number | null,
        dir?: string | null,
        trdPair?: string | null,
        level?: number | null,
      } | null > | null,
      bid?: number | null,
      ask?: number | null,
      levels?:  Array< {
        __typename: "Level",
        clientId?: string | null,
        strategyId?: string | null,
        id?: string | null,
        level?: number | null,
        takeProfit?: number | null,
        priceShift?: number | null,
        martingale?: number | null,
      } | null > | null,
      ordersType?: string | null,
      nextLevelPrice?: number | null,
      nextLevelVolume?: number | null,
      takeProfitPrice?: number | null,
    } | null,
  } | null,
};

export type ModifyExchangeMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: ExchangeInput,
};

export type ModifyExchangeMutation = {
  modifyExchange?:  {
    __typename: "ModifiedExchangePayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
  } | null,
};

export type ModifyOrderMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: OrderInput,
};

export type ModifyOrderMutation = {
  modifyOrder?:  {
    __typename: "ModifiedOrderPayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null,
  } | null,
};

export type ModifyPositionMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: PositionInput,
};

export type ModifyPositionMutation = {
  modifyPosition?:  {
    __typename: "ModifiedPositionPayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Position",
      clientId?: string | null,
      exchangeId?: string | null,
      symbol?: string | null,
      positionAmt?: number | null,
      entryPrice?: number | null,
      markPrice?: number | null,
      unRealizedProfit?: number | null,
      liquidationPrice?: number | null,
      leverage?: number | null,
      maxNotionalValue?: number | null,
      marginType?: string | null,
      isolatedMargin?: number | null,
      isAutoAddMargin?: boolean | null,
      positionSide?: string | null,
      notional?: number | null,
      isolatedWallet?: number | null,
      updateTime?: number | null,
    } | null,
  } | null,
};

export type ModifyBalanceMutationVariables = {
  clientId: string,
  operation: CRUD,
  input: BalanceInput,
};

export type ModifyBalanceMutation = {
  modifyBalance?:  {
    __typename: "ModifiedBalancePayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Balance",
      clientId?: string | null,
      exchangeId?: string | null,
      asset?: string | null,
      walletBalance?: number | null,
      unrealizedProfit?: number | null,
      marginBalance?: number | null,
      maintMargin?: number | null,
      initialMargin?: number | null,
      positionInitialMargin?: number | null,
      openOrderInitialMargin?: number | null,
      crossWalletBalance?: number | null,
      crossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      marginAvailable?: boolean | null,
      updateTime?: number | null,
    } | null,
  } | null,
};

export type GetPermissionsQueryVariables = {
  input: GetPermissionsInput,
};

export type GetPermissionsQuery = {
  getPermissions?:  {
    __typename: "Permissions",
    items?:  Array< {
      __typename: "Permission",
      clientId?: string | null,
      permission?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetExchangesQueryVariables = {
  input: GetExchangesInput,
};

export type GetExchangesQuery = {
  getExchanges?:  {
    __typename: "Exchanges",
    items?:  Array< {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetExchangeQueryVariables = {
  input: GetExchangeInput,
};

export type GetExchangeQuery = {
  getExchange?:  {
    __typename: "Exchange",
    clientId?: string | null,
    id?: string | null,
    kind?: ExchangeEnum | null,
    name?: string | null,
    publicKey?: string | null,
    lastTransferMoment?: number | null,
    lastRequestEndTime?: number | null,
    totalTransferredUSDT?: number | null,
    totalInitialMargin?: number | null,
    totalMaintMargin?: number | null,
    totalWalletBalance?: number | null,
    totalUnrealizedProfit?: number | null,
    totalMarginBalance?: number | null,
    totalPositionInitialMargin?: number | null,
    totalOpenOrderInitialMargin?: number | null,
    totalCrossWalletBalance?: number | null,
    totalCrossUnPnl?: number | null,
    availableBalance?: number | null,
    maxWithdrawAmount?: number | null,
    totalNotional?: number | null,
  } | null,
};

export type GetPortfoliosQueryVariables = {
  input: GetPortfoliosInput,
};

export type GetPortfoliosQuery = {
  getPortfolios?:  {
    __typename: "Portfolios",
    items?:  Array< {
      __typename: "Portfolio",
      clientId?: string | null,
      id?: string | null,
      trdPair?: string | null,
      exchange?:  {
        __typename: "Exchange",
        clientId?: string | null,
        id?: string | null,
        kind?: ExchangeEnum | null,
        name?: string | null,
        publicKey?: string | null,
        lastTransferMoment?: number | null,
        lastRequestEndTime?: number | null,
        totalTransferredUSDT?: number | null,
        totalInitialMargin?: number | null,
        totalMaintMargin?: number | null,
        totalWalletBalance?: number | null,
        totalUnrealizedProfit?: number | null,
        totalMarginBalance?: number | null,
        totalPositionInitialMargin?: number | null,
        totalOpenOrderInitialMargin?: number | null,
        totalCrossWalletBalance?: number | null,
        totalCrossUnPnl?: number | null,
        availableBalance?: number | null,
        maxWithdrawAmount?: number | null,
        totalNotional?: number | null,
      } | null,
      template?:  {
        __typename: "Template",
        clientId?: string | null,
        id?: string | null,
        name?: string | null,
        levels?:  Array< {
          __typename: "TemplateLevel",
          id?: string | null,
          level?: number | null,
          priceShift?: number | null,
          takeProfit?: number | null,
          martingale?: number | null,
        } | null > | null,
        maxBuyVolume?: number | null,
        maxSellVolume?: number | null,
      } | null,
      name?: string | null,
      status?: string | null,
      buy?: boolean | null,
      sell?: boolean | null,
      onlyClose?: boolean | null,
      cooldown?: number | null,
      initialVolume?: number | null,
      tradeVolume?: number | null,
      absInitialVolume?: number | null,
      initialVolumeEUR?: number | null,
      fixedVolume?: number | null,
      fixedAmount?: number | null,
      initialPrice?: number | null,
      positionControl?: string | null,
      amount?: number | null,
      volume?: number | null,
      absVolume?: number | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
      limit?: number | null,
      lastTradeMoment?: number | null,
      orders?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      ordersChain?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      trades?:  Array< {
        __typename: "Trade",
        clientId?: string | null,
        orderId?: string | null,
        id?: string | null,
        price?: number | null,
        qty?: number | null,
        time?: number | null,
        dir?: string | null,
        trdPair?: string | null,
        level?: number | null,
      } | null > | null,
      bid?: number | null,
      ask?: number | null,
      levels?:  Array< {
        __typename: "Level",
        clientId?: string | null,
        strategyId?: string | null,
        id?: string | null,
        level?: number | null,
        takeProfit?: number | null,
        priceShift?: number | null,
        martingale?: number | null,
      } | null > | null,
      ordersType?: string | null,
      nextLevelPrice?: number | null,
      nextLevelVolume?: number | null,
      takeProfitPrice?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPortfolioQueryVariables = {
  input: GetPortfolioInput,
};

export type GetPortfolioQuery = {
  getPortfolio?:  {
    __typename: "Portfolio",
    clientId?: string | null,
    id?: string | null,
    trdPair?: string | null,
    exchange?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
    template?:  {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null,
    name?: string | null,
    status?: string | null,
    buy?: boolean | null,
    sell?: boolean | null,
    onlyClose?: boolean | null,
    cooldown?: number | null,
    initialVolume?: number | null,
    tradeVolume?: number | null,
    absInitialVolume?: number | null,
    initialVolumeEUR?: number | null,
    fixedVolume?: number | null,
    fixedAmount?: number | null,
    initialPrice?: number | null,
    positionControl?: string | null,
    amount?: number | null,
    volume?: number | null,
    absVolume?: number | null,
    level?: number | null,
    takeProfit?: number | null,
    priceShift?: number | null,
    martingale?: number | null,
    limit?: number | null,
    lastTradeMoment?: number | null,
    orders?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    ordersChain?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    trades?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    bid?: number | null,
    ask?: number | null,
    levels?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    ordersType?: string | null,
    nextLevelPrice?: number | null,
    nextLevelVolume?: number | null,
    takeProfitPrice?: number | null,
  } | null,
};

export type GetTemplatesQueryVariables = {
  input: GetTemplatesInput,
};

export type GetTemplatesQuery = {
  getTemplates?:  {
    __typename: "Templates",
    items?:  Array< {
      __typename: "Template",
      clientId?: string | null,
      id?: string | null,
      name?: string | null,
      levels?:  Array< {
        __typename: "TemplateLevel",
        id?: string | null,
        level?: number | null,
        priceShift?: number | null,
        takeProfit?: number | null,
        martingale?: number | null,
      } | null > | null,
      maxBuyVolume?: number | null,
      maxSellVolume?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateQueryVariables = {
  input: GetTemplateInput,
};

export type GetTemplateQuery = {
  getTemplate?:  {
    __typename: "Template",
    clientId?: string | null,
    id?: string | null,
    name?: string | null,
    levels?:  Array< {
      __typename: "TemplateLevel",
      id?: string | null,
      level?: number | null,
      priceShift?: number | null,
      takeProfit?: number | null,
      martingale?: number | null,
    } | null > | null,
    maxBuyVolume?: number | null,
    maxSellVolume?: number | null,
  } | null,
};

export type GetTradesQueryVariables = {
  input: GetTradesInput,
};

export type GetTradesQuery = {
  getTrades?:  {
    __typename: "Trades",
    items?:  Array< {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetLogsQueryVariables = {
  input: GetLogsInput,
};

export type GetLogsQuery = {
  getLogs?:  {
    __typename: "Logs",
    items?:  Array< {
      __typename: "Log",
      clientId?: string | null,
      exchangeId?: string | null,
      portfolioId?: string | null,
      timestamp?: number | null,
      src?: string | null,
      srcId?: string | null,
      info?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetOrdersQueryVariables = {
  input: GetOrdersInput,
};

export type GetOrdersQuery = {
  getOrders?:  {
    __typename: "Orders",
    items?:  Array< {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetPositionsQueryVariables = {
  input: GetPositionsInput,
};

export type GetPositionsQuery = {
  getPositions?:  {
    __typename: "Positions",
    items?:  Array< {
      __typename: "Position",
      clientId?: string | null,
      exchangeId?: string | null,
      symbol?: string | null,
      positionAmt?: number | null,
      entryPrice?: number | null,
      markPrice?: number | null,
      unRealizedProfit?: number | null,
      liquidationPrice?: number | null,
      leverage?: number | null,
      maxNotionalValue?: number | null,
      marginType?: string | null,
      isolatedMargin?: number | null,
      isAutoAddMargin?: boolean | null,
      positionSide?: string | null,
      notional?: number | null,
      isolatedWallet?: number | null,
      updateTime?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetBalancesQueryVariables = {
  input: GetBalancesInput,
};

export type GetBalancesQuery = {
  getBalances?:  {
    __typename: "Balances",
    items?:  Array< {
      __typename: "Balance",
      clientId?: string | null,
      exchangeId?: string | null,
      asset?: string | null,
      walletBalance?: number | null,
      unrealizedProfit?: number | null,
      marginBalance?: number | null,
      maintMargin?: number | null,
      initialMargin?: number | null,
      positionInitialMargin?: number | null,
      openOrderInitialMargin?: number | null,
      crossWalletBalance?: number | null,
      crossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      marginAvailable?: boolean | null,
      updateTime?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetExchangeKindsQueryVariables = {
  input: GetExchangeKindsInput,
};

export type GetExchangeKindsQuery = {
  getExchangeKinds?:  {
    __typename: "ExchangeKinds",
    items?:  Array< {
      __typename: "ExchangeKind",
      id: string,
      name?: string | null,
      description?: string | null,
      url?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetStrategyLevelsQueryVariables = {
  input: GetLevelsInput,
};

export type GetStrategyLevelsQuery = {
  getStrategyLevels?:  {
    __typename: "Levels",
    items?:  Array< {
      __typename: "Level",
      clientId?: string | null,
      strategyId?: string | null,
      id?: string | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetConfigEntryQueryVariables = {
  input: GetConfigEntryInput,
};

export type GetConfigEntryQuery = {
  getConfigEntry?:  {
    __typename: "ConfigEntry",
    id?: string | null,
    value?: string | null,
  } | null,
};

export type GetConfigEntriesQueryVariables = {
  input: GetConfigEntriesInput,
};

export type GetConfigEntriesQuery = {
  getConfigEntries?:  {
    __typename: "ConfigEntries",
    items?:  Array< {
      __typename: "ConfigEntry",
      id?: string | null,
      value?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetClientStatusQueryVariables = {
  input: GetClientStatusInput,
};

export type GetClientStatusQuery = {
  getClientStatus?:  {
    __typename: "ClientStatus",
    clientId?: string | null,
    status?: string | null,
    mode?: string | null,
  } | null,
};

export type GetClientDataQueryVariables = {
  input?: string | null,
};

export type GetClientDataQuery = {
  getClientData?:  {
    __typename: "Client",
    id: string,
    status?: string | null,
    secretArn?: string | null,
    exchanges?:  Array< {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null > | null,
    portfolios?:  Array< {
      __typename: "Portfolio",
      clientId?: string | null,
      id?: string | null,
      trdPair?: string | null,
      exchange?:  {
        __typename: "Exchange",
        clientId?: string | null,
        id?: string | null,
        kind?: ExchangeEnum | null,
        name?: string | null,
        publicKey?: string | null,
        lastTransferMoment?: number | null,
        lastRequestEndTime?: number | null,
        totalTransferredUSDT?: number | null,
        totalInitialMargin?: number | null,
        totalMaintMargin?: number | null,
        totalWalletBalance?: number | null,
        totalUnrealizedProfit?: number | null,
        totalMarginBalance?: number | null,
        totalPositionInitialMargin?: number | null,
        totalOpenOrderInitialMargin?: number | null,
        totalCrossWalletBalance?: number | null,
        totalCrossUnPnl?: number | null,
        availableBalance?: number | null,
        maxWithdrawAmount?: number | null,
        totalNotional?: number | null,
      } | null,
      template?:  {
        __typename: "Template",
        clientId?: string | null,
        id?: string | null,
        name?: string | null,
        levels?:  Array< {
          __typename: "TemplateLevel",
          id?: string | null,
          level?: number | null,
          priceShift?: number | null,
          takeProfit?: number | null,
          martingale?: number | null,
        } | null > | null,
        maxBuyVolume?: number | null,
        maxSellVolume?: number | null,
      } | null,
      name?: string | null,
      status?: string | null,
      buy?: boolean | null,
      sell?: boolean | null,
      onlyClose?: boolean | null,
      cooldown?: number | null,
      initialVolume?: number | null,
      tradeVolume?: number | null,
      absInitialVolume?: number | null,
      initialVolumeEUR?: number | null,
      fixedVolume?: number | null,
      fixedAmount?: number | null,
      initialPrice?: number | null,
      positionControl?: string | null,
      amount?: number | null,
      volume?: number | null,
      absVolume?: number | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
      limit?: number | null,
      lastTradeMoment?: number | null,
      orders?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      ordersChain?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      trades?:  Array< {
        __typename: "Trade",
        clientId?: string | null,
        orderId?: string | null,
        id?: string | null,
        price?: number | null,
        qty?: number | null,
        time?: number | null,
        dir?: string | null,
        trdPair?: string | null,
        level?: number | null,
      } | null > | null,
      bid?: number | null,
      ask?: number | null,
      levels?:  Array< {
        __typename: "Level",
        clientId?: string | null,
        strategyId?: string | null,
        id?: string | null,
        level?: number | null,
        takeProfit?: number | null,
        priceShift?: number | null,
        martingale?: number | null,
      } | null > | null,
      ordersType?: string | null,
      nextLevelPrice?: number | null,
      nextLevelVolume?: number | null,
      takeProfitPrice?: number | null,
    } | null > | null,
    logs?:  Array< {
      __typename: "Log",
      clientId?: string | null,
      exchangeId?: string | null,
      portfolioId?: string | null,
      timestamp?: number | null,
      src?: string | null,
      srcId?: string | null,
      info?: string | null,
    } | null > | null,
  } | null,
};

export type OnModifyExchangeSubscriptionVariables = {
  clientId: string,
};

export type OnModifyExchangeSubscription = {
  onModifyExchange?:  {
    __typename: "ModifiedExchangePayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Exchange",
      clientId?: string | null,
      id?: string | null,
      kind?: ExchangeEnum | null,
      name?: string | null,
      publicKey?: string | null,
      lastTransferMoment?: number | null,
      lastRequestEndTime?: number | null,
      totalTransferredUSDT?: number | null,
      totalInitialMargin?: number | null,
      totalMaintMargin?: number | null,
      totalWalletBalance?: number | null,
      totalUnrealizedProfit?: number | null,
      totalMarginBalance?: number | null,
      totalPositionInitialMargin?: number | null,
      totalOpenOrderInitialMargin?: number | null,
      totalCrossWalletBalance?: number | null,
      totalCrossUnPnl?: number | null,
      availableBalance?: number | null,
      maxWithdrawAmount?: number | null,
      totalNotional?: number | null,
    } | null,
  } | null,
};

export type OnModifyPortfolioSubscriptionVariables = {
  clientId: string,
};

export type OnModifyPortfolioSubscription = {
  onModifyPortfolio?:  {
    __typename: "ModifiedPortfolioPayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Portfolio",
      clientId?: string | null,
      id?: string | null,
      trdPair?: string | null,
      exchange?:  {
        __typename: "Exchange",
        clientId?: string | null,
        id?: string | null,
        kind?: ExchangeEnum | null,
        name?: string | null,
        publicKey?: string | null,
        lastTransferMoment?: number | null,
        lastRequestEndTime?: number | null,
        totalTransferredUSDT?: number | null,
        totalInitialMargin?: number | null,
        totalMaintMargin?: number | null,
        totalWalletBalance?: number | null,
        totalUnrealizedProfit?: number | null,
        totalMarginBalance?: number | null,
        totalPositionInitialMargin?: number | null,
        totalOpenOrderInitialMargin?: number | null,
        totalCrossWalletBalance?: number | null,
        totalCrossUnPnl?: number | null,
        availableBalance?: number | null,
        maxWithdrawAmount?: number | null,
        totalNotional?: number | null,
      } | null,
      template?:  {
        __typename: "Template",
        clientId?: string | null,
        id?: string | null,
        name?: string | null,
        levels?:  Array< {
          __typename: "TemplateLevel",
          id?: string | null,
          level?: number | null,
          priceShift?: number | null,
          takeProfit?: number | null,
          martingale?: number | null,
        } | null > | null,
        maxBuyVolume?: number | null,
        maxSellVolume?: number | null,
      } | null,
      name?: string | null,
      status?: string | null,
      buy?: boolean | null,
      sell?: boolean | null,
      onlyClose?: boolean | null,
      cooldown?: number | null,
      initialVolume?: number | null,
      tradeVolume?: number | null,
      absInitialVolume?: number | null,
      initialVolumeEUR?: number | null,
      fixedVolume?: number | null,
      fixedAmount?: number | null,
      initialPrice?: number | null,
      positionControl?: string | null,
      amount?: number | null,
      volume?: number | null,
      absVolume?: number | null,
      level?: number | null,
      takeProfit?: number | null,
      priceShift?: number | null,
      martingale?: number | null,
      limit?: number | null,
      lastTradeMoment?: number | null,
      orders?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      ordersChain?:  Array< {
        __typename: "Order",
        clientId?: string | null,
        portfolioId?: string | null,
        id?: string | null,
        level?: number | null,
        orderId?: string | null,
        time?: string | null,
        status?: string | null,
        dir?: string | null,
        price?: number | null,
        qty?: number | null,
        volume?: number | null,
        role?: string | null,
        kind?: string | null,
        type?: string | null,
        fulfilled?: number | null,
        qtyChange?: number | null,
        linked?: string | null,
        trdPair?: string | null,
        trdAmount?: number | null,
      } | null > | null,
      trades?:  Array< {
        __typename: "Trade",
        clientId?: string | null,
        orderId?: string | null,
        id?: string | null,
        price?: number | null,
        qty?: number | null,
        time?: number | null,
        dir?: string | null,
        trdPair?: string | null,
        level?: number | null,
      } | null > | null,
      bid?: number | null,
      ask?: number | null,
      levels?:  Array< {
        __typename: "Level",
        clientId?: string | null,
        strategyId?: string | null,
        id?: string | null,
        level?: number | null,
        takeProfit?: number | null,
        priceShift?: number | null,
        martingale?: number | null,
      } | null > | null,
      ordersType?: string | null,
      nextLevelPrice?: number | null,
      nextLevelVolume?: number | null,
      takeProfitPrice?: number | null,
    } | null,
  } | null,
};

export type OnModifyOrderSubscriptionVariables = {
  clientId: string,
};

export type OnModifyOrderSubscription = {
  onModifyOrder?:  {
    __typename: "ModifiedOrderPayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Order",
      clientId?: string | null,
      portfolioId?: string | null,
      id?: string | null,
      level?: number | null,
      orderId?: string | null,
      time?: string | null,
      status?: string | null,
      dir?: string | null,
      price?: number | null,
      qty?: number | null,
      volume?: number | null,
      role?: string | null,
      kind?: string | null,
      type?: string | null,
      fulfilled?: number | null,
      qtyChange?: number | null,
      linked?: string | null,
      trdPair?: string | null,
      trdAmount?: number | null,
    } | null,
  } | null,
};

export type OnModifyTradeSubscriptionVariables = {
  clientId: string,
};

export type OnModifyTradeSubscription = {
  onModifyTrade?:  {
    __typename: "ModifiedTradePayload",
    operation?: CRUD | null,
    clientId: string,
    payload?:  {
      __typename: "Trade",
      clientId?: string | null,
      orderId?: string | null,
      id?: string | null,
      price?: number | null,
      qty?: number | null,
      time?: number | null,
      dir?: string | null,
      trdPair?: string | null,
      level?: number | null,
    } | null,
  } | null,
};

export type OnModifyLogSubscriptionVariables = {
  clientId: string,
};

export type OnModifyLogSubscription = {
  onModifyLog?:  {
    __typename: "ModifiedLogPayload",
    operation: CRUD,
    clientId: string,
    payload:  {
      __typename: "Log",
      clientId?: string | null,
      exchangeId?: string | null,
      portfolioId?: string | null,
      timestamp?: number | null,
      src?: string | null,
      srcId?: string | null,
      info?: string | null,
    },
  } | null,
};
