import { deleteExchange, updateExchange } from "../../graphql/mutations";
import { ExchangeEnum, GetExchangeQuery, UpdateExchangeInput } from "../../shared/API";
import { getExchange } from "../../graphql/queries";
import { getInput } from "../../shared/utils";
import { Grid } from "@mui/material";
import { NFormContainer } from "../../components/nFormContainer/NFormContainer";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { useGetData } from "../../shared/useGetData";
import { useNavigate, useParams } from "react-router-dom";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";

export function ExchangeEdit() {
  const navigate = useNavigate();
  const { customClientId } = useUserPreferences();
  const { id } = useParams();

  const dataState = useGetData<GetExchangeQuery>({
    customClientId,
    graphqlQuery: getExchange,
    variables: {
      input: { id },
    },
  });

  const post = usePostData({
    customClientId,
    onSuccess: () => {
      navigate("/exchange");
      toast.success("Exchange successfully mutated!");
    },
    onError: () => {
      toast.error("Failed to mutate exchange!");
    },
  });

  const updateExchangeAction = useCallback(
    (formData: UpdateExchangeInput) => {
      post.postData(
        updateExchange,
        getInput<UpdateExchangeInput>({
          id: formData.id,
          name: formData.name,
          publicKey: formData.publicKey,
          secretKey: formData.secretKey,
        })
      );
    },
    [post]
  );

  const deleteExchangeAction = useCallback(() => {
    post.postData(deleteExchange, { input: { id } });
  }, [post, id]);

  return (
    <NFormContainer
      onFormReady={updateExchangeAction}
      title="Edit exchange"
      defaultValues={dataState.data?.getExchange || undefined}
      deleteProps={{
        redirectToPath: "/exchange",
        onClick: deleteExchangeAction,
      }}
      isLoading={dataState.isLoading}
      isFrozen={post.state.isLoading}
      error={dataState.error || post.state.error}
    >
      <Grid item xs={12} md={6}>
        <TextFieldElement name="name" label="Name" required variant="filled" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="kind"
          label="Type"
          required
          variant="filled"
          fullWidth
          options={Object.entries(ExchangeEnum).map((e) => {
            const [key, value] = e;
            return { id: key, title: value };
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldElement name="publicKey" label="Public key" required variant="filled" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextFieldElement name="secretKey" label="Secret key" required variant="filled" fullWidth />
      </Grid>
    </NFormContainer>
  );
}
