import GoToNextLimit from "../../components/GoToNextToken";
import { getColumns, getInput, prepareTableRows } from "../../shared/utils";
import { getLogs } from "../../graphql/queries";
import { GetLogsInput, GetLogsQuery } from "../../shared/API";
import { LogColumns } from "./log.entity";
import { NTable } from "../../components/nTable/NTable";
import { SharedData } from "../../shared/sharedData/sharedData";
import { useGetData } from "../../shared/useGetData";
import { useState } from "react";
import { useUserPreferences } from "../_appLayout/AppContent";

const fetchLimit = 1000;
const TABLE_KEY = "logs";

export function Log() {
  const { customClientId } = useUserPreferences();
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY));

  const dataState = useGetData<GetLogsQuery>({
    customClientId,
    graphqlQuery: getLogs,
    variables: getInput<GetLogsInput>({ limit: fetchLimit }),
  });

  return (
    <>
      <NTable
        stickyHeadersMaxHeight="calc(100vh - 220px)"
        supportsColumnOrder={{ tableKey: TABLE_KEY, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY)) }}
        title="Logs"
        rememberStateKey={TABLE_KEY}
        isLoading={dataState.isLoading}
        columns={getColumns(LogColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="timestamp"
        rows={prepareTableRows(dataState.data?.getLogs?.items ?? [])}
      />
      <GoToNextLimit limit={fetchLimit} goToAction={dataState.fetchData} nextToken={dataState.data?.getLogs?.nextToken} />
    </>
  );
}
