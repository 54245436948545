import { Content } from "./content/Content";
import { createContext, useContext, useState } from "react";
import { ExchangeColumns } from "../exchanges/Exchange.entity";
import { IHeadCell } from "../../components/nTable/NTable.types";
import { LogColumns } from "../log/log.entity";
import { LogsAsChildrenColumns } from "../dash/children/logs";
import { OrdersAsChildrenColumns } from "../dash/children/orders";
import { OrdersChainAsChildrenColumns } from "../dash/children/ordersChain";
import { PortfolioColumns } from "../portfolio/portfolio.entity";
import { PortfoliosAsChildrenColumns } from "../dash/children/portfolios";
import { PositionsAsChildrenColumns } from "../dash/children/positions";
import { SideMenu } from "./sideMenu/SideMenu";
import { templateListColumns } from "../templates/Template.list";
import { Theme } from "@mui/material";
import { Topbar } from "./topbar/Topbar";
import { TradesAsChildrenColumns } from "../dash/children/trades";

const drawerWidth = 300;

const cleanColumns = (cols: IHeadCell<any>[]) => {
  return cols.map((c) => ({
    id: c.id,
    label: c.label,
  }));
};

type IKnownColumn = { key: string; label: string; columns: { id: string | number | symbol; label: string }[] };

export const KnownColumns: IKnownColumn[] = [
  { key: "exchanges", label: "Exchanges", columns: cleanColumns(ExchangeColumns) },
  { key: "exchanges_logs", label: "Exchanges / Logs", columns: cleanColumns(LogsAsChildrenColumns) },
  { key: "exchanges_trades", label: "Exchanges / Trades", columns: cleanColumns(TradesAsChildrenColumns) },
  { key: "exchanges_orders", label: "Exchanges / Orders", columns: cleanColumns(OrdersAsChildrenColumns) },
  { key: "exchanges_positions", label: "Exchanges / Positions", columns: cleanColumns(PositionsAsChildrenColumns) },
  { key: "exchanges_portfolios", label: "Exchanges / Portfolios", columns: cleanColumns(PortfoliosAsChildrenColumns) },

  { key: "templates_portfolios", label: "Templates / Portfolios", columns: cleanColumns(PortfoliosAsChildrenColumns) },

  { key: "portfolios", label: "Portfolios", columns: cleanColumns(PortfolioColumns) },
  { key: "portfolios_logs", label: "Portfolios / Logs", columns: cleanColumns(LogsAsChildrenColumns) },
  { key: "portfolios_trades", label: "Portfolios / Trades", columns: cleanColumns(TradesAsChildrenColumns) },
  { key: "portfolios_ordersChain", label: "Portfolios / Orders", columns: cleanColumns(OrdersChainAsChildrenColumns) },
  {
    key: "templates",
    label: "Templates",
    columns: cleanColumns(templateListColumns),
  },
  {
    key: "logs",
    label: "Logs",
    columns: cleanColumns(LogColumns),
  },
].sort((a, b) => a.label.localeCompare(b.label));

export function AppContent(props: { isDarkTheme: boolean; darkTheme: Theme; lightTheme: Theme; toggleTheme: () => void }) {
  const [isMobile, setIsMobile] = useState(false);
  const [customClientId, setCustomClientId] = useState<string | undefined>(undefined);

  const handleDrawerToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <UserContext.Provider
      value={{
        customClientId,
        setCustomClientId,
        theme: props.isDarkTheme ? props.darkTheme : props.lightTheme,
        toggleTheme: props.toggleTheme,
      }}
    >
      <Topbar drawerWidth={drawerWidth} onMenuToggle={handleDrawerToggle} />
      <SideMenu drawerWidth={drawerWidth} isMobile={isMobile} onMenuToggle={handleDrawerToggle} />
      <Content drawerWidth={drawerWidth} />
    </UserContext.Provider>
  );
}

export const UserContext = createContext<
  | {
      customClientId: string | undefined;
      setCustomClientId: (value: string | undefined) => void;
      theme: Theme;
      toggleTheme: () => void;
    }
  | undefined
>(undefined);

export const useUserPreferences = () => {
  const preferences = useContext(UserContext);
  return { ...preferences };
};
