/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPermissions = /* GraphQL */ `
  query GetPermissions($input: GetPermissionsInput!) {
    getPermissions(input: $input) {
      items {
        clientId
        permission
      }
      nextToken
    }
  }
`;
export const getExchanges = /* GraphQL */ `
  query GetExchanges($input: GetExchangesInput!) {
    getExchanges(input: $input) {
      items {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      nextToken
    }
  }
`;
export const getExchange = /* GraphQL */ `
  query GetExchange($input: GetExchangeInput!) {
    getExchange(input: $input) {
      clientId
      id
      kind
      name
      publicKey
      lastTransferMoment
      lastRequestEndTime
      totalTransferredUSDT
      totalInitialMargin
      totalMaintMargin
      totalWalletBalance
      totalUnrealizedProfit
      totalMarginBalance
      totalPositionInitialMargin
      totalOpenOrderInitialMargin
      totalCrossWalletBalance
      totalCrossUnPnl
      availableBalance
      maxWithdrawAmount
      totalNotional
    }
  }
`;
export const getPortfolios = /* GraphQL */ `
  query GetPortfolios($input: GetPortfoliosInput!) {
    getPortfolios(input: $input) {
      items {
        clientId
        id
        trdPair
        exchange {
          clientId
          id
          kind
          name
          publicKey
          lastTransferMoment
          lastRequestEndTime
          totalTransferredUSDT
          totalInitialMargin
          totalMaintMargin
          totalWalletBalance
          totalUnrealizedProfit
          totalMarginBalance
          totalPositionInitialMargin
          totalOpenOrderInitialMargin
          totalCrossWalletBalance
          totalCrossUnPnl
          availableBalance
          maxWithdrawAmount
          totalNotional
        }
        template {
          clientId
          id
          name
          levels {
            id
            level
            priceShift
            takeProfit
            martingale
          }
          maxBuyVolume
          maxSellVolume
        }
        name
        status
        buy
        sell
        onlyClose
        cooldown
        initialVolume
        tradeVolume
        absInitialVolume
        initialVolumeEUR
        fixedVolume
        fixedAmount
        initialPrice
        positionControl
        amount
        volume
        absVolume
        level
        takeProfit
        priceShift
        martingale
        limit
        lastTradeMoment
        orders {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        ordersChain {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        trades {
          clientId
          orderId
          id
          price
          qty
          time
          dir
          trdPair
          level
        }
        bid
        ask
        levels {
          clientId
          strategyId
          id
          level
          takeProfit
          priceShift
          martingale
        }
        ordersType
        nextLevelPrice
        nextLevelVolume
        takeProfitPrice
      }
      nextToken
    }
  }
`;
export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($input: GetPortfolioInput!) {
    getPortfolio(input: $input) {
      clientId
      id
      trdPair
      exchange {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      template {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      name
      status
      buy
      sell
      onlyClose
      cooldown
      initialVolume
      tradeVolume
      absInitialVolume
      initialVolumeEUR
      fixedVolume
      fixedAmount
      initialPrice
      positionControl
      amount
      volume
      absVolume
      level
      takeProfit
      priceShift
      martingale
      limit
      lastTradeMoment
      orders {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      ordersChain {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      trades {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      bid
      ask
      levels {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      ordersType
      nextLevelPrice
      nextLevelVolume
      takeProfitPrice
    }
  }
`;
export const getTemplates = /* GraphQL */ `
  query GetTemplates($input: GetTemplatesInput!) {
    getTemplates(input: $input) {
      items {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      nextToken
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($input: GetTemplateInput!) {
    getTemplate(input: $input) {
      clientId
      id
      name
      levels {
        id
        level
        priceShift
        takeProfit
        martingale
      }
      maxBuyVolume
      maxSellVolume
    }
  }
`;
export const getTrades = /* GraphQL */ `
  query GetTrades($input: GetTradesInput!) {
    getTrades(input: $input) {
      items {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      nextToken
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($input: GetLogsInput!) {
    getLogs(input: $input) {
      items {
        clientId
        exchangeId
        portfolioId
        timestamp
        src
        srcId
        info
      }
      nextToken
    }
  }
`;
export const getOrders = /* GraphQL */ `
  query GetOrders($input: GetOrdersInput!) {
    getOrders(input: $input) {
      items {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      nextToken
    }
  }
`;
export const getPositions = /* GraphQL */ `
  query GetPositions($input: GetPositionsInput!) {
    getPositions(input: $input) {
      items {
        clientId
        exchangeId
        symbol
        positionAmt
        entryPrice
        markPrice
        unRealizedProfit
        liquidationPrice
        leverage
        maxNotionalValue
        marginType
        isolatedMargin
        isAutoAddMargin
        positionSide
        notional
        isolatedWallet
        updateTime
      }
      nextToken
    }
  }
`;
export const getBalances = /* GraphQL */ `
  query GetBalances($input: GetBalancesInput!) {
    getBalances(input: $input) {
      items {
        clientId
        exchangeId
        asset
        walletBalance
        unrealizedProfit
        marginBalance
        maintMargin
        initialMargin
        positionInitialMargin
        openOrderInitialMargin
        crossWalletBalance
        crossUnPnl
        availableBalance
        maxWithdrawAmount
        marginAvailable
        updateTime
      }
      nextToken
    }
  }
`;
export const getExchangeKinds = /* GraphQL */ `
  query GetExchangeKinds($input: GetExchangeKindsInput!) {
    getExchangeKinds(input: $input) {
      items {
        id
        name
        description
        url
      }
      nextToken
    }
  }
`;
export const getStrategyLevels = /* GraphQL */ `
  query GetStrategyLevels($input: GetLevelsInput!) {
    getStrategyLevels(input: $input) {
      items {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      nextToken
    }
  }
`;
export const getConfigEntry = /* GraphQL */ `
  query GetConfigEntry($input: GetConfigEntryInput!) {
    getConfigEntry(input: $input) {
      id
      value
    }
  }
`;
export const getConfigEntries = /* GraphQL */ `
  query GetConfigEntries($input: GetConfigEntriesInput!) {
    getConfigEntries(input: $input) {
      items {
        id
        value
      }
      nextToken
    }
  }
`;
export const getClientStatus = /* GraphQL */ `
  query GetClientStatus($input: GetClientStatusInput!) {
    getClientStatus(input: $input) {
      clientId
      status
      mode
    }
  }
`;
export const getClientData = /* GraphQL */ `
  query GetClientData($input: ID) {
    getClientData(input: $input) {
      id
      status
      secretArn
      exchanges {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      portfolios {
        clientId
        id
        trdPair
        exchange {
          clientId
          id
          kind
          name
          publicKey
          lastTransferMoment
          lastRequestEndTime
          totalTransferredUSDT
          totalInitialMargin
          totalMaintMargin
          totalWalletBalance
          totalUnrealizedProfit
          totalMarginBalance
          totalPositionInitialMargin
          totalOpenOrderInitialMargin
          totalCrossWalletBalance
          totalCrossUnPnl
          availableBalance
          maxWithdrawAmount
          totalNotional
        }
        template {
          clientId
          id
          name
          levels {
            id
            level
            priceShift
            takeProfit
            martingale
          }
          maxBuyVolume
          maxSellVolume
        }
        name
        status
        buy
        sell
        onlyClose
        cooldown
        initialVolume
        tradeVolume
        absInitialVolume
        initialVolumeEUR
        fixedVolume
        fixedAmount
        initialPrice
        positionControl
        amount
        volume
        absVolume
        level
        takeProfit
        priceShift
        martingale
        limit
        lastTradeMoment
        orders {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        ordersChain {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        trades {
          clientId
          orderId
          id
          price
          qty
          time
          dir
          trdPair
          level
        }
        bid
        ask
        levels {
          clientId
          strategyId
          id
          level
          takeProfit
          priceShift
          martingale
        }
        ordersType
        nextLevelPrice
        nextLevelVolume
        takeProfitPrice
      }
      logs {
        clientId
        exchangeId
        portfolioId
        timestamp
        src
        srcId
        info
      }
    }
  }
`;
