import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { getInput } from "../../../../../../shared/utils";
import { toast } from "react-toastify";
import { updateTradingMode } from "../../../../../../graphql/mutations";
import { UpdateTradingModeInput } from "../../../../../../shared/API";
import { usePostData } from "../../../../../../shared/usePostData";

export type IToggleModeStatusDialogRef = {
  openDialog: (newStatusToBe: string) => void;
};

export type IToggleModeStatusDialogProps = {
  onSuccessfulSave: () => void;
  customClientId: string | undefined;
};

export const ToggleModeStatusDialog = forwardRef<IToggleModeStatusDialogRef, IToggleModeStatusDialogProps>(function _ToggleModeStatusDialog(
  props,
  ref
) {
  useImperativeHandle(ref, () => ({
    openDialog,
  }));

  const post = usePostData({
    customClientId: props.customClientId,
    onSuccess: () => {
      closeDialog();
      toast.success("Auto mode status successfully mutated!");
      props.onSuccessfulSave();
    },
    onError: () => {
      toast.error("Failed to mutate auto mode status!");
    },
  });

  const [state, setState] = useState({ isOpen: false, newStatusToBe: "" });

  const openDialog = (newStatusToBe: string) => {
    setState({ newStatusToBe, isOpen: true });
  };

  const closeDialog = () => {
    setState({ newStatusToBe: "", isOpen: false });
  };

  const saveNewStatus = () => {
    post.postData(updateTradingMode, getInput<UpdateTradingModeInput>({ mode: state.newStatusToBe }));
  };

  return (
    <Dialog open={state.isOpen} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Confirm new auto mode status</DialogTitle>
      <DialogContent>
        {post.state.isLoading && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to set the Auto Mode status to{" "}
          <Typography variant="button" color="orangered">
            {state.newStatusToBe}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={post.state.isLoading} onClick={closeDialog}>
          Disagree
        </Button>
        <Button disabled={post.state.isLoading} onClick={saveNewStatus} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
});
