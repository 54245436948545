import { AutoFixHigh, AutoFixOff, HourglassTop, Warning } from "@mui/icons-material";
import { getClientStatus } from "../../../../../graphql/queries";
import { GetClientStatusInput, GetClientStatusQuery } from "../../../../../shared/API";
import { getInput } from "../../../../../shared/utils";
import { IToggleModeStatusDialogRef, ToggleModeStatusDialog } from "./toggleModeStatusDialog/ToggleModeStatusDialog";
import { ReactNode, useRef } from "react";
import { useGetData } from "../../../../../shared/useGetData";
import { useUserPreferences } from "../../../AppContent";
import { Box, Button, CircularProgress } from "@mui/material";

function getStatusProps(
  status: string | null | undefined,
  isLoading: boolean | null | undefined
): { isToggleDisabled: boolean; label: string; icon: ReactNode; newStatus: string | undefined } {
  switch (status) {
    case "auto":
      return {
        label: "Deactivate AUTO mode",
        icon: <AutoFixOff />,
        isToggleDisabled: false,
        newStatus: "manual",
      };

    case "manual":
      return {
        label: "Activate AUTO mode",
        icon: <AutoFixHigh />,
        isToggleDisabled: false,
        newStatus: "auto",
      };

    default:
      return {
        label: isLoading ? "" : "Unknown status",
        icon: isLoading ? <HourglassTop /> : <Warning />,
        isToggleDisabled: true,
        newStatus: undefined,
      };
  }
}

export function ClientStatus() {
  const dialogRef = useRef<IToggleModeStatusDialogRef>(null);
  const { customClientId } = useUserPreferences();

  const dataState = useGetData<GetClientStatusQuery>({
    customClientId,
    graphqlQuery: getClientStatus,
    variables: getInput<GetClientStatusInput>({}),
  });

  const currentStatus = getStatusProps(dataState.data?.getClientStatus?.mode, dataState.isLoading);

  const onButtonClick = () => {
    dialogRef.current?.openDialog(currentStatus.newStatus || "Unknown");
  };

  return (
    <>
      <Box sx={{ position: "relative", width: "100%" }}>
        <Button
          variant="outlined"
          //   sx={buttonSx}
          disabled={dataState.isLoading || currentStatus.isToggleDisabled}
          onClick={onButtonClick}
          sx={{ width: "100%" }}
          startIcon={currentStatus.icon}
        >
          {!dataState.isLoading && currentStatus.label}
        </Button>
        {dataState.isLoading && (
          <CircularProgress
            size={24}
            sx={{
              // color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
      <ToggleModeStatusDialog ref={dialogRef} onSuccessfulSave={dataState.fetchData} customClientId={customClientId} />
    </>
  );
}
