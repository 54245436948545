import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useState } from "react";

export function usePostData<Type>(props: { customClientId: string | undefined; onSuccess?: () => void; onError?: () => void }) {
  const [state, setState] = useState<{ isLoading: boolean; data: Type | undefined; error: string | undefined }>({
    isLoading: false,
    data: undefined,
    error: undefined,
  });

  const postData = useCallback(
    (graphqlQuery: string, variables?: any) => {
      setState({
        isLoading: true,
        data: undefined,
        error: undefined,
      });
      const fetch = async () => {
        try {
          if (props.customClientId) {
            if (variables?.input) {
              variables.input.clientId = props.customClientId;
            }
          }

          const datas: GraphQLResult<any> = await API.graphql(graphqlOperation(graphqlQuery, variables));
          setState({ isLoading: false, data: datas.data, error: undefined });
          props.onSuccess?.();
        } catch (result: any) {
          setState({
            isLoading: false,
            data: undefined,
            error: "Mutation failed! Reason(s): \n" + result.errors?.map((e: any) => e.message).join("\n"),
          });
          props.onError?.();
        }
      };
      fetch();
    },
    [props.onError, props.onSuccess, props.customClientId]
  );

  return { postData, state };
}

export interface GraphQLResult<T = object> {
  data?: T;
  errors?: { message?: string }[];
  extensions?: {
    [key: string]: any;
  };
}
