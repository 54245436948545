import GoToNextLimit from "../../../components/GoToNextToken";
import { ArrowCircleDown, ArrowCircleUp, DirectionsRun, HourglassTop, NearbyError, WarningAmber } from "@mui/icons-material";
import { Box } from "@mui/material";
import { getColumns, getInput, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../../shared/utils";
import { getPortfolios } from "../../../graphql/queries";
import { GetPortfoliosInput, GetPortfoliosQuery, Portfolio } from "../../../shared/API";
import { grey, lightGreen, orange } from "@mui/material/colors";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useGetData } from "../../../shared/useGetData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../_appLayout/AppContent";

const fetchLimit = 1000;

type IProps = { exchangeId?: string; templateId?: string };

const getConfigKey = (props: IProps) => {
  if (props.exchangeId) {
    return "exchanges_portfolios";
  } else if (props.templateId) {
    return "templates_portfolios";
  } else {
    return "logs";
  }
};

const getKeyName = (props: IProps) => {
  if (props.exchangeId) {
    return "Exchange portfolios";
  } else if (props.templateId) {
    return "Template portfolios";
  } else {
    return "Portfolios";
  }
};

export function Portfolios(props: IProps) {
  const { customClientId } = useUserPreferences();
  const TABLE_KEY = useRef(getConfigKey(props));
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));

  const dataState = useGetData<GetPortfoliosQuery>({
    customClientId,
    graphqlQuery: getPortfolios,
    variables: getInput<GetPortfoliosInput>({
      templateId: props.templateId,
      exchangeId: props.exchangeId,
      limit: fetchLimit,
    }),
  });

  return (
    <>
      <NTable
        title={getKeyName(props)}
        toolbarIsDense={true}
        rememberStateKey={TABLE_KEY.current}
        columns={getColumns(PortfoliosAsChildrenColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="name"
        isLoading={dataState.isLoading}
        rows={prepareTableRows(dataState.data?.getPortfolios?.items ?? [])}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getPortfolios?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}

export const PortfoliosAsChildrenColumns: IHeadCell<Portfolio>[] = sortColumnsByLabelExceptFirst([
  {
    id: "name",
    label: "Portfolio name",
    numeric: false,
    disablePadding: false,
    linkTo: "/portfolio/:id/dash",
  },
  {
    id: "status",
    label: "Status",
    disablePadding: false,
    numeric: false,
    customRender: (value) => {
      switch (value) {
        case "wait":
          return (
            <Box
              sx={{
                color: grey[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <HourglassTop sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "active":
          return (
            <Box
              sx={{
                color: lightGreen[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <DirectionsRun sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "inconsistent":
          return (
            <Box
              sx={{
                color: orange[400],
                display: "flex",
                alignItems: "center",
                fontVariant: "all-small-caps",
              }}
            >
              <WarningAmber sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        default:
          return <Box>{value}</Box>;
      }
    },
  },
  {
    id: "buy",
    label: "Buy",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.buy ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.buy ? null : "line-through",
            opacity: row.buy ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <ArrowCircleDown sx={{ fontSize: "1.2em" }} /> &nbsp; Buy
        </Box>
      );
    },
  },
  {
    id: "sell",
    label: "Sell",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.sell ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.sell ? null : "line-through",
            opacity: row.sell ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <ArrowCircleUp sx={{ fontSize: "1.2em" }} /> &nbsp; Sell
        </Box>
      );
    },
  },
  {
    id: "onlyClose",
    label: "Only close",
    disablePadding: false,
    numeric: false,
    customRender: (_, row: Portfolio) => {
      return (
        <Box
          sx={{
            color: row.onlyClose ? lightGreen[400] : grey[400],
            display: "flex",
            alignItems: "center",
            textDecoration: row.onlyClose ? null : "line-through",
            opacity: row.onlyClose ? 1 : 0.5,
            fontVariant: "all-small-caps",
          }}
        >
          <NearbyError sx={{ fontSize: "1.2em" }} /> &nbsp; Only close
        </Box>
      );
    },
  },
  {
    id: "lastTradeMoment",
    label: "Last trade",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "amount",
    label: "Amount",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "level",
    label: "Level",
    numeric: true,
    disablePadding: false,
  },
  { id: "ask", label: "Ask", numeric: true, disablePadding: false },
  { id: "bid", label: "Bid", numeric: true, disablePadding: false },
  { id: "cooldown", label: "Cooldown", numeric: true, disablePadding: false },
  { id: "exchange", label: "Exchange", numeric: false, disablePadding: false, customRender: (value) => value.name },
  { id: "fixedAmount", label: "Fixed amount", numeric: true, disablePadding: false },
  { id: "fixedVolume", label: "Fixed volume", numeric: true, disablePadding: false },
  { id: "initialPrice", label: "Initial price", numeric: true, disablePadding: false },
  { id: "initialVolume", label: "Initial volume", numeric: true, disablePadding: false },
  { id: "initialVolumeEUR", label: "Initial volume EUR", numeric: true, disablePadding: false },
  { id: "limit", label: "Limit", numeric: true, disablePadding: false },
  { id: "martingale", label: "Martingale", numeric: true, disablePadding: false },
  { id: "nextLevelPrice", label: "Next level price", numeric: true, disablePadding: false },
  { id: "nextLevelVolume", label: "Next level volume", numeric: true, disablePadding: false },
  { id: "ordersType", label: "Orders type", numeric: false, disablePadding: false },
  { id: "positionControl", label: "Position control", numeric: false, disablePadding: false },
  { id: "priceShift", label: "Price shift", numeric: true, disablePadding: false },
  { id: "takeProfit", label: "Take profit", numeric: true, disablePadding: false },
  { id: "takeProfitPrice", label: "Take profit price", numeric: true, disablePadding: false },
  { id: "template", label: "Template", numeric: false, disablePadding: false, customRender: (value) => value.name },
  { id: "trdPair", label: "Trade pair", numeric: false, disablePadding: false },
  { id: "volume", label: "Volume", numeric: true, disablePadding: false },
]);
