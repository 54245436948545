import { Box, Button, Divider, Grid, TextField, Toolbar, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { NTable } from "../../../components/nTable/NTable";
import { prepareTableRows } from "../../../shared/utils";
import { Template } from "../../../shared/API";

export function Overview(props: { data?: Template | null }) {
  if (!props.data) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        maxWidth: "800px",
      }}
    >
      <Toolbar
        sx={{
          pl: { sm: 0 },
          pr: { xs: 1, sm: 1 },
          mb: { sm: 2 },
        }}
        variant="dense"
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Template overview
        </Typography>
      </Toolbar>
      <Typography variant="caption">Template info</Typography>
      <Divider sx={{ mb: "1ch" }} />
      <Grid container spacing={2}>
        <PropertyValue label="Name" value={props.data?.name} fullWidth />
        <PropertyValue label="Max buy volume" value={props.data?.maxBuyVolume} />
        <PropertyValue label="Max sell volume" value={props.data?.maxSellVolume} />
        <Grid item xs={12}>
          <NTable
            condensed
            hidePagination
            rows={prepareTableRows(props.data.levels ?? [])}
            defaultOrder="asc"
            defaultOrderBy="level"
            columns={[
              {
                id: "level",
                label: "Level",
                numeric: false,
                disablePadding: false,
              },
              {
                id: "priceShift",
                label: "Price shift",
                numeric: true,
                disablePadding: false,
              },
              {
                id: "takeProfit",
                label: "Take profit",
                numeric: true,
                disablePadding: false,
              },
              {
                id: "martingale",
                label: "Martingale",
                numeric: true,
                disablePadding: false,
              },
            ]}
          />
        </Grid>
      </Grid>
      <br />
      <NavLink to={"/template/" + props.data?.id}>
        <Button variant="contained" startIcon={<Edit />}>
          Edit
        </Button>
      </NavLink>
    </Box>
  );
}

function PropertyValue(props: { label: string; value: string | number | undefined | null; fullWidth?: boolean }) {
  return (
    <Grid item xs={props.fullWidth ? 12 : 6}>
      <TextField label={props.label} variant="filled" fullWidth value={props.value} disabled />
    </Grid>
  );
}
