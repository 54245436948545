import GoToNextLimit from "../../components/GoToNextToken";
import { API, graphqlOperation } from "aws-amplify";
import { createConfigEntry, tradePortfolio, updatePortfolio } from "../../graphql/mutations";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { getColumns, getInput, prepareTableRows } from "../../shared/utils";
import { getExchanges, getPortfolios } from "../../graphql/queries";
import { NModal } from "../../components/nModal/nModal";
import { NTable } from "../../components/nTable/NTable";
import { PortfolioColumns } from "./portfolio.entity";
import { SharedData } from "../../shared/sharedData/sharedData";
import { toast } from "react-toastify";
import { useGetData } from "../../shared/useGetData";
import { useMultiplePostData } from "../../shared/useMultiplePostData";
import { useNavigate } from "react-router-dom";
import { useUserPreferences } from "../_appLayout/AppContent";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  Divider,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import {
  CreateConfigEntryInput,
  GetExchangesInput,
  GetExchangesQuery,
  GetPortfoliosInput,
  GetPortfoliosQuery,
  UpdatePortfolioInput,
  PlacePortfolioOrderInput,
} from "../../shared/API";

const fetchLimit = 1000;

const parsePrevExchangeFilter = (): string[] => {
  const x = SharedData.get(`portfolios@prevFilters`);

  if (!x || !Array.isArray(x.exchanges)) {
    return [];
  }

  return x.exchanges.map((x: any) => x.toString());
};

const TABLE_KEY = "portfolios";

export function Portfolio() {
  const navigate = useNavigate();
  const { customClientId } = useUserPreferences();

  const exchangesState = useGetData<GetExchangesQuery>({
    customClientId,
    graphqlQuery: getExchanges,
    variables: getInput<GetExchangesInput>({ limit: Math.pow(2, 30) }),
  });

  const dataState = useGetData<GetPortfoliosQuery>({
    customClientId,
    graphqlQuery: getPortfolios,
    variables: getInput<GetPortfoliosInput>({ limit: fetchLimit }),
  });

  const onAddClick = () => navigate("/portfolio/new");

  const onExchangePick = (e: SelectChangeEvent<string[]>) => {
    setSelectedExchanges(typeof e.target.value === "string" ? [e.target.value] : e.target.value);
  };

  const [selectedExchanges, setSelectedExchanges] = useState<string[]>(parsePrevExchangeFilter());
  useEffect(() => {
    if (!dataState.isLoading) {
      SharedData.set(`portfolios@prevFilters`, { exchanges: selectedExchanges });

      setTimeout(() => {
        const fetch = async () => {
          try {
            await API.graphql(
              graphqlOperation(
                createConfigEntry,
                getInput<CreateConfigEntryInput>({
                  id: `portfolios@prevFilters`,
                  value: JSON.stringify({ exchanges: selectedExchanges }),
                })
              )
            );
          } catch (result: any) {
            toast.error("Failed to sync filters, contact Administrator!");
          }
        };
        fetch();
      });
    }
  }, [selectedExchanges, dataState.isLoading]);

  const data = (dataState.data?.getPortfolios?.items ?? []).filter((p) => {
    if (!selectedExchanges || selectedExchanges.length === 0) {
      return true;
    }

    return selectedExchanges.includes(p?.exchange?.id ?? "");
  });

  const selectedPortfolioIds = useRef<string[]>([]);
  const setSelectedPortfolioIds = useCallback((newIds: string[]) => {
    selectedPortfolioIds.current = newIds;
  }, []);

  const post = useMultiplePostData({
    customClientId,
    onSuccess: () => {
      toast.success("Portfolio succesfully mutated!");
      dataState.fetchData();
    },
    onError: () => {
      toast.error("Failed to mutate portfolio!");
    },
  });

  const mutateSelectedPortfolios = useCallback(
    (update: Partial<UpdatePortfolioInput>) => {
      post.postData(
        selectedPortfolioIds.current.map((id) => {
          return {
            graphqlQuery: updatePortfolio,
            variables: getInput<UpdatePortfolioInput>({
              id,
              ...update,
            }),
          };
        })
      );
    },
    [post]
  );

  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY));
  const [modalOpen, setModalOpen] = useState(false);
  const [placeOrdersPostData, setPlaceOrdersPostData] = useState([
    getInput<PlacePortfolioOrderInput>({
      id: "",
      dir: "",
      amount: 0,
      volume: 0,
    }),
  ]);

  const handleOpenModal = () => {
    setModalOpen(true);

    let newData = [...placeOrdersPostData];
    selectedPortfolioIds.current.map((portfolioData, index) => {
      let orderData = getInput<PlacePortfolioOrderInput>({
        id: portfolioData,
        dir: "",
        amount: null,
        volume: null,
      });

      index == 0 ? (newData[0] = orderData) : newData.push(orderData);
      setPlaceOrdersPostData(newData);
    });
  };

  const handlePortfolioOrderDirChange = (e: any, index: any) => {
    let placeOrdersNewData = [...placeOrdersPostData];
    placeOrdersNewData[index].input.dir = e.target.value;
    setPlaceOrdersPostData(placeOrdersNewData);
  };

  const handlePortfolioOrderAmountVolumeChanges = (e: any, index: any, updateValIndex: any) => {
    let placeOrdersNewData = [...placeOrdersPostData];
    updateValIndex === "amount"
      ? (placeOrdersNewData[index].input.amount = e.target.value)
      : (placeOrdersNewData[index].input.volume = e.target.value);
    setPlaceOrdersPostData(placeOrdersNewData);
  };

  const handlePlaceOrders = useCallback(() => {
    post.postData(
      placeOrdersPostData.map((orderPostData) => {
        return {
          graphqlQuery: tradePortfolio,
          variables: orderPostData,
        };
      })
    );
    setModalOpen(false);
  }, [post]);

  return (
    <>
      <NTable
        stickyHeadersMaxHeight="calc(100vh - 220px - 56px - 10px)"
        title="Portfolios"
        rememberStateKey={TABLE_KEY}
        elementsTop={
          <>
            <FormControl fullWidth variant="outlined" sx={{ mb: "10px" }}>
              <InputLabel id="demo-simple-select-label">Exchange filter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedExchanges}
                label="Exchange filter"
                multiple
                onChange={onExchangePick}
                variant="outlined"
              >
                {exchangesState.data?.getExchanges?.items?.map((e, i) => (
                  <MenuItem key={e?.id || i} value={e?.id || i}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        checkAble
        isLoading={dataState.isLoading || exchangesState.isLoading}
        columns={getColumns(PortfolioColumns, colsConfig)}
        supportsColumnOrder={{ tableKey: TABLE_KEY, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY)) }}
        defaultOrder="desc"
        defaultOrderBy="name"
        onAddClick={onAddClick}
        rows={prepareTableRows(data)}
        onCheck={setSelectedPortfolioIds}
        onSelectionAvailableActions={[
          {
            children: "Place portfolio order",
            type: "menuItem",
            onClick: () => handleOpenModal(),
          },
          { type: "divider" },
          {
            children: "Set active",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ status: "active" }),
          },
          {
            children: "Set wait",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ status: "wait" }),
          },
          { type: "divider" },
          {
            children: "Set buy enabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ buy: true }),
          },
          {
            children: "Set buy disabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ buy: false }),
          },
          { type: "divider" },
          {
            children: "Set sell enabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ sell: true }),
          },
          {
            children: "Set sell disabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ sell: false }),
          },
          { type: "divider" },
          {
            children: "Set only close enabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ onlyClose: true }),
          },
          {
            children: "Set only close disabled",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ onlyClose: false }),
          },
          { type: "divider" },
          {
            children: "Market close",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ task: "market.close" }),
          },
          { type: "divider" },
          {
            children: "Reset",
            type: "menuItem",
            onClick: () => mutateSelectedPortfolios({ task: "reset" }),
          },
        ]}
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getPortfolios?.nextToken} goToAction={dataState.fetchData} />

      <NModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        headerText={`Place Portfolio Order`}
        submitBtnText={`Place Orders`}
        onSubmit={() => handlePlaceOrders()}
      >
        <>
          {dataState?.data?.getPortfolios?.items
            ?.filter((el) => {
              return selectedPortfolioIds.current.includes(el?.id ? el?.id : "");
            })
            .map((portfolio, index) => {
              return (
                <Fragment key={index}>
                  <p>{portfolio?.name}</p>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "50ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={placeOrdersPostData[index].input.dir}
                          onChange={(event) => handlePortfolioOrderDirChange(event, index)}
                          style={{ flexDirection: "row" }}
                        >
                          <FormControlLabel value="buy" control={<Radio />} label="Buy" />
                          <FormControlLabel value="sell" control={<Radio />} label="Sell" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="amount"
                        label="Amount"
                        variant="filled"
                        value={placeOrdersPostData[index].input.amount}
                        onChange={(event) => handlePortfolioOrderAmountVolumeChanges(event, index, "amount")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="volume"
                        label="Volume"
                        variant="filled"
                        value={placeOrdersPostData[index].input.volume}
                        onChange={(event) => handlePortfolioOrderAmountVolumeChanges(event, index, "volume")}
                        fullWidth
                      />
                    </Grid>
                  </Box>
                  {selectedPortfolioIds.current.length > 1 && <Divider />}
                </Fragment>
              );
            })}
        </>
      </NModal>
    </>
  );
}
