import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

export function useGetData<Type>(props: { graphqlQuery: string; variables?: any | undefined; customClientId: string | undefined }) {
  const fetchedAlreadyForClientId = useRef("");

  const [state, setState] = useState<{
    isLoading: boolean;
    data: Type | undefined;
    error: string | undefined;
    stateKey: string;
  }>({
    isLoading: true,
    data: undefined,
    error: undefined,
    stateKey: v4(),
  });

  const fetchData = useCallback(
    (nextToken?: string | null | undefined) => {
      setState({ data: undefined, error: undefined, isLoading: true, stateKey: v4() });

      const fetch = async () => {
        try {
          if (props.customClientId) {
            if (props.variables?.input) {
              props.variables.input.clientId = props.customClientId;
            }
          }

          if (nextToken && props.variables?.input) {
            props.variables.input.nextToken = nextToken;
          }

          const datas: GraphQLResult<any> = await API.graphql(graphqlOperation(props.graphqlQuery, props.variables));
          setState({ isLoading: false, data: datas.data, error: undefined, stateKey: v4() });
        } catch (error: any) {
          setState({
            isLoading: false,
            data: undefined,
            error: "Failed to fetch data! Reason(s): \n" + error.errors.map((e: any) => e.message).join("\n"),
            stateKey: v4(),
          });
        }
      };

      fetch();
    },
    [props.customClientId, props.graphqlQuery, props.variables]
  );

  useEffect(() => {
    const daClientId = `clientId_${props.customClientId}`;
    if (fetchedAlreadyForClientId.current !== daClientId) {
      fetchData();
      fetchedAlreadyForClientId.current = daClientId;
    }
  }, [fetchData, props.customClientId]);

  return { ...state, fetchData };
}

export interface GraphQLResult<T = object> {
  data?: T;
  errors?: { message?: string }[];
  extensions?: {
    [key: string]: any;
  };
}
