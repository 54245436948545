import { Navigate, Route, Routes } from "react-router-dom";

import { Box, Toolbar } from "@mui/material";

import { ExchangeDash } from "../../dash/ExchangeDash";
import { PortfolioDash } from "../../dash/PortfolioDash";
import { ExchangeEdit } from "../../exchanges/Exchange.edit";
import { Exchange } from "../../exchanges/Exchange.list";
import { ExchangeNew } from "../../exchanges/Exchange.new";
import { Log } from "../../log/Log.list";
import { Portfolio } from "../../portfolio/Portfolio.list";
import { PortfolioNew } from "../../portfolio/Portfolio.new";
import { UserSettings } from "../../userSettings/UserSettings";
import { Templates } from "../../templates/Template.list";
import { TemplateCreate } from "../../templates/Template.create";
import { PortfolioEdit } from "../../portfolio/Portfolio.edit";
import { TemplateEdit } from "../../templates/Template.edit";
import { TemplateDash } from "../../dash/TemplateDash";

export function Content(props: { drawerWidth: number }) {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 2,
        width: { sm: `calc(100% - ${props.drawerWidth}px)` },
      }}
    >
      <Toolbar />
      <Routes>
        <Route path="/" element={<Navigate to="/exchange" replace />} />
        <Route path="log" element={<Log />} />
        <Route path="exchange" element={<Exchange />} />
        <Route path="exchange/new" element={<ExchangeNew />} />
        <Route path="exchange/:id" element={<ExchangeEdit />} />
        <Route path="exchange/:id/dash" element={<ExchangeDash />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfolio/new" element={<PortfolioNew />} />
        <Route path="portfolio/:id" element={<PortfolioEdit />} />
        <Route path="portfolio/:id/dash" element={<PortfolioDash />} />
        <Route path="template" element={<Templates />} />
        <Route path="template/new" element={<TemplateCreate />} />
        <Route path="template/:id" element={<TemplateEdit />} />
        <Route path="template/:id/dash" element={<TemplateDash />} />
        <Route path="user_settings" element={<UserSettings />} />
      </Routes>
    </Box>
  );
}
