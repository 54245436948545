import Box from "@mui/material/Box";
import { AppContent } from "./AppContent";
import { AppInitialLoad } from "./AppInitialLoad";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme, lightTheme } from "./themes";
import { ToastContainer } from "react-toastify";
import { useCallback, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

export function AppLayout() {
  const [isInitialLoaded, setLoaded] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem("isDark") === "false" ? false : true);

  const toggleTheme = useCallback(() => {
    setIsDarkTheme((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isDark", newState.toString());
      return newState;
    });
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          {!isInitialLoaded ? (
            <AppInitialLoad onLoaded={setLoaded} />
          ) : (
            <AppContent darkTheme={darkTheme} isDarkTheme={isDarkTheme} lightTheme={lightTheme} toggleTheme={toggleTheme} />
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
