// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#general-configuration
const awsExports = {
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_mandatory_sign_in: process.env.REACT_APP_MANDATORY_SIGN_IN, // (optional) - Users are not allowed to get the aws credentials unless they are signed in

  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION, // (optional) - AWS AppSync region
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL, // (optional) - AWS AppSync endpoint
  aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH, // (optional) - Primary AWS AppSync authentication type
};

export default awsExports;
