import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { blue, red } from "@mui/material/colors";
import { Box } from "@mui/material";
import { getColumns, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../../shared/utils";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { Order } from "../../../shared/API";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useRef, useState } from "react";

export function OrdersChain(props: { ordersChain: (Order | null)[] | null | undefined }) {
  const TABLE_KEY = useRef("portfolios_ordersChain");
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));
  return (
    <>
      <NTable
        toolbarIsDense
        title={"Portfolio orders"}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        rememberStateKey={TABLE_KEY.current}
        columns={getColumns(OrdersChainAsChildrenColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="trdPair"
        rows={prepareTableRows(props.ordersChain ?? [])}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />
    </>
  );
}

export const OrdersChainAsChildrenColumns: IHeadCell<Order>[] = sortColumnsByLabelExceptFirst([
  {
    id: "time",
    label: "Time",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "id",
    label: "Order ID",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "trdPair",
    label: "Trade pair",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "dir",
    label: "Direction",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      switch (value) {
        case "SELL":
          return (
            <Box sx={{ color: blue[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleUpIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "BUY":
          return (
            <Box sx={{ color: red[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleDownIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        default:
          return <Box>{value}</Box>;
      }
    },
  },
  {
    id: "qty",
    label: "Quantity",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "price",
    label: "Price",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "level",
    label: "Level",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "role",
    label: "Role",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "volume",
    label: "Volume",
    numeric: true,
    disablePadding: false,
  },
  { id: "status", label: "Status", numeric: false, disablePadding: false },
  { id: "kind", label: "Kind", numeric: false, disablePadding: false },
  { id: "type", label: "Type", numeric: false, disablePadding: false },
  { id: "fulfilled", label: "Fulfilled", numeric: true, disablePadding: false },
  { id: "qtyChange", label: "Quantity change", numeric: true, disablePadding: false },
  { id: "linked", label: "Linked", numeric: false, disablePadding: false },
  { id: "trdAmount", label: "Trade amount", numeric: true, disablePadding: false },
]);
