import GoToNextLimit from "../../../components/GoToNextToken";
import { getColumns, getInput, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../../shared/utils";
import { getPositions } from "../../../graphql/queries";
import { GetPositionsInput, GetPositionsQuery, Position } from "../../../shared/API";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useGetData } from "../../../shared/useGetData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../_appLayout/AppContent";

const fetchLimit = 1000;

export function Positions(props: { exchangeId: string }) {
  const { customClientId } = useUserPreferences();
  const TABLE_KEY = useRef("exchanges_positions");
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));

  const dataState = useGetData<GetPositionsQuery>({
    customClientId,
    graphqlQuery: getPositions,
    variables: getInput<GetPositionsInput>({
      exchangeId: props.exchangeId,
      limit: fetchLimit,
    }),
  });

  return (
    <>
      <NTable
        title="Exchange positions"
        toolbarIsDense
        rememberStateKey={TABLE_KEY.current}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        columns={getColumns(PositionsAsChildrenColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="symbol"
        isLoading={dataState.isLoading}
        rows={prepareTableRows(dataState.data?.getPositions?.items ?? [])}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getPositions?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}

export const PositionsAsChildrenColumns: IHeadCell<Position>[] = sortColumnsByLabelExceptFirst([
  {
    id: "updateTime",
    label: "Time",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "symbol",
    label: "Symbol",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "positionAmt",
    label: "Position Amount",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "unRealizedProfit",
    label: "Unrealized profit",
    numeric: true,
    disablePadding: false,
  },
  { id: "entryPrice", label: "Entry price", numeric: true, disablePadding: false },
  { id: "markPrice", label: "Mark price", numeric: true, disablePadding: false },
  { id: "liquidationPrice", label: "Liquidation price", numeric: true, disablePadding: false },
  { id: "leverage", label: "Leverage", numeric: true, disablePadding: false },
  { id: "maxNotionalValue", label: "Max notional value", numeric: true, disablePadding: false },
  { id: "marginType", label: "Margin type", numeric: false, disablePadding: false },
  { id: "isolatedMargin", label: "Isolated margin", numeric: true, disablePadding: false },
  { id: "isAutoAddMargin", label: "Is auto add margin", numeric: false, disablePadding: false },
  { id: "positionSide", label: "Position side", numeric: false, disablePadding: false },
  { id: "notional", label: "Notional", numeric: true, disablePadding: false },
  { id: "isolatedWallet", label: "Isolated wallet", numeric: true, disablePadding: false },
]);
