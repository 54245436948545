import { Box, LinearProgress, Paper, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { getExchange } from "../../graphql/queries";
import { GetExchangeInput, GetExchangeQuery } from "../../shared/API";
import { getInput } from "../../shared/utils";
import { Logs } from "./children/logs";
import { Orders } from "./children/orders";
import { Overview } from "./children/exchangeOverview";
import { Portfolios } from "./children/portfolios";
import { Positions } from "./children/positions";
import { Trades } from "./children/trades";
import { useGetData } from "../../shared/useGetData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useUserPreferences } from "../_appLayout/AppContent";

export function ExchangeDash() {
  const { id = "" } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { customClientId } = useUserPreferences();

  const dataState = useGetData<GetExchangeQuery>({
    customClientId,
    graphqlQuery: getExchange,
    variables: getInput<GetExchangeInput>({ id }),
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography sx={{ flex: "1 1 100%" }} variant="h6" component="div">
            Exchange: {dataState.data?.getExchange?.name}
          </Typography>
        </Toolbar>
        {dataState.isLoading ? <LinearProgress /> : <div style={{ height: "4px" }} />}

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs value={selectedTabIndex} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Overview" id="0" />
            <Tab label="Trades" id="1" />
            <Tab label="Orders" id="2" />
            <Tab label="Positions" id="3" />
            <Tab label="Portfolios" id="4" />
            <Tab label="Logs" id="5" />
          </Tabs>
        </Box>
        <TabPanel value={selectedTabIndex} index={0}>
          <Overview data={dataState.data?.getExchange} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={1}>
          <Trades exchangeId={id} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={2}>
          <Orders exchangeId={id} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={3}>
          <Positions exchangeId={id} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={4}>
          <Portfolios exchangeId={id} />
        </TabPanel>
        <TabPanel value={selectedTabIndex} index={5}>
          <Logs exchangeId={id} />
        </TabPanel>
      </Paper>
    </Box>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
