import { Auth } from "aws-amplify";
import { Button, Divider } from "@mui/material";
import { ClientPicker } from "./clientPicker/ClientPicker";
import { Logout, Person } from "@mui/icons-material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ClientStatus } from "./clientStatus/ClientStatus";

export function User() {
  const { user } = useAuthenticator((context) => [context.user]);

  const signOut = () => {
    const signUserOut = async () => {
      await Auth.signOut();
      window.location.reload();
    };
    signUserOut();
  };

  return (
    <>
      <div style={{ padding: "10px 16px", display: "flex", flexDirection: "column", gap: "10px" }}>
        <ClientPicker username={user.username} userEmail={user?.attributes?.email} />
        <ClientStatus />
      </div>
      <Divider />
      <div style={{ padding: "8px 16px" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <Person /> {user?.attributes?.email}
        </div>

        <Button startIcon={<Logout />} onClick={signOut}>
          Sign out
        </Button>
      </div>
    </>
  );
}
