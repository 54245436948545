import { Box, Button, Divider, Grid, TextField, Toolbar, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Exchange } from "../../../shared/API";
import { NavLink } from "react-router-dom";

export function Overview(props: { data?: Exchange | null }) {
  if (!props.data) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        maxWidth: "800px",
      }}
    >
      <Toolbar
        sx={{
          pl: { sm: 0 },
          pr: { xs: 1, sm: 1 },
          mb: { sm: 2 },
        }}
        variant="dense"
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Exchange overview
        </Typography>
      </Toolbar>
      <Typography variant="caption">Exchange info</Typography>
      <Divider sx={{ mb: "1ch" }} />
      <Grid container spacing={2}>
        <PropertyValue label="Name" value={props.data?.name} />
        <PropertyValue label="Kind" value={props.data?.kind} />
        <PropertyValue label="Public key" value={props.data?.publicKey} fullSize />
      </Grid>
      <br />
      <NavLink to={"/exchange/" + props.data?.id}>
        <Button variant="contained" startIcon={<Edit />}>
          Edit
        </Button>
      </NavLink>
      <br /> <br />
      <Typography variant="caption">Exchange</Typography>
      <Divider sx={{ mb: "1ch" }} />
      <Grid container spacing={2}>
        <PropertyValue label="Available balance" value={props.data?.availableBalance} />
        <PropertyValue label="Last transfer moment" value={props.data?.lastTransferMoment} />
        <PropertyValue label="Max withdraw amount" value={props.data?.maxWithdrawAmount} />
        <PropertyValue label="Total cross UnPnl" value={props.data?.totalCrossUnPnl} />
        <PropertyValue label="Total cross wallet balance" value={props.data?.totalCrossWalletBalance} />
        <PropertyValue label="Total initial margin" value={props.data?.totalInitialMargin} />
        <PropertyValue label="Total margin balance" value={props.data?.totalMarginBalance} />
        <PropertyValue label="Total open order initial margin" value={props.data?.totalOpenOrderInitialMargin} />
        <PropertyValue label="Total position initial margin" value={props.data?.totalPositionInitialMargin} />
        <PropertyValue label="Total transferred USDT" value={props.data?.totalTransferredUSDT} />
        <PropertyValue label="Total unrealized profit" value={props.data?.totalUnrealizedProfit} />
        <PropertyValue label="Total wallet balance" value={props.data?.totalWalletBalance} />
      </Grid>
    </Box>
  );
}

function PropertyValue(props: { label: string; value: string | number | undefined | null; fullSize?: boolean }) {
  return (
    <Grid item xs={12} md={props.fullSize ? 12 : 6}>
      <TextField label={props.label} variant="filled" fullWidth value={props.value || undefined} disabled />
    </Grid>
  );
}
