import GoToNextLimit from "../../components/GoToNextToken";
import { ExchangeColumns } from "./Exchange.entity";
import { getColumns, getInput, prepareTableRows } from "../../shared/utils";
import { getExchanges } from "../../graphql/queries";
import { GetExchangesInput, GetExchangesQuery } from "../../shared/API";
import { NTable } from "../../components/nTable/NTable";
import { SharedData } from "../../shared/sharedData/sharedData";
import { useGetData } from "../../shared/useGetData";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUserPreferences } from "../_appLayout/AppContent";

const fetchLimit = 1000;
const TABLE_KEY = "exchanges";

export function Exchange() {
  const navigate = useNavigate();
  const { customClientId } = useUserPreferences();
  const dataState = useGetData<GetExchangesQuery>({
    customClientId,
    graphqlQuery: getExchanges,
    variables: getInput<GetExchangesInput>({ limit: fetchLimit }),
  });

  const onAddClick = () => navigate("/exchange/new");

  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY));

  return (
    <>
      <NTable
        stickyHeadersMaxHeight="calc(100vh - 220px)"
        title="Exchanges"
        rememberStateKey={TABLE_KEY}
        supportsColumnOrder={{ tableKey: TABLE_KEY, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY)) }}
        isLoading={dataState.isLoading}
        error={dataState.error}
        columns={getColumns(ExchangeColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="id"
        onAddClick={onAddClick}
        rows={prepareTableRows(dataState.data?.getExchanges?.items ?? [])}
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getExchanges?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}
