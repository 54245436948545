import { CheckboxElement, SelectElement, TextFieldElement } from "react-hook-form-mui";
import { deletePortfolio, updatePortfolio } from "../../graphql/mutations";
import { getInput } from "../../shared/utils";
import { getPortfolio, getTemplates } from "../../graphql/queries";
import { GetPortfolioQuery, GetTemplatesInput, GetTemplatesQuery, UpdatePortfolioInput } from "../../shared/API";
import { Grid, TextField } from "@mui/material";
import { NFormContainer } from "../../components/nFormContainer/NFormContainer";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { useGetData } from "../../shared/useGetData";
import { useNavigate, useParams } from "react-router-dom";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";

type IState = {
  buy: null | number | undefined | string;
  sell: null | number | undefined | string;
  helpObject: { templateId?: string | null | undefined; initialVolum?: string | number | null | undefined };
};

export function PortfolioEdit() {
  const { customClientId } = useUserPreferences();
  const navigate = useNavigate();
  const { id } = useParams();

  const dataState = useGetData<GetPortfolioQuery>({
    customClientId,
    graphqlQuery: getPortfolio,
    variables: {
      input: { id },
    },
  });

  const templatesState = useGetData<GetTemplatesQuery>({
    customClientId,
    graphqlQuery: getTemplates,
    variables: getInput<GetTemplatesInput>({ limit: 1000 }),
  });

  const post = usePostData({
    customClientId,
    onSuccess: () => {
      navigate("/portfolio");
      toast.success("Portfolio succesfully mutated!");
    },
    onError: () => {
      toast.error("Failed to mutate portfolio!");
    },
  });

  const updatePortfolioAction = useCallback(
    (formData: UpdatePortfolioInput) => {
      post.postData(
        updatePortfolio,
        getInput<UpdatePortfolioInput>({
          id: formData.id,
          buy: formData.buy,
          initialVolume: formData.initialVolume,
          name: formData.name,
          sell: formData.sell,
          onlyClose: formData.onlyClose,
          status: formData.status,
          cooldown: formData.cooldown,
          templateId: formData.templateId,
        })
      );
    },
    [post]
  );

  const deletePortfolioAction = useCallback(() => {
    post.postData(deletePortfolio, { input: { id } });
  }, [post, id]);

  const [maxVolumes, setMaxVolumes] = useState<IState>({
    buy: "",
    sell: "",
    helpObject: {},
  });

  const setMaxVolumesAction = useCallback(
    (newInitialVolum: string | number | null | undefined, newTempalteId: string | null | undefined) => {
      if (newInitialVolum !== maxVolumes.helpObject.initialVolum || newTempalteId !== maxVolumes.helpObject.templateId) {
        const newStatToBe: IState = {
          buy: "",
          sell: "",
          helpObject: {
            templateId: newTempalteId,
            initialVolum: newInitialVolum,
          },
        };
        try {
          const selectedTemplate = templatesState.data?.getTemplates?.items?.find((t) => t?.id === newTempalteId);

          try {
            if (selectedTemplate?.maxBuyVolume != null) {
              newStatToBe.buy = Number(newInitialVolum) * selectedTemplate.maxBuyVolume;
            }
            if (selectedTemplate?.maxSellVolume != null) {
              newStatToBe.sell = Number(newInitialVolum) * selectedTemplate.maxSellVolume;
            }
          } catch (error) {
            // nothign for now
          }

          setMaxVolumes(newStatToBe);
        } catch (error) {
          setMaxVolumes({
            buy: "",
            sell: "",
            helpObject: {
              templateId: newTempalteId,
              initialVolum: newInitialVolum,
            },
          });
        }
      }
    },
    [maxVolumes.helpObject.initialVolum, maxVolumes.helpObject.templateId, templatesState.data?.getTemplates?.items]
  );

  const registerWatchers = useCallback(
    (watcher: any) => {
      const [initialVolum, templateId] = watcher(["initialVolume", "templateId"]);
      setMaxVolumesAction(initialVolum, templateId);
    },
    [setMaxVolumesAction]
  );

  const [formDefaultValues, setFormDefaultValues] = useState<any>();

  useEffect(() => {
    setFormDefaultValues({ ...dataState.data?.getPortfolio, templateId: dataState.data?.getPortfolio?.template?.id } || undefined);
  }, [dataState.data?.getPortfolio]);

  return (
    <NFormContainer
      watchers={(watcher) => {
        registerWatchers(watcher);
      }}
      onFormReady={updatePortfolioAction}
      title="Edit portfolio"
      defaultValues={formDefaultValues}
      deleteProps={{
        redirectToPath: "/portfolio",
        onClick: deletePortfolioAction,
      }}
      isLoading={dataState.isLoading || templatesState.isLoading}
      isFrozen={post.state.isLoading}
    >
      <Grid item xs={12} md={6}>
        <TextFieldElement name="name" label="Name" required variant="outlined" fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="status"
          label="Status"
          required
          variant="outlined"
          fullWidth
          options={[
            { id: "active", title: "Active" },
            { id: "wait", title: "Wait" },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectElement
          name="templateId"
          label="Template"
          required
          variant="outlined"
          fullWidth
          options={templatesState.data?.getTemplates?.items?.map((e) => ({ id: e?.id, title: e?.name }))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldElement
          name="initialVolume"
          label="Initial volume (USD)"
          // validation={{ min: (val) => (val > 0) | "Intial volume must be > 0!" }}
          type="number"
          required
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField label="Max buy volume" disabled variant="outlined" fullWidth value={maxVolumes.buy} />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField label="Max sell volume" disabled variant="outlined" fullWidth value={maxVolumes.sell} />
      </Grid>

      <Grid item xs={12}>
        <CheckboxElement name="buy" label="Buy" />
        <CheckboxElement name="sell" label="Sell" />
        <CheckboxElement name="onlyClose" label="Only close" />
      </Grid>
    </NFormContainer>
  );
}
