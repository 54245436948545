import { IHeadCell } from "../../components/nTable/NTable.types";
import { Exchange } from "../../shared/API";
import { LocalizedTs, sortColumnsByLabelExceptFirst } from "../../shared/utils";

export const ExchangeColumns: IHeadCell<Exchange>[] = sortColumnsByLabelExceptFirst([
  {
    id: "name",
    label: "Name",
    numeric: false,
    disablePadding: false,
    linkTo: "/exchange/:id/dash",
  },
  {
    id: "kind",
    label: "Type",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "lastTransferMoment",
    label: "Last transfer",
    numeric: false,
    disablePadding: false,
    customRender: (value) => <LocalizedTs value={value} />,
  },
  { id: "availableBalance", label: "Available balance", numeric: true, disablePadding: false },
  { id: "totalWalletBalance", label: "Total wallet balance", numeric: true, disablePadding: false },
  { id: "totalUnrealizedProfit", label: "Total unrealized profit", numeric: true, disablePadding: false },
  { id: "totalCrossWalletBalance", label: "Total cross wallet balance", numeric: true, disablePadding: false },
  { id: "totalCrossUnPnl", label: "Total cross UnPnl", numeric: true, disablePadding: false },
  { id: "maxWithdrawAmount", label: "Max withdraw amount", numeric: true, disablePadding: false },
  { id: "totalTransferredUSDT", label: "Total transferred USDT", numeric: true, disablePadding: false },
  { id: "totalInitialMargin", label: "Total initial margin", numeric: true, disablePadding: false },
  { id: "totalMaintMargin", label: "Total maint margin", numeric: true, disablePadding: false },
  { id: "totalMarginBalance", label: "Total margin balance", numeric: true, disablePadding: false },
  { id: "totalPositionInitialMargin", label: "Total position initial margin", numeric: true, disablePadding: false },
  { id: "totalOpenOrderInitialMargin", label: "Total open order initial margin", numeric: true, disablePadding: false },
  { id: "totalNotional", label: "Total notional", numeric: true, disablePadding: false },
]);
