import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Toolbar, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { Portfolio } from "../../../shared/API";

export function Overview(props: { data?: Portfolio | null }) {
  if (!props.data) {
    return null;
  }

  const maxVolume = (() => {
    const returnValue = {
      buy: "",
      sell: "",
    };

    try {
      returnValue.buy = Number(props.data.template?.maxBuyVolume) * (props.data.initialVolume || 0) + "";
    } catch (error) {
      // do nothing for now
    }

    try {
      returnValue.sell = Number(props.data.template?.maxSellVolume) * (props.data.initialVolume || 0) + "";
    } catch (error) {
      // do nothing for now
    }
    return returnValue;
  })();

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        maxWidth: "800px",
      }}
    >
      <Toolbar
        sx={{
          pl: { sm: 0 },
          pr: { xs: 1, sm: 1 },
          mb: { sm: 2 },
        }}
        variant="dense"
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Portfolio overview
        </Typography>
      </Toolbar>
      <Typography variant="caption">Portfolio info</Typography>
      <Divider sx={{ mb: "1ch" }} />
      <Grid container spacing={2}>
        <PropertyValue label="Name" value={props.data?.name} />
        <PropertyValue label="Exchange" value={props.data?.exchange?.name} />
        <PropertyValue label="Status" value={props.data?.status} />
        <PropertyValue label="Template" value={props.data?.template?.name} />
        <PropertyValue label="Initial volume (USD)" value={props.data?.initialVolume} />
        <PropertyValue label="Limit" value={props.data?.limit} />
        <PropertyValue label="Trade pair" value={props.data?.trdPair} />

        <PropertyValue label="Max buy volume" value={maxVolume.buy} />
        <PropertyValue label="Max sell volume" value={maxVolume.sell} />

        <Grid item xs={12}>
          <FormControlLabel control={<Checkbox disabled checked={props.data?.buy || false} />} label="Buy" />
          <FormControlLabel control={<Checkbox disabled checked={props.data?.sell || false} />} label="Sell" />
          <FormControlLabel control={<Checkbox disabled checked={props.data?.onlyClose || false} />} label="Only close" />
        </Grid>
      </Grid>
      <br />
      <NavLink to={"/portfolio/" + props.data?.id}>
        <Button variant="contained" startIcon={<Edit />}>
          Edit
        </Button>
      </NavLink>
    </Box>
  );
}

function PropertyValue(props: { label: string; value: string | number | undefined | null }) {
  return (
    <Grid item xs={12} md={6}>
      <TextField label={props.label} variant="filled" fullWidth value={props.value} disabled />
    </Grid>
  );
}
