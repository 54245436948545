import { useEffect, useState } from "react";

import { CancelOutlined, Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export function NPrompDialog(props: { yieldsActions: { show: (() => void) | undefined }; onYes?: () => void }) {
  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const afirmative = () => {
    setOpen(false);
    props.onYes?.();
  };

  useEffect(() => {
    props.yieldsActions.show = show;
  }, [props.yieldsActions]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this item? Your action is <strong>irreversible</strong>!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={afirmative} variant="contained" color="warning" startIcon={<Delete />}>
          Yes
        </Button>
        <Button onClick={close} variant="contained" autoFocus startIcon={<CancelOutlined />}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
