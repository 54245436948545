import { SkipNext } from "@mui/icons-material";
import { Button, Paper, Typography } from "@mui/material";

export default function GoToNextLimit(props: {
  nextToken: string | undefined | null;
  goToAction: (nextToken: string) => void;
  limit: number;
}) {
  if (!props.nextToken) {
    return null;
  }

  const onClick = () => {
    props.goToAction(props.nextToken || "");
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="body2">
        Because of large data set, the data was sliced into groups of {props.limit}.
        <br />
        <Button onClick={onClick} endIcon={<SkipNext />}>
          Go to next data slice
        </Button>
      </Typography>
    </Paper>
  );
}
