/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExchange = /* GraphQL */ `
  mutation CreateExchange($input: CreateExchangeInput!) {
    createExchange(input: $input) {
      clientId
      id
      kind
      name
      publicKey
      lastTransferMoment
      lastRequestEndTime
      totalTransferredUSDT
      totalInitialMargin
      totalMaintMargin
      totalWalletBalance
      totalUnrealizedProfit
      totalMarginBalance
      totalPositionInitialMargin
      totalOpenOrderInitialMargin
      totalCrossWalletBalance
      totalCrossUnPnl
      availableBalance
      maxWithdrawAmount
      totalNotional
    }
  }
`;
export const updateExchange = /* GraphQL */ `
  mutation UpdateExchange($input: UpdateExchangeInput!) {
    updateExchange(input: $input) {
      clientId
      id
      kind
      name
      publicKey
      lastTransferMoment
      lastRequestEndTime
      totalTransferredUSDT
      totalInitialMargin
      totalMaintMargin
      totalWalletBalance
      totalUnrealizedProfit
      totalMarginBalance
      totalPositionInitialMargin
      totalOpenOrderInitialMargin
      totalCrossWalletBalance
      totalCrossUnPnl
      availableBalance
      maxWithdrawAmount
      totalNotional
    }
  }
`;
export const deleteExchange = /* GraphQL */ `
  mutation DeleteExchange($input: DeleteExchangeInput!) {
    deleteExchange(input: $input) {
      clientId
      id
      kind
      name
      publicKey
      lastTransferMoment
      lastRequestEndTime
      totalTransferredUSDT
      totalInitialMargin
      totalMaintMargin
      totalWalletBalance
      totalUnrealizedProfit
      totalMarginBalance
      totalPositionInitialMargin
      totalOpenOrderInitialMargin
      totalCrossWalletBalance
      totalCrossUnPnl
      availableBalance
      maxWithdrawAmount
      totalNotional
    }
  }
`;
export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio($input: CreatePortfolioInput!) {
    createPortfolio(input: $input) {
      clientId
      id
      trdPair
      exchange {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      template {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      name
      status
      buy
      sell
      onlyClose
      cooldown
      initialVolume
      tradeVolume
      absInitialVolume
      initialVolumeEUR
      fixedVolume
      fixedAmount
      initialPrice
      positionControl
      amount
      volume
      absVolume
      level
      takeProfit
      priceShift
      martingale
      limit
      lastTradeMoment
      orders {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      ordersChain {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      trades {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      bid
      ask
      levels {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      ordersType
      nextLevelPrice
      nextLevelVolume
      takeProfitPrice
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio($input: UpdatePortfolioInput!) {
    updatePortfolio(input: $input) {
      clientId
      id
      trdPair
      exchange {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      template {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      name
      status
      buy
      sell
      onlyClose
      cooldown
      initialVolume
      tradeVolume
      absInitialVolume
      initialVolumeEUR
      fixedVolume
      fixedAmount
      initialPrice
      positionControl
      amount
      volume
      absVolume
      level
      takeProfit
      priceShift
      martingale
      limit
      lastTradeMoment
      orders {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      ordersChain {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      trades {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      bid
      ask
      levels {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      ordersType
      nextLevelPrice
      nextLevelVolume
      takeProfitPrice
    }
  }
`;
export const deletePortfolio = /* GraphQL */ `
  mutation DeletePortfolio($input: DeletePortfolioInput!) {
    deletePortfolio(input: $input) {
      clientId
      id
      trdPair
      exchange {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      template {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      name
      status
      buy
      sell
      onlyClose
      cooldown
      initialVolume
      tradeVolume
      absInitialVolume
      initialVolumeEUR
      fixedVolume
      fixedAmount
      initialPrice
      positionControl
      amount
      volume
      absVolume
      level
      takeProfit
      priceShift
      martingale
      limit
      lastTradeMoment
      orders {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      ordersChain {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      trades {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      bid
      ask
      levels {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      ordersType
      nextLevelPrice
      nextLevelVolume
      takeProfitPrice
    }
  }
`;
export const tradePortfolio = /* GraphQL */ `
  mutation TradePortfolio($input: PlacePortfolioOrderInput!) {
    tradePortfolio(input: $input) {
      clientId
      id
      trdPair
      exchange {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
      template {
        clientId
        id
        name
        levels {
          id
          level
          priceShift
          takeProfit
          martingale
        }
        maxBuyVolume
        maxSellVolume
      }
      name
      status
      buy
      sell
      onlyClose
      cooldown
      initialVolume
      tradeVolume
      absInitialVolume
      initialVolumeEUR
      fixedVolume
      fixedAmount
      initialPrice
      positionControl
      amount
      volume
      absVolume
      level
      takeProfit
      priceShift
      martingale
      limit
      lastTradeMoment
      orders {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      ordersChain {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
      trades {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
      bid
      ask
      levels {
        clientId
        strategyId
        id
        level
        takeProfit
        priceShift
        martingale
      }
      ordersType
      nextLevelPrice
      nextLevelVolume
      takeProfitPrice
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      clientId
      id
      name
      levels {
        id
        level
        priceShift
        takeProfit
        martingale
      }
      maxBuyVolume
      maxSellVolume
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      clientId
      id
      name
      levels {
        id
        level
        priceShift
        takeProfit
        martingale
      }
      maxBuyVolume
      maxSellVolume
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input) {
      clientId
      id
      name
      levels {
        id
        level
        priceShift
        takeProfit
        martingale
      }
      maxBuyVolume
      maxSellVolume
    }
  }
`;
export const createConfigEntry = /* GraphQL */ `
  mutation CreateConfigEntry($input: CreateConfigEntryInput!) {
    createConfigEntry(input: $input) {
      id
      value
    }
  }
`;
export const updateConfigEntry = /* GraphQL */ `
  mutation UpdateConfigEntry($input: UpdateConfigEntryInput!) {
    updateConfigEntry(input: $input) {
      id
      value
    }
  }
`;
export const deleteConfigEntry = /* GraphQL */ `
  mutation DeleteConfigEntry($input: DeleteConfigEntryInput!) {
    deleteConfigEntry(input: $input) {
      id
      value
    }
  }
`;
export const updateTradingMode = /* GraphQL */ `
  mutation UpdateTradingMode($input: UpdateTradingModeInput!) {
    updateTradingMode(input: $input) {
      mode
    }
  }
`;
export const modifyLog = /* GraphQL */ `
  mutation ModifyLog($clientId: ID!, $operation: CRUD!, $input: LogInput!) {
    modifyLog(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        exchangeId
        portfolioId
        timestamp
        src
        srcId
        info
      }
    }
  }
`;
export const modifyTrade = /* GraphQL */ `
  mutation ModifyTrade($clientId: ID!, $operation: CRUD!, $input: TradeInput!) {
    modifyTrade(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        orderId
        id
        price
        qty
        time
        dir
        trdPair
        level
      }
    }
  }
`;
export const modifyPortfolio = /* GraphQL */ `
  mutation ModifyPortfolio(
    $clientId: ID!
    $operation: CRUD!
    $input: PortfolioInput!
  ) {
    modifyPortfolio(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        id
        trdPair
        exchange {
          clientId
          id
          kind
          name
          publicKey
          lastTransferMoment
          lastRequestEndTime
          totalTransferredUSDT
          totalInitialMargin
          totalMaintMargin
          totalWalletBalance
          totalUnrealizedProfit
          totalMarginBalance
          totalPositionInitialMargin
          totalOpenOrderInitialMargin
          totalCrossWalletBalance
          totalCrossUnPnl
          availableBalance
          maxWithdrawAmount
          totalNotional
        }
        template {
          clientId
          id
          name
          levels {
            id
            level
            priceShift
            takeProfit
            martingale
          }
          maxBuyVolume
          maxSellVolume
        }
        name
        status
        buy
        sell
        onlyClose
        cooldown
        initialVolume
        tradeVolume
        absInitialVolume
        initialVolumeEUR
        fixedVolume
        fixedAmount
        initialPrice
        positionControl
        amount
        volume
        absVolume
        level
        takeProfit
        priceShift
        martingale
        limit
        lastTradeMoment
        orders {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        ordersChain {
          clientId
          portfolioId
          id
          level
          orderId
          time
          status
          dir
          price
          qty
          volume
          role
          kind
          type
          fulfilled
          qtyChange
          linked
          trdPair
          trdAmount
        }
        trades {
          clientId
          orderId
          id
          price
          qty
          time
          dir
          trdPair
          level
        }
        bid
        ask
        levels {
          clientId
          strategyId
          id
          level
          takeProfit
          priceShift
          martingale
        }
        ordersType
        nextLevelPrice
        nextLevelVolume
        takeProfitPrice
      }
    }
  }
`;
export const modifyExchange = /* GraphQL */ `
  mutation ModifyExchange(
    $clientId: ID!
    $operation: CRUD!
    $input: ExchangeInput!
  ) {
    modifyExchange(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        id
        kind
        name
        publicKey
        lastTransferMoment
        lastRequestEndTime
        totalTransferredUSDT
        totalInitialMargin
        totalMaintMargin
        totalWalletBalance
        totalUnrealizedProfit
        totalMarginBalance
        totalPositionInitialMargin
        totalOpenOrderInitialMargin
        totalCrossWalletBalance
        totalCrossUnPnl
        availableBalance
        maxWithdrawAmount
        totalNotional
      }
    }
  }
`;
export const modifyOrder = /* GraphQL */ `
  mutation ModifyOrder($clientId: ID!, $operation: CRUD!, $input: OrderInput!) {
    modifyOrder(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        portfolioId
        id
        level
        orderId
        time
        status
        dir
        price
        qty
        volume
        role
        kind
        type
        fulfilled
        qtyChange
        linked
        trdPair
        trdAmount
      }
    }
  }
`;
export const modifyPosition = /* GraphQL */ `
  mutation ModifyPosition(
    $clientId: ID!
    $operation: CRUD!
    $input: PositionInput!
  ) {
    modifyPosition(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        exchangeId
        symbol
        positionAmt
        entryPrice
        markPrice
        unRealizedProfit
        liquidationPrice
        leverage
        maxNotionalValue
        marginType
        isolatedMargin
        isAutoAddMargin
        positionSide
        notional
        isolatedWallet
        updateTime
      }
    }
  }
`;
export const modifyBalance = /* GraphQL */ `
  mutation ModifyBalance(
    $clientId: ID!
    $operation: CRUD!
    $input: BalanceInput!
  ) {
    modifyBalance(clientId: $clientId, operation: $operation, input: $input) {
      operation
      clientId
      payload {
        clientId
        exchangeId
        asset
        walletBalance
        unrealizedProfit
        marginBalance
        maintMargin
        initialMargin
        positionInitialMargin
        openOrderInitialMargin
        crossWalletBalance
        crossUnPnl
        availableBalance
        maxWithdrawAmount
        marginAvailable
        updateTime
      }
    }
  }
`;
