export class SharedData {
  static data: any = {};

  public static set(key: string, value: any): void {
    SharedData.data[key] = value;
  }

  public static setMultiple(
    args: {
      key: string;
      value: any[];
    }[]
  ): void {
    args.forEach((element) => {
      SharedData.data[element.key] = element.value;
    });
  }

  public static get(key: string): any {
    return SharedData.data[key];
  }

  public static deepGet(key: string, path: string): any {
    return recLookup(SharedData.data[key], path);
  }

  public static purge(key: string): void {
    delete SharedData.data[key];
  }

  public static debug(): void {
    console.log(SharedData.data);
  }
}

function recLookup(obj: any, relPath: string): any {
  const parts = relPath.split(".");
  if (parts.length === 1) {
    return obj?.[parts[0]];
  }
  return recLookup(obj[parts[0]], parts.slice(1).join("."));
}
