import GoToNextLimit from "../../../components/GoToNextToken";
import { getColumns, getInput, LocalizedTs, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../../shared/utils";
import { getLogs } from "../../../graphql/queries";
import { GetLogsInput, GetLogsQuery, Log } from "../../../shared/API";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useGetData } from "../../../shared/useGetData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../_appLayout/AppContent";

const fetchLimit = 1000;

type IProps = { exchangeId?: string; portfolioId?: string };

const getConfigKey = (props: IProps) => {
  if (props.exchangeId) {
    return "exchanges_logs";
  } else if (props.portfolioId) {
    return "portfolios_logs";
  } else {
    return "logs";
  }
};

const getKeyName = (props: IProps) => {
  if (props.exchangeId) {
    return "Exchange logs";
  } else if (props.portfolioId) {
    return "Portfolio logs";
  } else {
    return "Logs";
  }
};

export function Logs(props: IProps) {
  const { customClientId } = useUserPreferences();
  const TABLE_KEY = useRef(getConfigKey(props));
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));

  const dataState = useGetData<GetLogsQuery>({
    customClientId,
    graphqlQuery: getLogs,
    variables: getInput<GetLogsInput>({ limit: fetchLimit, exchangeId: props.exchangeId, portfolioId: props.portfolioId }),
  });

  return (
    <>
      <NTable
        toolbarIsDense
        title={getKeyName(props)}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        rememberStateKey={TABLE_KEY.current}
        isLoading={dataState.isLoading}
        columns={getColumns(LogsAsChildrenColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="timestamp"
        rows={prepareTableRows(dataState.data?.getLogs?.items ?? [])}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />
      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getLogs?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}

export const LogsAsChildrenColumns: IHeadCell<Log>[] = sortColumnsByLabelExceptFirst([
  {
    id: "timestamp",
    label: "Timestamp",
    numeric: false,
    disablePadding: false,
    customRender: (value: Log) => <LocalizedTs value={value.timestamp} />,
  },
  {
    id: "src",
    label: "Source",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "srcId",
    label: "Source ID",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "info",
    label: "Info",
    numeric: false,
    disablePadding: false,
  },
]);
