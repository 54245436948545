import { Add, ContentPasteGo, Delete, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Save } from "@mui/icons-material";
import { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { deleteTemplate, updateTemplate } from "../../graphql/mutations";
import { getInput } from "../../shared/utils";
import { getTemplate } from "../../graphql/queries";
import { GetTemplateQuery, UpdateTemplateInput } from "../../shared/API";
import { LevelRow } from "./LevelRow";
import { NContainer } from "../../components/nContainer/NContainer";
import { NLevel } from "./Template.types";
import { NPrompDialog } from "../../components/nPrompt/NPrompDialog";
import { reorderSequentialSet } from "./Template.utils";
import { toast } from "react-toastify";
import { useGetData } from "../../shared/useGetData";
import { useNavigate, useParams } from "react-router-dom";
import { usePasteLevelsDialog } from "./PasteLevelsDialog";
import { usePostData } from "../../shared/usePostData";
import { useUserPreferences } from "../_appLayout/AppContent";
import { v4 } from "uuid";
import {
  alpha,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

export function TemplateEdit() {
  const { customClientId } = useUserPreferences();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const dialogActions = useRef({ show: undefined });

  const dataState = useGetData<GetTemplateQuery>({
    customClientId,
    graphqlQuery: getTemplate,
    variables: {
      input: { id },
    },
  });

  useEffect(() => {
    const newLevels: NLevel[] = [];

    dataState.data?.getTemplate?.levels?.forEach((level) => {
      if (level) {
        newLevels.push({ ...level, __customId: v4() });
      }
    });

    setLevels(newLevels);
    setTemplateName(dataState.data?.getTemplate?.name || "");
  }, [dataState.data]);

  const post = usePostData({
    customClientId,
    onSuccess: () => {
      navigate("/template");
      toast.success("Template successfully mutated!");
    },
    onError: () => {
      toast.error("Failed to mutate template!");
    },
  });

  const [templateName, setTemplateName] = useState<string>("");
  const [levels, setLevels] = useState<NLevel[]>([]);
  const [selectedLevels, setSelectecedLevels] = useState<string[]>([]);

  const toggleLevelSelection = useCallback((levelId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectecedLevels((prevState) => [...prevState, levelId]);
    } else {
      setSelectecedLevels((prevState) => prevState.filter((f) => f !== levelId));
    }
  }, []);

  const onAddLevel = useCallback(() => {
    setLevels((prevLevels) => {
      return [
        ...prevLevels,
        {
          __customId: v4(),
          __typename: "TemplateLevel",
          level:
            prevLevels.reduce((pv, cv) => {
              return Math.max(pv, cv.level || 0);
            }, -1) + 1,
        },
      ];
    });
  }, []);

  const levelsById = useRef<any>({});

  const levelUp = useCallback(() => {
    setLevels((prevLevelsOrder) => {
      return reorderSequentialSet(selectedLevels, prevLevelsOrder, 1);
    });
  }, [selectedLevels]);

  const levelDown = useCallback(() => {
    setLevels((prevLevelsOrder) => {
      return reorderSequentialSet(selectedLevels, prevLevelsOrder, -1);
    });
  }, [selectedLevels]);

  const levelRemove = useCallback((levelId: string) => {
    setSelectecedLevels((prevState) => prevState.filter((f) => f !== levelId));
    setLevels((prevState) => prevState.filter((f) => f.__customId !== levelId));
  }, []);

  const updateTemplateAction = useCallback(() => {
    post.postData(
      updateTemplate,
      getInput<UpdateTemplateInput>({
        id: id || "",
        // @ts-ignore
        levels: levels.map((l) => {
          return {
            level: l.level,
            martingale: levelsById.current[l.__customId].martingale,
            priceShift: levelsById.current[l.__customId].priceShift,
            takeProfit: levelsById.current[l.__customId].takeProfit,
          };
        }),
      })
    );
  }, [levels, post, id]);

  const deleteTemplateAction = useCallback(() => {
    post.postData(deleteTemplate, { input: { id } });
  }, [post, id]);

  const pasteDialog = usePasteLevelsDialog({
    onFullfilled: (lvls: NLevel[]) => {
      setLevels(lvls);
    },
  });

  return (
    <>
      {pasteDialog.TemplateDialog}
      <NPrompDialog yieldsActions={dialogActions.current} onYes={deleteTemplateAction} />
      <NContainer title={"Template: " + dataState.data?.getTemplate?.name} isLoading={dataState.isLoading} isFrozen={post.state.isLoading}>
        <Fragment key={dataState.stateKey}>
          <Grid item xs={12}>
            <TextField
              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setTemplateName(e.target.value)}
              value={templateName}
              name="name"
              label="Template name"
              required
              variant="filled"
              fullWidth
              disabled
            />
          </Grid>

          <br />
          <Grid item xs={12}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selectedLevels.length > 0 && {
                  bgcolor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
              }}
            >
              {selectedLevels.length > 0 ? (
                <>
                  <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {selectedLevels.length} selected
                  </Typography>

                  <Tooltip title="+1 Level">
                    <IconButton onClick={levelDown}>
                      <KeyboardDoubleArrowDown />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="-1 Level">
                    <IconButton onClick={levelUp}>
                      <KeyboardDoubleArrowUp />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Typography sx={{ flex: "1 1 100%" }} variant="body1" id="tableTitle" component="div">
                    Template's levels
                  </Typography>
                  <Tooltip title="Import">
                    <IconButton onClick={() => pasteDialog.openDialog()}>
                      <ContentPasteGo />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add">
                    <IconButton onClick={onAddLevel}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Toolbar>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Martingale</TableCell>
                  <TableCell>Price shift</TableCell>
                  <TableCell>Take profit</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {levels
                  .sort((a, b) => (a.level || 0) - (b.level || 0))
                  .map((lvl) => (
                    <LevelRow
                      key={lvl.__customId}
                      level={lvl}
                      refValue={levelsById.current}
                      onSelection={toggleLevelSelection}
                      onRemove={levelRemove}
                    />
                  ))}
              </TableBody>
            </Table>
          </Grid>

          {post.state.error && (
            <div style={{ margin: "10px 0px 0px 0px" }}>
              <Typography color="orangered" variant="caption">
                {post.state.error}
              </Typography>
            </div>
          )}
          <br />
          <div style={{ display: "flex", gap: "1em" }}>
            <Button variant="contained" startIcon={<Save />} onClick={updateTemplateAction}>
              Save
            </Button>
            <Button onClick={dialogActions.current?.show} variant="outlined" startIcon={<Delete />}>
              Delete
            </Button>
          </div>
        </Fragment>
      </NContainer>
    </>
  );
}
