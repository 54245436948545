import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, useTheme } from "@mui/material";
import { IHeadCell, ITableProps } from "./NTable.types";
import { visuallyHidden } from "@mui/utils";

export function NTableHead<T>(props: { tableProps: ITableProps<T>; headCells: IHeadCell<T>[]; checkAble?: boolean }) {
  const theme = useTheme();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props.tableProps;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.checkAble && props.headCells.length > 0 && (
          <TableCell padding="checkbox" sx={{ bgcolor: theme.palette.background.paper }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {props.headCells.map((headCell, i) => (
          <TableCell
            key={i}
            align={headCell.numeric || headCell.rightAligned ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ bgcolor: theme.palette.background.paper }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
