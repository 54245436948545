import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import GoToNextLimit from "../../../components/GoToNextToken";
import { blue, red } from "@mui/material/colors";
import { Box } from "@mui/material";
import { getColumns, getInput, prepareTableRows, sortColumnsByLabelExceptFirst } from "../../../shared/utils";
import { getTrades } from "../../../graphql/queries";
import { GetTradesInput, GetTradesQuery, Trade } from "../../../shared/API";
import { IHeadCell } from "../../../components/nTable/NTable.types";
import { NTable } from "../../../components/nTable/NTable";
import { SharedData } from "../../../shared/sharedData/sharedData";
import { useGetData } from "../../../shared/useGetData";
import { useRef, useState } from "react";
import { useUserPreferences } from "../../_appLayout/AppContent";

const fetchLimit = 1000;

type IProps = { exchangeId?: string; portfolioId?: string };

const getConfigKey = (props: IProps) => {
  if (props.exchangeId) {
    return "exchanges_trades";
  } else if (props.portfolioId) {
    return "portfolios_trades";
  } else {
    return "trades";
  }
};

const getKeyName = (props: IProps) => {
  if (props.exchangeId) {
    return "Exchange trades";
  } else if (props.portfolioId) {
    return "Portfolio trades";
  } else {
    return "Trades";
  }
};

export function Trades(props: IProps) {
  const { customClientId } = useUserPreferences();
  const TABLE_KEY = useRef(getConfigKey(props));
  const [colsConfig, setColsConfig] = useState(SharedData.get(TABLE_KEY.current));

  const dataState = useGetData<GetTradesQuery>({
    customClientId,
    graphqlQuery: getTrades,
    variables: getInput<GetTradesInput>({
      portfolioId: props.portfolioId,
      exchangeId: props.exchangeId,
      limit: fetchLimit,
    }),
  });

  return (
    <>
      <NTable
        toolbarIsDense
        title={getKeyName(props)}
        rememberStateKey={TABLE_KEY.current}
        supportsColumnOrder={{ tableKey: TABLE_KEY.current, onNewOrder: () => setColsConfig(SharedData.get(TABLE_KEY.current)) }}
        isLoading={dataState.isLoading}
        columns={getColumns(TradesAsChildrenColumns, colsConfig)}
        defaultOrder="desc"
        defaultOrderBy="time"
        rows={prepareTableRows(dataState.data?.getTrades?.items ?? [])}
        stickyHeadersMaxHeight="calc(100vh - 320px)"
      />

      <GoToNextLimit limit={fetchLimit} nextToken={dataState.data?.getTrades?.nextToken} goToAction={dataState.fetchData} />
    </>
  );
}

export const TradesAsChildrenColumns: IHeadCell<Trade>[] = sortColumnsByLabelExceptFirst([
  {
    id: "time",
    label: "Timestamp",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      if (!value) {
        return null;
      }

      if (!isNaN(value)) {
        const d = new Date(+value);
        return <span>{d.toLocaleDateString() + " " + d.toLocaleTimeString()}</span>;
      }

      return <span>{value}</span>;
    },
  },
  {
    id: "id",
    label: "Trade ID",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "trdPair",
    label: "Trade pair",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "dir",
    label: "Direction",
    numeric: false,
    disablePadding: false,
    customRender: (value) => {
      switch (value) {
        case "SELL":
          return (
            <Box sx={{ color: blue[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleUpIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        case "BUY":
          return (
            <Box sx={{ color: red[400], display: "flex", alignItems: "center" }}>
              <ArrowCircleDownIcon sx={{ fontSize: "1.2em" }} /> &nbsp;
              {value}
            </Box>
          );

        default:
          return <Box>{value}</Box>;
      }
    },
  },
  {
    id: "qty",
    label: "Quantity",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "price",
    label: "Price",
    numeric: true,
    disablePadding: false,
  },
  {
    id: "level",
    label: "Level",
    numeric: true,
    disablePadding: false,
  },
]);
